import React, {useEffect, useRef, useState} from 'react'
import videojs from 'video.js'
import WaterMarkLogo from '../../images/watermark.png'
import 'videojs-contrib-quality-levels'
import 'videojs-hls-quality-selector'
import 'videojs-contrib-ads';
import 'videojs-contrib-eme'
import 'videojs-contrib-dash'
import 'videojs-ima';
import "@silvermine/videojs-airplay/dist/silvermine-videojs-airplay.css";
import { connect } from 'react-redux'
import {mediaSetCurrentTime,
    mediaSetCurrentTextLanguage,
    mediaSetCurrentAudioLanguage,
    mediaSetStartTime,
    siteSetIsUserAlreadyInteractWithMedia,
    siteSetForcePlayVideo,
    siteSetisClickFromSlideShow,
    siteSetisPlayMediaNextEpisode} from '../../actions'
import { useHistory } from 'react-router-dom';
import {consoleLogHelper, goToEpisode, randomString} from '../../helper/Helper'
import { endWatching, getEpisode, sendBeaconEndWatch, startWatching, watching } from '../../api'
import { useCookies } from 'react-cookie'

import 'videojs-ima/dist/videojs.ima.css'
import { isUserPremium } from '../../helper/ReduxStoreHelper'



const VideoPlayer = ({ source, adsList, media, dispatch, autoPlay, onStartWatching, site,  videoLicenseCertificateUrl, videoLicenseUrl, titlePremium}) => {

    const [cookies, setCookie] = useCookies(['userSettings']);

    const [isStartedWatching, setIsStartedWatching] = useState(false)
    const [isEnded, setIsEnded] = useState(false)
    const [isAlreadyPlayMidRollAd, setIsAlreadyPlayMidRollAd] = useState(false)
    const [waterMarkIntervalId, setWaterMarkIntervalId] = useState('')

    const waterMarkIntervalIdRef = useRef();
    waterMarkIntervalIdRef.current = waterMarkIntervalId;

    const isStartedWatchingRef = useRef();
    isStartedWatchingRef.current = isStartedWatching;

    const isEndedRef = useRef();
    isEndedRef.current = isEnded;

    const isAlreadyPlayMidRollAdRef = useRef();
    isAlreadyPlayMidRollAdRef.current = isAlreadyPlayMidRollAd;


    const videoNode = useRef(null)
    const player = useRef(null)
    const history = useHistory();
    const historyRef = useRef();
    historyRef.current = history;

    var intervalWatchingTime;


    // มีการเรียกใช้ใน Callback จึงต้องใช้ useRef ไม่งั้นค่าจะไม่อัพเดท
    const mediaRef = useRef();
    mediaRef.current = media;

    useEffect(() => {

        const cleanup = async () => {

            // if( isStartedWatchingRef.current ){
            //     // กรณีที่หนังจบจะเก็บเวลาล่าสุด -4 วิ กันเคสผิดพลาด แล้วไปขึ้นหน้า continue watching
            //     if( isEndedRef.current){
            //         await watching(mediaRef.current.id, mediaRef.current.episode,  Math.round(player.current.currentTime()) -4)
            //         clearInterval(intervalWatchingTime)
            //         await endWatching()
            //     }else{
            //         await watching(mediaRef.current.id, mediaRef.current.episode,  Math.round(player.current.currentTime()))
            //         clearInterval(intervalWatchingTime)
            //         await endWatching()
            //     }
            // }

            player.current.dispose()
        }

        window.addEventListener('beforeunload', handleBeforeUnload);
   
        return () => {
            player.current.off('pause')
            clearInterval(waterMarkIntervalIdRef.current)
            clearInterval(intervalWatchingTime)
            cleanup()
            window.removeEventListener('beforeunload', handleBeforeUnload); // remove the event handler for normal unmounting
        }

    },[])

    // ก่อนเว็บจะปิดลงหรือโดน refresh จะมา handle ที่นี่เนื่องจากจะไม่เข้า componentWillUnmount
    const handleBeforeUnload = () => {
        if( isStartedWatchingRef.current ){
            sendBeaconEndWatch(mediaRef.current.id, mediaRef.current.episode);
        }
    }

    // สำหรับกรณีที่กดเลือก Episode เดิม จะมีการสั่งให้เริ่มเล่น Video
    useEffect(()=>{
        if( site.forcePlayVideo ){
            dispatch(siteSetForcePlayVideo(false))
            if( player.current ){
                player.current.play()
            }
        }
    }, [site.forcePlayVideo])

    const handleEpisodeResponse = ( response ) => {
        // This is src for player
        // let playlist = response.result.playlist;
        // let adsList  = response.result.ads.dfp

        videoLicenseCertificateUrl = response.result.playlist_license_cert_url
        videoLicenseUrl = response.result.playlist_license_url
        

        // setConfirmRating(true);

        
    }

    useEffect(() => {
        
        

        const process = async () => {

            // getEpisode(media.id, media.episode).then( (res) => handleEpisodeResponse(res) )

            consoleLogHelper("VideoJs Intial",player)

            
            if (videoLicenseUrl != null) {
                if (videojs.browser.IS_SAFARI) {

                    consoleLogHelper('IS_SAFARI','IS_SAFARI')
                    const videoJsOptions = {
                        controls: true,
                        poster: media.thumbnailUrl,
                        techOrder: [ 'html5' ],
                        html5: {
                            // hls: {
                            //     // withCredentials: true,
                            //     overrideNative: !videojs.browser.IS_SAFARI
                            //     // overrideNative: true
                            // }
                            hls: {
                                overrideNative: true
                                },
                                nativeAudioTracks: false,
                                nativeVideoTracks: false
                        },
                        sources: [{ 
                            src:  source,
                            type: 'application/x-mpegURL',
                            keySystems: {
                                'com.apple.fps.1_0': {
                                    certificateUri: videoLicenseCertificateUrl,
                                    licenseUri: videoLicenseUrl,
                                    getContentId: function(emeOptions, contentId) {
                                        // return content ID
                                        
                                        var textContentId = new TextDecoder('utf-16').decode(contentId.slice(16));
                                        textContentId = textContentId.slice(1);
                                        return textContentId;
                                      },


                                    
                                }
                            }
        
                        }]
                    }
                    player.current = videojs(videoNode.current, videoJsOptions);
                } else {
                    const videoJsOptions = {
                        controls: true,
                        textTrackSettings: false,
                        poster: media.thumbnailUrl,
                        techOrder: [ 'html5' ],
                        html5: {
                                hls: {
                                    withCredentials: true
                                }
                            },
                        sources: [{ 
                            src: source,
                            type: 'application/dash+xml', 
                            
                            keySystemOptions: [
                                {
                                name: 'com.widevine.alpha',
                                options: {
                                    serverURL: videoLicenseUrl
                                }
                                }
                            ]
        
                        }]
                    }

                    player.current = videojs(videoNode.current, videoJsOptions);

                }
            } else {
                //กรณีไม่มี licese url ส่งมาแสดงว่าไม่ใช่ MPD ให้เล่นไฟล์ m3u8 ได้ปกติ
                const videoJsOptions = {
                    controls: true,
                    poster: media.thumbnailUrl,
                    // techOrder: [ 'html5' ],
                    // html5: {
                    //     vhs: {
                    //         // withCredentials: true,
                    //         overrideNative: !videojs.browser.IS_SAFARI
                    //     }
                    // },
                    sources: [{ 
                        src:  source,
                        type: 'application/x-mpegURL'
                    }]
                }

                player.current = videojs(videoNode.current, videoJsOptions);
            }
            player.current.eme();
            player.current.aspectRatio('16:9');
            player.current.hlsQualitySelector({
                displayCurrentQuality: false,
            });

            player.current.on('timeupdate', function() {
                dispatch(mediaSetCurrentTime(this.currentTime()));
            });

            player.current.on('firstplay', function() {

                // Add ครั้งแรกตอนเริ่มเล่น จากนั้นจะรันใหม่ทุกๆ 2 วินาที เพื่อป้องกัน user ทำการลบออกหรือแก้ไข class 
                addWaterMark()
                const id = setInterval(() => {
                    addWaterMark()
                    // consoleLogHelper("watermark", "watermark")               
                }, 2000)
                setWaterMarkIntervalId(id)
                // var watchingTime = 0;
                
                //  intervalWatchingTime = setInterval(() => {
                //     try {
                //         if(player.current.currentTime() != null) {
                //             var  currentTimeWatching = Math.round(player.current.currentTime())
                //             watchingTime = watchingTime + 1
                //             consoleLogHelper("watchingTime web", watchingTime)
                //             if(watchingTime % 10 === 0) {
                //                 watching(mediaRef.current.id, mediaRef.current.episode,  currentTimeWatching )  
                //                 consoleLogHelper("call : ", "watching web")
                //             }
                //         }  
                //     } catch (error) {
                //         // consoleLogHelper("error" , error)
                //     }       
                // }, 1000)

                // setIsStartedWatching(true)

                var episodePremium = 0;
                if(mediaRef.current.isCurrentEpisodePremium === true) {
                    episodePremium = 1;
                } else {
                    episodePremium = 0;
                }

                if (titlePremium !== 1) {
                    titlePremium = 0;
                }

                // startWatching(titlePremium, episodePremium,mediaRef.current.id, mediaRef.current.episode).then(() => {
                //     watching(mediaRef.current.id, mediaRef.current.episode, 1).then( () => {
                //         // สำหรับใช้งานใน VideoPage
                //         onStartWatching()
                //     })
                // })                
            });

            handlePlayerEnded()
            handlePlayerPause()

                        // set volume
                        let userSettingVolume  = getUserSettingsCookieValueByName('style', 'volume')
                        let userSettingVolumeMute  = getUserSettingsCookieValueByName('style', 'isMute')
                        if (userSettingVolumeMute !== '1') {
                            player.current.muted(false);
                            player.current.volume(userSettingVolume);
                        } else {
            player.current.volume(0);
                            // player.current.volume(userSettingVolume);
                            player.current.muted(true);
                        }
            
                        player.current.on("volumechange", () => {
                            // get volume for set to cookie
                            var howLoudIsIt = player.current.volume(); 
            
                            if (player.current.muted()) {
                                let latestUserVolume = getUserSettingsCookieValueByName('style', 'volume');
                                setUserSettingsCookie('style', 'isMute', '1');
                                    setUserSettingsCookie('style', 'volume', latestUserVolume)
                                    player.current.volume(latestUserVolume); 
                            } else {
                                    let previousMuteStatus = getUserSettingsCookieValueByName('style', 'isMute')
            
                                previousMuteStatus = previousMuteStatus === '1' ? true : false;
                               
                                //มีการเปลี่ยนสถานะจาก mute เป็น unmute
                                if(previousMuteStatus !== player.current.muted()){
                                   
                                    // let latestUserVolume = setUserSettingsCookie('style', 'volume', userSettingVolume)
                                    let latestUserVolume = getUserSettingsCookieValueByName('style', 'volume');
                                    player.current.volume(latestUserVolume); 
                                    
                                } else {                        
                                        setUserSettingsCookie('style', 'volume', howLoudIsIt);
                                }
            
                                setUserSettingsCookie('style', 'isMute', '0');
                                
                            }
                            // setUserSettingsCookie('style', 'volume', howLoudIsIt)
                        })

            player.current.audioTracks().on("change", () => {
                let activeAudioLanguage = getActiveAudioLanguage()
                dispatch(mediaSetCurrentAudioLanguage(activeAudioLanguage));
                setUserSettingsCookie('language', 'audioLanguage', activeAudioLanguage)
            });
            player.current.textTracks().on("change", () => {
                let activeTextLanguage = getActiveTextLanguage()
                dispatch(mediaSetCurrentTextLanguage(activeTextLanguage));
                setUserSettingsCookie('language', 'subtitleLanguage', activeTextLanguage)
            })

            if( isUserPremium() ){
                
                if( typeof player.current.airPlay  === 'undefined' ){
                    require('@silvermine/videojs-airplay')(videojs);
                }
                if( typeof player.current.chromecast  === 'undefined' ){
                    require('@silvermine/videojs-chromecast')(videojs);
                }

                player.current.airPlay();
                player.current.chromecast({
                    receiverAppID: 'E02DC2E3'
                }); // initializes the Chromecast plugin
            }

            initialAds(adsList)
            handleMidRollAd(adsList)
            
            handleUserInteractWithMedia()

            // addCustomFontToCaptionSettings()
            initialUserSettings()
            // handleCaptionSettingsResetButton()
            // handleCaptionSettingsOnChange()
            handleContinueWatching()
            handleAutoPlay()
            handleTrackSubtitle()
        }
        process()

    }, [source])


    // สำหรับ Handle ว่า User มีการทำอะไรซักอย่่างกับ media แล้วหรือยัง เช่น ทำการกดเริ่มเล่น กดหยุด หรือปรับ volume ให้เป็น unmuted  
    // จะเอาไว้ใช้งานกับ Safari Browser เพื่อ Handle ว่าจะต้อง Muted ตอน AutoPlay หรือไม่
    // เนื่องจากใน Safari มีข้อกำหนดว่า ถ้า User ยังไม่ได้มีการ Intereact กับ Media จะต้องทำการ AutoPlay แบบปิดเสียงเท่านั้น
    const handleUserInteractWithMedia = () => {
        player.current.posterImage.on('click', function(){
            dispatch(siteSetIsUserAlreadyInteractWithMedia(true))
        })
        player.current.bigPlayButton.on('click', function(){
            dispatch(siteSetIsUserAlreadyInteractWithMedia(true))
        })
        player.current.on('pause', function(){
            dispatch(siteSetIsUserAlreadyInteractWithMedia(true))
        })
        player.current.on('volumechange', () => {
            if( !player.current.muted() ){
                dispatch(siteSetIsUserAlreadyInteractWithMedia(true))
            }
        })
    }

    const handleContinueWatching = () => {
        player.current.ready(function(){
            if( mediaRef.current.startTime > 0 ){
                // หน่วงเวลาไว้เนื่องจากมีปัญหาว่าบางครั้ง AutoPlay ไม่สำเร็จ คาดว่าน่าจะเกิดจากการโหลดอะไรบางอย่างยังไม่เสร็จดี
                setTimeout( () => {
                    player.current.currentTime(mediaRef.current.startTime);
                    processAutoPlay(mediaRef.current.startTime)
                    // reset
                    dispatch(mediaSetStartTime(0))
                }, 1500);
            }
        });
    }


    const handleAutoPlay = () => {
        player.current.ready(function(){
            if( autoPlay || site.isClickFromSlideShow){

                // หน่วงเวลาไว้เนื่องจากมีปัญหาว่าบางครั้ง AutoPlay ไม่สำเร็จ คาดว่าน่าจะเกิดจากการโหลดอะไรบางอย่างยังไม่เสร็จดี
                setTimeout( () => {
                    processAutoPlay();
                }, 1000);
            }           
        });
    }


    const processAutoPlay = () => {

        if( player.current.player() ){

            // ถ้าเป็น Safari และ ยังไม่เคยมีการ Interact กับ Media มาก่อน จะต้องทำการ Muted เสมอ ถึงจะสามารถ AutoPlay ได้
            if( videojs.browser.IS_SAFARI && !site.isUserAlreadyInteractWithMedia ){
                player.current.muted(true);
            }

            var promise = player.current.play();

            if (promise !== undefined) {
                promise.then(function(data) {
                    // Autoplay started!
                }).catch(function(error) {
                    // Autoplay was prevented.
                });
            }
        }

    }


    const handleCaptionSettingsOnChange = () => {

        let fontfamilySelection = document.querySelector('.vjs-font-family > select')
        fontfamilySelection.addEventListener('change', function() {
            if( this.value === 'SukhumvitSetMedium' ){
                activeSukhumvitFont()
            }else{
                inactiveSukhumvitFont()
            }
            setUserSettingsCookie('style', 'fontFamily', this.value)
        });

        let backgroundColorSelection = document.querySelector('.vjs-bg-color > select')
        backgroundColorSelection.addEventListener('change', function() {
            setUserSettingsCookie('style', 'backgroundColor', this.value)
        });

        let backgroundOpacitySelection = document.querySelector('.vjs-bg-opacity > select')
        backgroundOpacitySelection.addEventListener('change', function() {
            setUserSettingsCookie('style', 'backgroundOpacity', this.value)
        });

        let colorSelection = document.querySelector('.vjs-fg-color > select')
        colorSelection.addEventListener('change', function() {
            setUserSettingsCookie('style', 'color', this.value)
        });

        let edgeStyleSelection = document.querySelector('.vjs-edge-style > select')
        edgeStyleSelection.addEventListener('change', function() {
            setUserSettingsCookie('style', 'edgeStyle', this.value)
        });

        let fontPercentSelection = document.querySelector('.vjs-font-percent > select')
        fontPercentSelection.addEventListener('change', function() {
            setUserSettingsCookie('style', 'fontPercent', Number(this.value))
        });

        let textOpacitySelection = document.querySelector('.vjs-text-opacity > select')
        textOpacitySelection.addEventListener('change', function() {
            setUserSettingsCookie('style', 'textOpacity', this.value)
        });

        let windowColorSelection = document.querySelector('.vjs-window-color > select')
        windowColorSelection.addEventListener('change', function() {
            setUserSettingsCookie('style', 'windowColor', this.value)
        });

        let windowOpacitySelection = document.querySelector('.vjs-window-opacity > select')
        windowOpacitySelection.addEventListener('change', function() {
            setUserSettingsCookie('style', 'windowOpacity', this.value)
        });
    }


    const initialUserSettings = () => {
        initialUserTracksSettings()
        // initialUserCaptionStyleSettings()
    }

    const handleTrackSubtitle = () => {
        player.current.on('timeupdate', function (){
            // player.current.currentTime();
            if (player.current.currentTime() > 1 && player.current.currentTime() <= 2) {
            let textTracks = player.current.textTracks();
            let userSettingTextLanguage = getUserSettingsCookieValueByName('language', 'subtitleLanguage')

            for (let i = 0; i < textTracks.length; i++) {
                let track = textTracks[i];
                if (track.kind === 'subtitles' && track.language === userSettingTextLanguage) {
                    track.mode = 'showing';
                }
            }
            }
        })
    }

    const initialUserTracksSettings = () => {

        player.current.on('loadedmetadata', function (){

            let textTracks = player.current.textTracks();
            let userSettingTextLanguage = getUserSettingsCookieValueByName('language', 'subtitleLanguage')

            for (let i = 0; i < textTracks.length; i++) {
                let track = textTracks[i];
                if (track.kind === 'subtitles' && track.language === userSettingTextLanguage) {
                    track.mode = 'showing';
                }
            }

        })

    }

    const initialUserCaptionStyleSettings = () => { 

        player.current.ready(function(){

            let userSettingFontPercent          = getUserSettingsCookieValueByName('style', 'fontPercent')
            let userSettingFontFamily           = getUserSettingsCookieValueByName('style', 'fontFamily')
            let userSettingBackgroundColor      = getUserSettingsCookieValueByName('style', 'backgroundColor')
            let userSettingBackgroundOpacity    = getUserSettingsCookieValueByName('style', 'backgroundOpacity')
            let userSettingColor                = getUserSettingsCookieValueByName('style', 'color')
            let userSettingEdgeStyle            = getUserSettingsCookieValueByName('style', 'edgeStyle')
            let userSettingTextOpacity          = getUserSettingsCookieValueByName('style', 'textOpacity')
            let userSettingWindowColor          = getUserSettingsCookieValueByName('style', 'windowColor')
            let userSettingWindowOpacity        = getUserSettingsCookieValueByName('style', 'windowOpacity')
            let userSettingVolume               = getUserSettingsCookieValueByName('style', 'volume')

            var settings = this.textTrackSettings;
            settings.setValues({
                "fontPercent"       : userSettingFontPercent,
                "fontFamily"        : userSettingFontFamily,
                "backgroundColor"   : userSettingBackgroundColor,
                "backgroundOpacity" : userSettingBackgroundOpacity,
                "color"             : userSettingColor,
                "edgeStyle"         : userSettingEdgeStyle,
                "textOpacity"       : userSettingTextOpacity,
                "windowColor"       : userSettingWindowColor,
                "windowOpacity"     : userSettingWindowOpacity,
                "volume"            : userSettingVolume,

            });
            settings.updateDisplay();

            // Custom font จะต้องทำการ active เอง เนื่องจากจะไม่เข้า case ใน function ของ videojs
            if( userSettingFontFamily === "SukhumvitSetMedium" ) {
                activeSukhumvitFont()
            }
        })

    }


    const handleCaptionSettingsResetButton = () => {
        player.current.ready(function(){
            let trackSettingsResetButton = document.querySelector('.vjs-track-settings-controls .vjs-default-button')
            if(trackSettingsResetButton != null) {
                trackSettingsResetButton.addEventListener('click', function() {
                    resetCaptionSettings()
                });
            }
        })
    }

    const resetCaptionSettings = () => { 

        let userSettingFontPercent          = window.flixer.userSettingsDefault.style.fontPercent
        let userSettingFontFamily           = window.flixer.userSettingsDefault.style.fontFamily
        let userSettingBackgroundColor      = window.flixer.userSettingsDefault.style.backgroundColor
        let userSettingBackgroundOpacity    = window.flixer.userSettingsDefault.style.backgroundOpacity
        let userSettingColor                = window.flixer.userSettingsDefault.style.color
        let userSettingEdgeStyle            = window.flixer.userSettingsDefault.style.edgeStyle
        let userSettingTextOpacity          = window.flixer.userSettingsDefault.style.textOpacity
        let userSettingWindowColor          = window.flixer.userSettingsDefault.style.windowColor
        let userSettingWindowOpacity        = window.flixer.userSettingsDefault.style.windowOpacity


        var settings = player.current.textTrackSettings;
        settings.setValues({
            "fontPercent"       : userSettingFontPercent,
            "fontFamily"        : userSettingFontFamily,
            "backgroundColor"   : userSettingBackgroundColor,
            "backgroundOpacity" : userSettingBackgroundOpacity,
            "color"             : userSettingColor,
            "edgeStyle"         : userSettingEdgeStyle,
            "textOpacity"       : userSettingTextOpacity,
            "windowColor"       : userSettingWindowColor,
            "windowOpacity"     : userSettingWindowOpacity,
        });
        settings.updateDisplay();

        setUserSettingsCookie('style', 'fontPercent', userSettingFontPercent)
        setUserSettingsCookie('style', 'fontFamily', userSettingFontFamily)
        setUserSettingsCookie('style', 'backgroundColor', userSettingBackgroundColor)
        setUserSettingsCookie('style', 'backgroundOpacity', userSettingBackgroundOpacity)
        setUserSettingsCookie('style', 'color', userSettingColor)
        setUserSettingsCookie('style', 'edgeStyle', userSettingEdgeStyle)
        setUserSettingsCookie('style', 'textOpacity', userSettingTextOpacity)
        setUserSettingsCookie('style', 'windowColor', userSettingWindowColor)
        setUserSettingsCookie('style', 'windowOpacity', userSettingWindowOpacity)

        inactiveSukhumvitFont()
    }

    const getUserSettingsCookieValueByName = ( type, name ) => {
        let userSettings = cookies.userSettings
        return userSettings[type][name]
    }

    const setUserSettingsCookie = ( type, name, value ) => {
        let cookieConsentGiven = document.cookie.replace(/(?:(?:^|.*;\s*)rcl_consent_given\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        let cookieConsentPreferences = document.cookie.replace(/(?:(?:^|.*;\s*)rcl_preferences_consent\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        let cookieConsentMarketing = document.cookie.replace(/(?:(?:^|.*;\s*)rcl_marketing_consent\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        let cookieConsentStatistics = document.cookie.replace(/(?:(?:^|.*;\s*)rcl_statistics_consent\s*\=\s*([^;]*).*$)|^.*$/, "$1");

        if(cookieConsentPreferences !== '') {  //ถ้าไม่มีค่าแสดงว่าไม่ได้อนุญาตให้เข้าถึง cookie (ถ้ามีค่าจะส่งเป็น true มา )
            let userSettings = cookies.userSettings
            userSettings[type][name] = value
            setCookie('userSettings', JSON.stringify(userSettings), { path: '/', maxAge: window.flixer.userSettingsCookiesMaxAge, domain: window.flixer.cookieDomainDefault })
        } else {
            let userSettings = cookies.userSettings
            setCookie('userSettings', JSON.stringify(userSettings), { path: '/', maxAge: window.flixer.userSettingsCookiesMaxAge, domain: window.flixer.cookieDomainDefault })
        }
    }


    const addCustomFontToCaptionSettings = () => {
        let fontfamilySelection = document.querySelector('.vjs-font-family > select')
        fontfamilySelection.innerHTML += "<option value='SukhumvitSetMedium'>Sukhumvit</option>"
    }

    const activeSukhumvitFont = () => {
        let textTrack = document.querySelector('.vjs-text-track-display')
        textTrack.classList.add('font-sukhumvit');
    }

    const inactiveSukhumvitFont = () => {
        let textTrack = document.querySelector('.vjs-text-track-display')
        textTrack.classList.remove('font-sukhumvit');
    }

    const handlePlayerEnded = () => {

        player.current.on('ended', () => {

            setIsEnded(true);

            let currentEpisodeIndex = mediaRef.current.episodeList.findIndex( (episode) => {
                return episode.episode === mediaRef.current.episode;
            });

            // ยังไม่ใช่ episode สุดท้าย
            if( currentEpisodeIndex < mediaRef.current.episodeList.length - 1 ) {
                // ไป episode ต่อไป
                dispatch(siteSetisPlayMediaNextEpisode(true))
                goToEpisode(historyRef.current, mediaRef.current.id, mediaRef.current.episodeList[currentEpisodeIndex+1].episode);
            }

            clearInterval(intervalWatchingTime)

        });
        
    }


    const handlePlayerPause = () => {

        player.current.on('pause', (e) => {

            let currentTime = Math.round(player.current.currentTime())
            let duration    = Math.round(player.current.duration())

            // ถ้าไม่ใช่ตอนหนังจบ
            if( currentTime !== duration ){
                // watching(mediaRef.current.id, mediaRef.current.episode,  currentTime)
            }

        });

    }


    const initialAds = (adsList) => {
        if( adsList && adsList.length > 0){
            const ads = adsList[0]

            const imaOptions = {
                adTagUrl: ads.link
            };
            player.current.ima(imaOptions);
        }
    }

    const getActiveAudioLanguage = () => { 

        var audioTrackList = player.current.audioTracks();

        for (var i = 0; i < audioTrackList.length; i++) {
            var track = audioTrackList[i];
            if (track.enabled) {
                return track.language;
            }
        }

        return null;
    }


    const getActiveTextLanguage = () => {

        var tracks = player.current.textTracks();
        
        for (var i = 0; i < tracks.length; i++) {
            var track = tracks[i];
            if (track.kind === 'subtitles' && track.mode === 'showing') {
                return track.language;
            }
        }

        return null;
    }

    // ทำให้ click ขวาแล้วไม่ขึ้น context menu
    const handleOnContextMenu = (e) => {
        e.preventDefault()
        return false
    }


    const handleMidRollAd = ( adsList ) => {

        let adTagUrl, playTime;
        
        if( adsList && adsList.length > 0){
            // index 1 คือ mid-roll ad
            const ads = adsList[1]

            if( ads ){
                adTagUrl = ads.link
                playTime = ads.time

                player.current.on('timeupdate', function() {
        
                    let currenTime = player.current.currentTime()
        
                    if( !isAlreadyPlayMidRollAdRef.current && (currenTime >= playTime) ){
                        setIsAlreadyPlayMidRollAd(true)
                
                        player.current.ima.initializeAdDisplayContainer()
                        player.current.ima.setContentWithAdTag(null, adTagUrl, false)
                        player.current.ima.requestAds()
                
                        // ตอนที่เรียก requestAds แล้ว ima lib จะย้อน video ไปที่จุดเริ่มต้น ดังนั้นเลยต้อง set ค่าให้เวลากลับมาจุดที่ก่อน Ad ทำงาน
                        player.current.currentTime(currenTime)
                    }
        
                });
            }
        }
    }

    // จะทำการประมวลผลทุกๆ 2 วินาที โดยจะลบ Element เดิมและสร้าง Element ใหม่โดยเปลี่ยนชื่อ Class ไปเรื่อยๆเพื่อป้องกัน User ทำการลบหรือแก้ไข Class ต่างๆได้ง่าย
    const addWaterMark = () => {

        let waterMarkClass = "watermark-"+randomString(20);
        
        let node = document.createElement("div")
        node.className                  = waterMarkClass
        node.style.width                = '8%';
        node.style.maxWidth             = '100px';
        node.style.height               = '6%';
        node.style.maxHeight            = '41px';
        node.style.display              = 'block';
        node.style.position             = 'absolute';
        node.style.top                  = '5%';
        node.style.right                = '3%';
        node.style.backgroundImage      = "url('"+WaterMarkLogo+"')";
        node.style.backgroundRepeat     = 'no-repeat';
        node.style.backgroundSize       = 'cover';

        let videoJSElement = document.getElementsByClassName("video-js")

        if( videoJSElement && videoJSElement.length > 0 ){
            document.querySelectorAll("div[class^='watermark']").forEach(e => e.remove());
            videoJSElement[0].appendChild(node);  
        }
    }

    return (
        <div className='video-player-container'>	
            <div data-vjs-player>
                <video ref={videoNode} className="video-js vjs-layout-x-large" onContextMenu={handleOnContextMenu}></video>
            </div>
        </div>
    );


}



const mapStateToProps = (state, ownProps) => ({
    source: ownProps.source,
    adsList: ownProps.adsList,
    media: state.media,
    site: state.site
})
  
const mapDispatchToProps = (dispatch, ownProps) => ({
    dispatch
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VideoPlayer)
