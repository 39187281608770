import React from 'react'
import { useHistory } from 'react-router-dom'
import { goToCharacterContent } from '../../helper/Helper';


const MediaBannerContent = ({details}) => {

    const history = useHistory();

    const handleOnClick = (id) => {
        goToCharacterContent(history, id);
    }

    return (
        <div className='media-banner-content' onClick={() => handleOnClick(details.id)}>
            <img src={details.thumbnail_url} alt='' />
        </div>
    )
}

export default MediaBannerContent