import React from 'react'
import { useHistory } from 'react-router'
import {useDispatch, useSelector} from 'react-redux'
import { getAge, goToEpisode } from '../../helper/Helper'
// import { chromecastSetMediaId, chromecastSetEpisode, mediaSetStartTime, siteSetBeforeTitleVideo } from '../../actions'
import {chromecastSetSessionState, 
    chromecastSetMediaDuration, 
    chromecastSetMediaCurrentTime, 
    chromecastSetMediaRemainingTime, 
    chromecastSetMediaAvailableTextTracks, 
    chromecastSetMediaActiveTextTrack,
    chromecastSetMediaAvailableAudioTracks,
    chromecastSetMediaActiveAudioTrack,
    chromecastSetIsMuted,
    chromecastSetVolume,
    chromecastSetIsPlay,
    chromecastSetMediaTitle,
    chromecastSetDeviceName,
    mediaSetCurrentTime,
    mediaSetCurrentAudioLanguage,
    mediaSetCurrentTextLanguage,
    mediaSetStartTime, 
    siteSetBeforeTitleVideo,
    chromecastSetMediaId, 
    chromecastSetEpisode,
    selectMedia,
    selectEpisode
    } from '../../actions'

    import { useTranslation } from 'react-i18next';

const ContinueWatchingContent = ({details}) => {

    const history = useHistory()
    const userProfile = useSelector(state => state.user.profile);
    const { t , i18n} = useTranslation('trans');

    const dispatch = useDispatch()
    const chromeCastSessionState = useSelector(state => state.chromecast.sessionState)

    const handleOnClick = () => {
        dispatch(siteSetBeforeTitleVideo(String(details.title)));
        dispatch(mediaSetStartTime(details.start_time))
        // goToEpisode(history, details.title, details.episode)


        // กรณีที่เปิด ChromeCast อยู่ จะทำการเล่นหนังผ่าน ChromeCast
        if( chromeCastSessionState === window.flixer.chromecast.sessionState.started || 
            chromeCastSessionState === window.flixer.chromecast.sessionState.resumed
        ){

            // dispatch(selectMedia(details.title));
            // dispatch(selectEpisode(details.episode));
            // dispatch(mediaSetStartTime(details.start_time));
            // dispatch(mediaSetCurrentTime(details.start_time));
            // dispatch(chromecastSetIsPlay(true));
            // dispatch(chromecastSetMediaId(details.title))


            var rating = details.rating;
            var age = getAge(userProfile.dateOfBirth) ;
            var ratingList = [];
    
            if(rating != null && rating != '') {
                if (parseInt(age) >= 18) {
                    ratingList = ["R-18","R-15","PG-12","G"];
                } else if(parseInt(age) >= 15 && parseInt(age) < 18) {
                    ratingList = ["R-15","PG-12","G"];
                } else if(parseInt(age) > 12 && parseInt(age) < 15) {
                    ratingList = ["PG-12","G"];
                }else {
                    ratingList = ["PG-12","G"];
                }
    
                if(ratingList.includes(rating)) {
                    dispatch(selectMedia(details.title));
                    dispatch(selectEpisode(details.episode));
                    dispatch(mediaSetStartTime(details.start_time));
                    dispatch(mediaSetCurrentTime(details.start_time));
                    dispatch(chromecastSetIsPlay(true));
                    dispatch(chromecastSetMediaId(details.title))
                } else {
                    alert(t('TextAlertCannotViewMovie'));
                }
    
            } else {
                dispatch(selectMedia(details.title));
                dispatch(selectEpisode(details.episode));
                dispatch(mediaSetStartTime(details.start_time));
                dispatch(mediaSetCurrentTime(details.start_time));
                dispatch(chromecastSetIsPlay(true));
                dispatch(chromecastSetMediaId(details.title))
            }

            
        }else{
            goToEpisode(history, details.title, details.episode)
        }
    }

    const calculateProgressPercent = () => {
        return (details.start_time/details.duration) * 100
    }

    return (
        <div className='continue-watching-content' onClick={handleOnClick}>
            <div>
                <div className='continue-watching-content-thumbnail'>
                    <img src={details.thumbnail_url} alt='' />
                </div>
                <div className='continue-watching-content-progress-background'>
                </div>
                <div style={{"width": calculateProgressPercent() + "%"}} className='continue-watching-content-progress'>
                </div>
            </div>
            <div>
                <div className='continue-watching-content-display-episode'>
                    {details.episode_title_display}
                </div>
                <div className='continue-watching-content-display-title'>
                    {details.title_display}
                </div>
            </div>
        </div>
    )
}

export default ContinueWatchingContent