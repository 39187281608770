export const siteSetCurrentPage = currentPage => ({
    type: 'SITE_SET_CURRENT_PAGE',
    currentPage
})

export const siteSetIsAvailable = isAvailableStatus => ({
    type: 'SITE_SET_IS_AVAILABLE_STATUS', isAvailableStatus
})

export const siteSetAvailableMessage = isAvailableMessage => ({
    type: 'SITE_SET_IS_AVAILABLE_MESSAGE',
    isAvailableMessage
})

export const siteSetForcePlayVideo = forcePlayVideo => ({
    type: 'SITE_SET_FORCE_PLAY_VIDEO',
    forcePlayVideo
})


export const siteSetIsUserAlreadyInteractWithMedia = isUserAlreadyInteractWithMedia => ({
    type: 'SITE_SET_IS_USER_ALREADY_INTEREACT_WITH_MEDIA',
    isUserAlreadyInteractWithMedia
})

export const siteSetisClickFromSlideShow = isClickFromSlideShow => ({
    type: 'SITE_SET_CLICK_FROM_SLIDE_SHOW',
    isClickFromSlideShow
}) 

export const siteSetisPlayMediaNextEpisode = isPlayMediaNextEpisode => ({
    type: 'SITE_SET_IS_PLAY_MEDIA_NEXT_EPISODE',
    isPlayMediaNextEpisode
}) 

export const siteSetCurrentLanguage = currentLanguage => ({
    type: 'SITE_SET_CURRENT_LANGUAGE',
    currentLanguage
})

export const siteSetBeforeTitleVideo = beforeTitleVideo => ({
    type: 'SITE_SET_BEFORE_TITLE_VIDEO',
    beforeTitleVideo
})