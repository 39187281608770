import React, {useEffect} from 'react'
import {callApiSubscriptionStatus, callApiTopupStatus, getContinueWatching, getHomePage, receiptValidator} from '../../api';
import MediaListContainer from '../../components/home/MediaListContainer'
import {connect, useSelector} from 'react-redux'
import {accountModalSetCurrentTab, accountModalSetShow, homeSetContinueWatchingList, homeSetShelf, homeSetSlideshow, loginModalSetCurrentTab, loginModalSetHidden, premiumModalRefresh, premiumModalSetCurrentTab, premiumModalSetShow} from '../../actions'
import SlideshowContentList from '../../components/home/SlideshowContentList';
import MediaBannerContentList from '../../components/home/MediaBannerContentList';
import Ads from '../../components/ads/Ads';
import ContinueWatchingList from './ContinueWatchingList';
import { alertErrorByCode, checkLanguageCookieFromUrl, hideLoading, isCheckLanguageFromUrl, isLanguageFromUrl, isMobileWidth, isUserPremiumStatusActive, processCheckLanguageFromUrl, showLoading } from '../../helper/Helper';
import { useLocation } from "react-router-dom";
import { useHistory } from 'react-router';
import { isUserLoggedIn } from '../../helper/ReduxStoreHelper';
import { useParams } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie';

// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const Home = ({shelf, slideshow,setShelf,setSlideshow, setContinueWatchingList, dispatch}) => {

    const query = useQuery()
    const history = useHistory()

    const siteCurrentPage = useSelector(state => state.site.currentPage)
    const platformPremium = useSelector(state => state.user.premiumPlatform)
    const premiumExpire = useSelector(state => state.user.premiumExpire)
    const {language} = useParams();
    const {slug} = useParams();
    const [cookiesLanguage, setCookieLanguage] = useCookies(['currentLanguage']);
    const { t , i18n} = useTranslation('trans');

    // สำหรับใช้งานกรณีที่ redirect มาจาก 2c2p
    const getSubscriptionPaymentFinishQueryString = () => {
        if( query.get("subscription_payment_finish") ){
            return query.get("subscription_payment_finish");
        }else{
            return '';
        }
    }

     // สำหรับใช้งานกรณีที่ redirect มาจาก customer_portal 
     const getPaymentCancelFromCustomerPortal = () => {
        if( query.get("from_customer_portal") ){
            return query.get("from_customer_portal");
        }else{
            return '';
        }
    }

    // สำหรับใช้งานกรณีที่ redirect มาจาก 2c2p
    const getTopupPaymentFinishQueryString = () => {
        if( query.get("topup_payment_finish") ){
            return query.get("topup_payment_finish");
        }else{
            return '';
        }
    }

     // สำหรับใช้งานกรณีที่ redirect มาจาก 2c2p
     const getPaymentCancelQueryString = () => {
        if( query.get("cancel") ){
            return query.get("cancel");
        }else{
            return '';
        }
    }

    var movieListCount = 0;

    useEffect(() => {

        processCheckLanguageFromUrl(language)
        
		getHomePage().then( (res) => { processApi(res) } );

	    getContinueWatching().then( handleContinueWatchingResponse );

        // กรณีที่มี querystring ชุดนี้มาแสดงว่า redirect มาจาก 2c2p
        if( getSubscriptionPaymentFinishQueryString() === 'true'){

            // จะทำการล้างค่า URL ออก จากนั้นจะ push history เข้าไปเพิ่ม เพื่อไม่ให้ลูกค้าสามารถกด Back กลับหน้า Payment ได้
            // replace history เนื่องจาก url ที่เรียกมาหลังจาก 2c2p payment จะมี querystring ติดมาด้วยเพื่อนำมาประมวลผล เราไม่ต้องการให้ user เห็นรวมถึงไม่ต้องการให้ user กดกลับมาที่ URL นี้ได้
            history.replace( window.flixer.homeUrl, {'flixerWeb': true, "fromPaymentPage": true});
            history.push('/', {'flixerWeb': true, "fromPaymentPage": true});
            history.push('/', {'flixerWeb': true, "fromPaymentPage": true});
            // Push ค่าสุดท้ายให้ไม่มี state firstPage เพื่อที่เวลาไปหน้าอื่นแล้วจะได้กดกลับมาได้
            history.push('/', {'flixerWeb': true});
            
            // กรณีเป็น Cancel จะทำการขึ้นหน้า Sub และไม่ทำอะไรต่อ
            if( getPaymentCancelQueryString() === 'true' ){
                dispatch(premiumModalSetShow(true))
            }else{
    
                dispatch(premiumModalSetShow(true))
                showLoading()
    
                let intervalCount = 0
    
                async function process() {
    
                    intervalCount++
    
                    // Set limit จำนวนครั้งในการเรียกซ้ำ ถ้าเรียกเกินนี้ก็จะหยุดเรียกต่อและถือว่าไม่ผ่านละ
                    if( intervalCount <= 3 ){
    
                        let response = await callApiSubscriptionStatus()
    
                        // กรณีที่ลูกค้าได้เป็น premium แล้วไ
                            if( isUserPremiumStatusActive(response.result.subscription_status) ){
                                await receiptValidator()
                                dispatch(premiumModalRefresh(true))
        
                                if(getPaymentCancelFromCustomerPortal() !== 'true') {
                                // จะต้องให้ด้านหลังเปลี่ยนเป็น premium ก่อนค่อยขึ้น alert
                                    setTimeout( () => {
                                        hideLoading()
                                        alert(t('UpgradeSuccess'))
                                    }, 1000)
                                } else {
                                    hideLoading()
                                }

                        }else if( response.result.subscription_status === "fail" ){
                            hideLoading()
                            dispatch(premiumModalSetShow(false))
                            alertErrorByCode("0002", "", response.result.subscription_message)
                        }else if( response.result.subscription_status === "purchase" ){
                            // จะเรียก API ซ้ำทุกๆ 5 วินาที กรณีเป็น purchase (pending)
                            setTimeout(() =>{
                                process()
                            }, 5000)
                        }else{
                            // เคสนี้คือ API ตอบอะไรมาไม่รู้
                            hideLoading()
                            dispatch(premiumModalSetShow(false))
                            alertErrorByCode("500")
                        }
    
                    }else{
    
                        hideLoading()
                        dispatch(premiumModalSetShow(false))
                        alertErrorByCode("0003")
                    }
                }
    
                process()
            }
        } 
        
        if( getTopupPaymentFinishQueryString() === 'true' ){
            //มาจาก 2c2p ในการซื้อวัน

            // จะทำการล้างค่า URL ออก จากนั้นจะ push history เข้าไปเพิ่ม เพื่อไม่ให้ลูกค้าสามารถกด Back กลับหน้า Payment ได้
            // replace history เนื่องจาก url ที่เรียกมาหลังจาก 2c2p payment จะมี querystring ติดมาด้วยเพื่อนำมาประมวลผล เราไม่ต้องการให้ user เห็นรวมถึงไม่ต้องการให้ user กดกลับมาที่ URL นี้ได้
            history.replace( window.flixer.homeUrl, {'flixerWeb': true, "fromPaymentPage": true});
            history.push('/', {'flixerWeb': true, "fromPaymentPage": true});
            history.push('/', {'flixerWeb': true, "fromPaymentPage": true});
            // Push ค่าสุดท้ายให้ไม่มี state firstPage เพื่อที่เวลาไปหน้าอื่นแล้วจะได้กดกลับมาได้
            history.push('/', {'flixerWeb': true});


            // กรณีเป็น Cancel จะทำการขึ้นหน้า Sub และไม่ทำอะไรต่อ
            if( getPaymentCancelQueryString() === 'true' ){
                dispatch(premiumModalSetShow(true))
            }else{
                dispatch(premiumModalSetShow(true))
                showLoading()
    
                let intervalCount = 0
    
                async function process() {
    
                    intervalCount++
    
                    // Set limit จำนวนครั้งในการเรียกซ้ำ ถ้าเรียกเกินนี้ก็จะหยุดเรียกต่อและถือว่าไม่ผ่านละ
                    if( intervalCount <= 3 ){
    
                        let response = await callApiTopupStatus()
    
                        // กรณีที่ลูกค้าได้เป็น premium แล้วไ
                        if( isUserPremiumStatusActive(response.result.subscription_status) ){
                            await receiptValidator()
                            // dispatch(premiumModalRefresh(true))
                            dispatch(premiumModalSetShow(false))
    
                            // จะต้องให้ด้านหลังเปลี่ยนเป็น premium ก่อนค่อยขึ้น alert
                            setTimeout( () => {
                                hideLoading()
                                alert('เติมวันสำเร็จ')
                            }, 1000)
                        }else if( response.result.subscription_status === "fail" ){
                            hideLoading()
                            dispatch(premiumModalSetShow(false))
                            alertErrorByCode("0002", "", response.result.subscription_message)
                        }else if( response.result.subscription_status === "purchase" ){
                            // จะเรียก API ซ้ำทุกๆ 5 วินาที กรณีเป็น purchase (pending)
                            setTimeout(() =>{
                                process()
                            }, 5000)
                        }else{
                            // เคสนี้คือ API ตอบอะไรมาไม่รู้
                            hideLoading()
                            dispatch(premiumModalSetShow(false))
                            alertErrorByCode("500")
                        }
    
                    }else{
    
                        hideLoading()
                        dispatch(premiumModalSetShow(false))
                        alertErrorByCode("0003")
                    }
                }
    
                process()                
            }

        }

        if(siteCurrentPage  === "manageAccount") {
            if(isUserLoggedIn()) {
                dispatch(accountModalSetCurrentTab('manageAccount'))
                dispatch(accountModalSetShow(true))
            } else {
                dispatch(loginModalSetCurrentTab(''))
            dispatch(loginModalSetHidden(false))
            }
        }

        // if(siteCurrentPage  === "otherpackages") {
        //     if(platformPremium !== "flixer") {
        //         dispatch(premiumModalSetShow(true))
        //         if(premiumExpire != null || premiumExpire === '') {
        //             dispatch(premiumModalSetCurrentTab('topupExpire'))
        //         } else {
        //             dispatch(premiumModalSetCurrentTab('topup'))
        //         }
        //     }
        // }

	}, []);

    useEffect(() => {

        if(siteCurrentPage  === "manageAccount") {
            if(isUserLoggedIn()) {
                dispatch(accountModalSetCurrentTab('manageAccount'))
                dispatch(accountModalSetShow(true))
            } else {
                dispatch(loginModalSetCurrentTab(''))
            dispatch(loginModalSetHidden(false))
            }
        }

    //     if(siteCurrentPage  === "otherpackages") {
    //         if(platformPremium !== "flixer") {
    //             // if(platformPremium === "flixer-topup" || platformPremium === '') {
                
    //             dispatch(premiumModalSetShow(true))

    //             if(premiumExpire != null || premiumExpire === '') {
    //                 dispatch(premiumModalSetCurrentTab('topupExpire'))
    //             } else {
    //                 dispatch(premiumModalSetCurrentTab('topup'))
    //             }
                
    //         }
    //     }

    }, [siteCurrentPage ])

    const processApi = function(response){

        response.result.shelf = setProperlyAdsSize(response.result.shelf)

        setShelf(response.result.shelf);
        setSlideshow(response.result.slideshow);
    }

    const handleContinueWatchingResponse = (response) => {
        if( response.result !== null){
            setContinueWatchingList(response.result)
        }
    }

    // จะต้อง Set ขนาดของ Ads ให้เป็นตามที่มีบน server และ แบ่งขนาดเป็น 2 ส่วนคือ mobile กับ desktop
    const setProperlyAdsSize = ( responseShelf ) => { 

        return responseShelf.map( ( shelfContent ) => {
            if( shelfContent.type === 'ads'){
                if( isMobileWidth() ){
                    shelfContent.ads_data.dfp_web.width  = 300
                    shelfContent.ads_data.dfp_web.height = 250
                }else{
                    shelfContent.ads_data.dfp_web.width  = 750
                    shelfContent.ads_data.dfp_web.height = 100
                }
            }
            return shelfContent
        })

    }

    return (
        <div className='home-page'>
            {
                shelf 
                ?
                <React.Fragment>
                    <SlideshowContentList list={slideshow} />
                    {
                    shelf.map( ( shelfContent, index ) => {

                        if( shelfContent.type === 'movie'){
                                return <MediaListContainer key={index} title={shelfContent.title} lists={shelfContent.data} shelfId={shelfContent.id} />;
                        } else if( shelfContent.type === 'player'){
                            return (
                                <React.Fragment key={index}>
                                    {/* <MediaListContainer title={shelfContent.title} lists={shelfContent.data} shelfId={shelfContent.id} /> */}
                                    <ContinueWatchingList />
                                </React.Fragment>)
                        }else if( shelfContent.type === 'banner2' ){
                            return <MediaBannerContentList key={index} list={shelfContent.data}/>;
                        }else if( shelfContent.type === 'ads' ){
                            return  <Ads 
                                        key={index} 
                                        adUnitId={shelfContent.ads_data.dfp_web.ads_unit_id} 
                                        sampleImage={shelfContent.ads_data.dfp_web.banner_url} 
                                        width={parseInt(shelfContent.ads_data.dfp_web.width)} 
                                        height={parseInt(shelfContent.ads_data.dfp_web.height)}
                                    />
                        }else{
                            return "";
                        }
                    })
                    }
                </React.Fragment>
                :
                ""
            }
        </div>
    );
}


const mapStateToProps = (state, ownProps) => ({
    shelf: state.home.shelf,
    slideshow: state.home.slideshow
})
  
const mapDispatchToProps = (dispatch, ownProps) => ({
    setShelf: (shelf) => dispatch(homeSetShelf(shelf)) ,
    setSlideshow: (slideshow) => dispatch(homeSetSlideshow(slideshow)) ,
    setContinueWatchingList: (list) => dispatch(homeSetContinueWatchingList(list)),
    dispatch
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home)
