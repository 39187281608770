import React, { useEffect, useState } from 'react'
import i18n from '../../components/i18n';
import {Suspense} from 'react';
import { store } from '../..';
import { useDispatch, useSelector } from 'react-redux';
import { siteSetCurrentLanguage } from '../../actions';
import { Cookies, useCookies } from 'react-cookie';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { consoleLogHelper, goToHomePage, goToPage, hideLoading, isMobileWidth, showLoading } from '../../helper/Helper';
import { Dropdown } from 'react-bootstrap';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { FaGlobe } from 'react-icons/fa';



const LanguageButton = ({languageCurrent}) => {

    const history = useHistory();
    const [cookies, setCookie] = useCookies(['currentLanguage']);
    
    const [textTitleLanguage, setTextTitleLanguage] = useState('');

  useEffect(()=>{
    if(languageCurrent === 'en') {
        setTextTitleLanguage('EN')
    } else if (languageCurrent === 'es'){
        setTextTitleLanguage('ES')
    } else {
        setTextTitleLanguage('EN')
    }
    
}, [languageCurrent])

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }

    const query = useQuery()
    
    const clickChangeLanguageButton = (language) => {

        //ถ้าภาษาไม่ตรงกับภาษาปัจจุบันค่อยทำงานต่อ
        if(languageCurrent !== language) {

        store.dispatch(siteSetCurrentLanguage(language))
        // window.location.reload()
        i18n.changeLanguage(language)

        // setCookie('currentLanguage', language)  // maxAge 1 year
        setCookie('currentLanguage', language, { path: '/', maxAge: 31622400, domain: window.flixer.cookieDomainDefault })
        // const cookies = new Cookies();
        // cookies.set('currentLanguage', language , { path: '/' });

        let url = window.location.pathname
        // if (url.includes('/es/')) {
        //     url = url.substring(3);
        //     url = '/' + language + url
        // }

        if(language === "es") {
            url = '/' + language + url
        } else {
            url = url.substring(3);
        }
        

        // goToPage(history, url, true, true)


        if( url.includes('/search/') ){
            url = url + '?q=' + query.get('q')
        }
    
        history.replace(url , { 'flixerWeb': true });




        // console.log("change lang", language)
        // console.log("historyPath" , history.location.pathname)
        // window.location.replace(url)

        // window.location.pathname = window.flixer.homeUrl + url

        // window.location = window.flixer.homeUrl + newLocation

        // showLoading()

        // history.go(0)


        // let x = setTimeout(function() {
            window.location.reload()
        //     hideLoading()
        //     clearTimeout(x);
        // }, 2000);


    }
       

        
    
    }

    

    return (
        <Suspense fallback={<div>Loading...</div>}>

        <Dropdown>
          
                <Dropdown.Toggle className='btn' id="dropdown" style={{background: "black" , borderRadius:"25px", borderColor: "white", paddingLeft: "10px", paddingRight: "10px", paddingTop: "2px", paddingBottom: "2px", fontSize: "15px", marginRight: "15px"}}><FaGlobe fill='white' style={{marginBottom: "3px"}}/> {textTitleLanguage}</Dropdown.Toggle>
            
        <Dropdown.Menu  style={{background: "black"}}>
            <Dropdown.Item href="" style={{background: "black", color: "white"}} onClick={() => clickChangeLanguageButton('en')}>English</Dropdown.Item>
            {/* <Dropdown.Item href="" style={{background: "black", color: "white"}} onClick={() => clickChangeLanguageButton('th')}>TH</Dropdown.Item> */}
            <Dropdown.Item href="" style={{background: "black", color: "white"}} onClick={() => clickChangeLanguageButton('es')}>Spanish</Dropdown.Item>
          </Dropdown.Menu>  
        </Dropdown>
        </Suspense>
    )
}

export default LanguageButton
