import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import FlixerLogo from '../../images/flixer-logo.png'

const PageNotFound = () => {

    const { t , i18n} = useTranslation('trans');
    const [cookieCurrentLanguage , setCookieCurrentLanguage] = useState('');
    const [termOfUseLink , setTermOfUseLink] = useState('');
    const [privacyPolicyLink , setPrivacyPolicyLink] = useState('');

    useEffect(() => {

        let cookieLanguage = document.cookie.replace(/(?:(?:^|.*;\s*)currentLanguage\s*\=\s*([^;]*).*$)|^.*$/, "$1")
        setCookieCurrentLanguage(cookieLanguage)

        if(cookieLanguage !== '') {
          i18n.changeLanguage(cookieLanguage)
        } else {
          i18n.changeLanguage("en")
          setCookieCurrentLanguage('en')
        }
        setTermOfUseLink('/' + cookieLanguage + '/term-of-use')
        setPrivacyPolicyLink('/' + cookieLanguage + '/privacy-policy')

    }, [])

    const handleOnClickGoToHomePage = () => {
        window.location.href = "/";
    }

    // text-align: center;
    // line-height: 1;
    // width: fit-content;
    // position: relative;
    // top: calc(45vh - calc(291px/2)); /* เป็นการจัดกลางให้กับทุกๆความสูง 291 คือความสูงของ Content บน Desktop */
    // margin: auto;

    return (
        <div className="service-unavailable-page">
            <div className="service-unavailable-content" style={{top : "calc(30vh - calc(291px/2))"}}>
                <img className='service-unavailable-logo' src={FlixerLogo} alt='' style={{}} onClick={handleOnClickGoToHomePage}/>
                <div className='service-unavailable-title'>
                    {/* {t('ServiceUnavailable')} */}
                    {/* Page Not Found */}
                </div>
                <div className='service-unavailable-information'>
                    {/* {message} */} This Page Isn't Available. 
                </div>
                <div className='button-goto-homepage'>

                    <div className='' style={{marginTop: "50px", 
                                            borderRadius: "50px", 
                                            width: "100%",
                                            backgroundColor : "RGB(165,37,44)", 
                                            paddingTop : "10px",
                                            paddingBottom: "10px",
                                            fontSize : "22px",
                                            color : "black",
                                            textAlign: "center",
                                            alignItem: "center",
                                            cursor: "pointer"
                                    }} 
                                            onClick={handleOnClickGoToHomePage}><strong>Go to Home Page</strong></div>
                </div>
                {/* <div className='service-unavailable-terms'>
                    <a className='service-unavailable-terms-terms-of-use' target='_blank' href={t('TermOfUseLink')}>{t('TermOfUse')}</a>
                    <a target='_blank' href={t('PrivacyPolicyLink')}>{t('PrivacyPolicy')}</a>
                </div> */}
            </div>
        </div>
    )
}

export default PageNotFound