import {useEffect} from 'react'
import { useDispatch } from 'react-redux';
import { useLocation } from "react-router-dom"
import { siteSetCurrentPage } from '../../actions';
import { isFlixerPlusPage, isHomePage, isCategoryPage, firebaseLogEvent, isRedeemPage, consoleLogHelper, isMovieMenuPage, isCategoryMenuMovie, isCategoryMenuAnime, isCategoryMenuDocumentary, isCategoryMenuIdol, isCategoryMenuGundam, isCategoryMenuMaskedRider, isCategoryMenuChildren, isPurchaseDatePage, isOtherPackages, isPageNotFound, isManageAccountPage } from '../../helper/Helper';

const HandePageChange = () => {

    const dispatch = useDispatch()

    const { pathname } = useLocation();
  
    useEffect(() => {

        firebaseLogEvent('page_view',{
            page_title: '',
            page_location: window.location.href,
            page_path:  window.location.pathname + window.location.search
        })

        consoleLogHelper(`HandePageChange`, pathname)
        if( isHomePage(pathname) ){
            dispatch(siteSetCurrentPage('home'))
        }
        // else if( isCategoryMenuMovie(pathname)){
        //     dispatch(siteSetCurrentPage('movie')) //submenu
        // }else if(isCategoryMenuChildren(pathname)) { 
        //     dispatch(siteSetCurrentPage('children')) //submenu
        // }else if(isCategoryMenuIdol(pathname)) { 
        //     dispatch(siteSetCurrentPage('idol')) //submenu
        // }else if(isCategoryMenuGundam(pathname)) {
        //     dispatch(siteSetCurrentPage('gundam')) //submenu
        // }else if(isCategoryMenuMaskedRider(pathname)) {
        //     dispatch(siteSetCurrentPage('masked rider')) //submenu
        // }
        else if( isCategoryPage(pathname) ){
            dispatch(siteSetCurrentPage('category'))
        }else if( isFlixerPlusPage(pathname) ){
            dispatch(siteSetCurrentPage('flixer-plus'))
        }else if( isRedeemPage(pathname) ){
            dispatch(siteSetCurrentPage('redeem'))
        } else if(isPageNotFound(pathname)) {
            dispatch(siteSetCurrentPage('pageNotFound'))
        } else if (isManageAccountPage(pathname)) {
            dispatch(siteSetCurrentPage('manageAccount'))
        }
        // else if( isOtherPackages(pathname)) {
        //     dispatch(siteSetCurrentPage('otherpackages'))
        // }
        else{
            dispatch(siteSetCurrentPage(''))
        }
    }, [pathname]);
  
    return null;
}

export default HandePageChange
