import React, { useEffect, useState } from 'react'
import { callApiRentalList } from '../../api'
import AccountModalHeader from './AccountModalHeader'
import RentalContentList from './RentalContentList'
import { useDispatch } from 'react-redux'
import { accountModalSetCurrentTab } from '../../actions'
import NoRentalList from './NoRentalList'

const FlixerPlusAccount = () => {

    const dispatch = useDispatch()

    const [loaded, setLoaded] = useState(false)

    const [rentalList, setRentalList] = useState([])

    useEffect( () => {
        // callApiRentalList().then(handleRentalListResponse)
    }, [])

    const handleRentalListResponse = (response) => {
        setLoaded(true)
        if( !response.error ){
            setRentalList(response.result)
        }
    }

    return (
        <div>
            <AccountModalHeader title={'Flixer+'} haveBackButton={true} backButtonFunction={() => dispatch(accountModalSetCurrentTab(''))}/>
            {
                    loaded 
                ?
                        rentalList && rentalList.length > 0
                    ?
                        <RentalContentList list={rentalList} />
                    :
                        <NoRentalList/>
                :
                    ""
            }
        </div>
    )
}

export default FlixerPlusAccount
