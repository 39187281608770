import React from 'react'
import { useHistory } from 'react-router-dom'
import {goToSeeAllPage} from '../../helper/Helper'
import {Suspense} from 'react';
import { useTranslation } from 'react-i18next'

const SeeAllButton = ({shelfId}) => {

    let history = useHistory();

    const handleOnClick = () => {
        goToSeeAllPage(history, shelfId)
    }

    const { t , i18n} = useTranslation('trans');

    return (
        <div className='see-all-button cursor-pointer position-relative float-end' onClick={handleOnClick}>
            {/* See All */}
            {t('seeAll')}
        </div>
    )
}

export default SeeAllButton
