import React from 'react'
import {ReactComponent as AccountIcon} from './../../images/navbar/account.svg'
import MenuButton from '../../components/navbar/MenuButton';
import {connect} from 'react-redux'
import { accountModalSetCurrentTab, accountModalSetShow, loginModalSetCurrentTab, loginModalSetHidden } from '../../actions';
import { isUserLoggedIn, isUserPremium } from '../../helper/ReduxStoreHelper';
import ImageProfileDummyIcon from '../../images/profile-dummy-icon.png'
import PremiumFrame from '../../images/navbar/premium-frame.png'
import {Suspense} from 'react';
import { useTranslation } from 'react-i18next'
import { isMobileWidth } from '../../helper/Helper';
import SignInNavbarButton from './SignInNavbarButton';
import SignInNavbarButtonMobile from './SignInNavbarButtonMobile';

const AccountButton = ({ user, dispatch }) => {

    const { t , i18n} = useTranslation('trans');

    const handleOnClick = () => {

        // กรณีที่ Login อยู่แล้วจะเข้าหน้า Account
        if( isUserLoggedIn() ){
            dispatch(accountModalSetCurrentTab(''))
            dispatch(accountModalSetShow(true))
        }else{
            // ถ้ายังไม่ได้ Login อยู่ จะขึ้น Login Modal tab แรกเสมอ
            dispatch(loginModalSetCurrentTab(''))
            dispatch(loginModalSetHidden(false))
        }

    }
        
    return (
        <div className='nav-bar-menu-button nav-bar-account-menu-button' onClick={handleOnClick}>
            {
                    isUserLoggedIn() 
                ?
                      isMobileWidth() ? 

                      <img style={{borderRadius: "100px", width:"25px", height : "25px", marginRight:"10px" , objectFit: "cover"}} className='search-icon-navbar' src={user.profile.profileImageUrl ? user.profile.profileImageUrl : ImageProfileDummyIcon} alt='' />
                    //   <MenuButton 
                    //     className='account-menu-button'
                    //     image={user.profile.profileImageUrl ? user.profile.profileImageUrl : ImageProfileDummyIcon} 
                    //     // name='' />
                    //     name='' />
                      :
                      
                      <img style={{borderRadius: "100px", width:"25px", height : "25px", marginRight:"10px" , objectFit: "cover"}} className='search-icon-navbar' src={user.profile.profileImageUrl ? user.profile.profileImageUrl : ImageProfileDummyIcon} alt='' />

                    // <MenuButton 
                    //     className='account-menu-button'
                    //     image={user.profile.profileImageUrl ? user.profile.profileImageUrl : ImageProfileDummyIcon} 
                    //     // name='' />
                    //     name={ isUserLoggedIn() ? t('navbar.myprofile') : t('navbar.signin') } />
                :
                isMobileWidth() ? 

                    <SignInNavbarButtonMobile/>
                // <MenuButton 
                //         className='account-menu-button'
                //         image={user.profile.profileImageUrl ? user.profile.profileImageUrl : ImageProfileDummyIcon} 
                //         // name='' />
                //         name='' /> 
                :
                    // <MenuButton className='account-menu-button' svgComponent={<AccountIcon />} 
                    // // name='' />
                    // name={ isUserLoggedIn() ? t('navbar.myprofile') : t('navbar.signin') } />

                    <SignInNavbarButton/>

            }
            {
                //     isUserPremium()
                // ?
                //     <div className='nav-bar-menu-button-premium-frame'>
                //         <img className='w-100' src={PremiumFrame} alt='' />
                //     </div>
                // :
                // ''
            }

                    


        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    user: state.user
})
  
const mapDispatchToProps = (dispatch, ownProps) => ({
    dispatch
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountButton)
