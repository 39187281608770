import React, { useState } from 'react'
import IconArrowUp from '../../images/video/arrow_up.png'
import IconArrowDown from '../../images/video/arrow_down.png'
import { isMobileWidth } from '../../helper/Helper';
import { useTranslation } from 'react-i18next';

const Description = ({ text, mediaDetail }) => {

    const [showMore, setShowMore] = useState(false);
    const { t , i18n} = useTranslation('trans');

    return (
        <div className='content-video-detail'>

            <div className='video-page-description mb-4' style={{marginBottom: "80px"}}>
                <font style={{opacity: "0.7" , whiteSpace: "pre-wrap"}}>
                    {
                        (mediaDetail.synopsis != null) ? mediaDetail.synopsis : '-'
                    }
                </font>
            </div>

            { 

            (showMore === true) ?


                isMobileWidth() ? 

                <div className='show-more-content'>

                    {
                        (mediaDetail.description != null && mediaDetail.description != "") ? 

                        <div className='video-page-description mb-4' style={{marginBottom: "40px"}}>
                            <h4>{t('Description')}</h4>
                            <font style={{opacity: "0.7" , whiteSpace: "pre-wrap"}}>
                                { mediaDetail.description }
                            </font>
                        </div>
                        
                        : ''
                    }
                    

                    <div className='col-md-12 '>
                        <div className='row'>
                        <div className='col-md-6 col-sm-12' style={{marginBottom: "40px"}}>
                            <h4>{t('Staff')}</h4>
                            <label style={{opacity: "0.7" , whiteSpace: "pre-wrap"}}>
                                {
                                    (mediaDetail.staff_details != null) ? mediaDetail.staff_details : '-'
                                }
                            </label>
                        </div>
                        <div className='col-md-6 col-sm-12' style={{marginBottom: "40px"}}>
                            <h4>{t('Cast')}</h4>
                            <font style={{opacity: "0.7" , whiteSpace: "pre-wrap"}}>
                                {
                                    (mediaDetail.cast != null) ? mediaDetail.cast : '-'
                                }
                            </font>
                        </div>
                        </div>
                    </div>


                    <div className='col-md-12'>
                        <div className='row'>
                        <div className='col-md-6 col-sm-12' style={{marginBottom: "40px"}}>
                            <h4>{t('Subtitles')}</h4>
                            
                            <font style={{opacity: "0.7" , whiteSpace: "pre-wrap"}}>
                                {
                                    (mediaDetail.subtitles != null) ? mediaDetail.subtitles : '-'
                                }
                                </font>
                        </div>
                        <div className='col-md-6 col-sm-12' style={{marginBottom: "40px"}}>
                            <h4>{t('Dubbing')}</h4>
                            {/* English ( Dolby Atmos , Dolby 5.1 , AAC , AD ) */}
                            <font style={{opacity: "0.7" , whiteSpace: "pre-wrap"}}>
                                {
                                    (mediaDetail.audios != null) ? mediaDetail.audios : '-'
                                }
                            </font>
                        </div>
                        </div>
                    </div>

                </div>
            : 

            <div className='show-more-content'>

            {
                (mediaDetail.description != null && mediaDetail.description != "") ? 

                <div className='video-page-description mb-4' style={{marginBottom: "40px"}}>
                    <h4>{t('Description')}</h4>
                    <font style={{opacity: "0.7" , whiteSpace: "pre-wrap"}}>
                        { mediaDetail.description }
                    </font>
                </div>
                
                : ''
            }

            <div className='col-md-12 ' style={{marginBottom: "40px"}}>
                <div className='row'>
                <div className='col-md-6 col-sm-12'>
                    <h4>{t('Staff')}</h4>
                    <label style={{opacity: "0.7" , whiteSpace: "pre-wrap"}}>
                        {
                            (mediaDetail.staff_details != null) ? mediaDetail.staff_details : '-'
                        }
                    </label>
                </div>
                <div className='col-md-6 col-sm-12'>
                    <h4>{t('Cast')}</h4>
                    <font style={{opacity: "0.7" , whiteSpace: "pre-wrap"}}>
                        {
                            (mediaDetail.cast != null) ? mediaDetail.cast : '-'
                        }
                    </font>
                </div>
                </div>
            </div>


            <div className='col-md-12' style={{marginBottom: "40px"}}>
                <div className='row'>
                <div className='col-md-6 col-sm-12'>
                    <h4>{t('Subtitles')}</h4>
                    {/* Enlish (CC,SDH), Arabic(SDH), Bugarian(SDH) */}
                    <font style={{opacity: "0.7" , whiteSpace: "pre-wrap"}}>
                        {
                            (mediaDetail.subtitles != null) ? mediaDetail.subtitles : '-'
                        }
                    </font>
                </div>
                <div className='col-md-6 col-sm-12'>
                    <h4>{t('Dubbing')}</h4>
                    {/* English ( Dolby Atmos , Dolby 5.1 , AAC , AD ) */}
                    <font style={{opacity: "0.7" , whiteSpace: "pre-wrap"}}>
                        {
                            (mediaDetail.audios != null) ? mediaDetail.audios : '-'
                        }
                    </font>
                </div>
                </div>
            </div>

        </div>

        : ''
        
            }

            { 
            (text !== '') ?
            <div style={{color: "white", width: "100%" , paddingTop: "60px"}} className="btn text-center" onClick={() => setShowMore(!showMore)}>
                {showMore ? 
                <div className='text-center'>
                    <div className='col-md-12' style={{ marginBottom: "2px", color:"white"}}>
                        <img className='' src={IconArrowUp} alt='' style={{width: "20px" }}/> 
                    </div>
                    <div className='col-md-12'>
                        <font className='text-center'>{t('Less')}</font>
                    </div>
                </div>
                : 
                <div className='text-center'>
                <div className='col-md-12'>
                    <font className='text-center'>{t('More')}</font>
                </div>
                <div className='col-md-12' style={{marginBottom: "2px", color:"white", marginTop: "-2px"}}>
                    <img className='' src={IconArrowDown} alt='' style={{width: "20px"}}/>
                </div>
                    
                </div>
                }
            </div>
            : '' 
            }
        </div>
    )
}

export default Description
