import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import {Modal, Container, Row, Col} from 'react-bootstrap'
import { accountModalSetCurrentTab, accountModalSetShow, premiumModalSetCurrentTab, premiumModalSetShow } from '../../actions'
import { changePassword, logout } from '../../api'
import ImageProfile from './ImageProfile'
import { firebaseLogEvent, locationToHomePage, hideLoading, showLoading, goToRedeemPage } from '../../helper/Helper'
import { isUserLoggedIn, isUserPremium } from '../../helper/ReduxStoreHelper'
import PremiumIcon from './../../images/navbar/premium.png'
import FlixerPlusAccount from './FlixerPlusAccount'
import AccountModalHeader from './AccountModalHeader'
import ModalCloseButton from '../../components/global/ModalCloseButton'
import { useHistory } from 'react-router'
import {Suspense} from 'react';
import { useTranslation } from 'react-i18next'
import { useGoogleLogout } from 'react-google-login'
import ManageAccount from './ManageAccount'
import EmailProfile from './EmailProfile'
import DateOfBirth from './DateOfBirth'


const AccountModal = ({show, currentTab, userProfile, dispatch}) => {

    const { t , i18n} = useTranslation('trans');

    const platformPremium = useSelector(state => state.user.premiumPlatform)
    const premiumExpire = useSelector(state => state.user.premiumExpire)

    const [day , setBirthDayDate] = useState("");
    const [month , setBirthDayMonth] = useState("");
    const [year , setBirthDayYear] = useState("");
    const [dateLocaleFormat , setDateLocaleFormat] = useState("");

    const history = useHistory()

    const closeModal = () => {
        dispatch(accountModalSetShow(false))
    }

    const handleChangePassword = () => {
        showLoading()
        changePassword().then(handleChangePasswordResponse)
    }
    const handleChangePasswordResponse = (response) => {
        hideLoading()
        alert("Title: " + response.message.title + "\nMessage: " + response.message.message + "\n" )
    }

    
    const handleSignOut = () => {
        let confirm = window.confirm(t('AlertSignOut'));
        if (confirm) {
            // signOut()
            showLoading()
            logout().then(handleLogoutResponse)
        }
    }

    const onLogoutSuccess = () => {
        // console.log('logout');
        // history.push('/');
      };
      const onFailure = () => {
        console.log('logout fail');
      };

      //this is google signout
    const { signOut } = useGoogleLogout({
        clientId: '813172702758-ahvehtsnshf7221kku47d5devtono9ad.apps.googleusercontent.com',
        onLogoutSuccess: onLogoutSuccess,
        onFailure: onFailure,
      });

    const handleLogoutResponse = (response) => {
        hideLoading()
        if( response.error === false ){
            locationToHomePage()
        }else{
            alert(response.message)
        }

    }

    useEffect(() => {
        if(show){
            firebaseLogEvent('page_view',{
                page_title: 'MyProfile Modal',
                page_location: window.location.origin + '/modal/my-profile',
                page_path: '/modal/my-profile'
            })
        }
    }, [show])

    useEffect(() => {
        if(userProfile.dateOfBirth != null) {
            // const dateSplit = birthday.split('-');
            const dateSplit = userProfile.dateOfBirth.split('-');
            
            setBirthDayYear(dateSplit[0]);
            setBirthDayMonth(dateSplit[1]);
            setBirthDayDate(dateSplit[2]);



            const date = new Date(userProfile.dateOfBirth);
            let text = date.toLocaleDateString();
            // let text = date.toLocaleDateString("nl",{year:"2-digit",month:"2-digit", day:"2-digit"});
            setDateLocaleFormat(text);
            
        }
    }, [userProfile])

    const clickButtonSubscription = (productId, price, topupDays) => {

        dispatch(premiumModalSetShow(true))

        if(isUserLoggedIn()) {
            // if( platformPremium === "flixer-topup") {
            //     if(premiumExpire != null) {
            //         dispatch(premiumModalSetCurrentTab('topupExpire'))
            //     } else {
            //         dispatch(premiumModalSetCurrentTab('topup'))
            //     }
                
            // } else {
                dispatch(premiumModalSetCurrentTab(''))
            // }
            
        } else {
            dispatch(premiumModalSetCurrentTab(''))
        }
    }


    return (
        <Suspense fallback="loading">
        <Modal
            show={show}
            onHide={closeModal}
            dialogClassName={isUserPremium() ? 'account-modal-container premium-account modal-dialog-centered' : 'account-modal-container modal-dialog-centered'}
        >
            <Modal.Body>
                <ModalCloseButton onClick={closeModal}/>
                {
                    currentTab === '' &&
                    <Container>
                        <Row className='mb-5'>
                            <AccountModalHeader title={t('MyProfile')}/>
                        </Row>

                        <Row className='mt-5 mb-5'>
                            <ImageProfile imageProfile={userProfile.profileImageUrl} />
                            <div className='w-100'/>
                            <Col className='account-modal-premium-label text-center mt-3'>
                                {/* <img src={PremiumIcon} alt='' /> */}
                                {/* <span>{t('premium')}</span> */}
                            </Col>
                        </Row>

                        <Row className='account-modal-list-container'>
                            <Col className='account-modal-list account-modal-list-email' onClick={() => dispatch(accountModalSetCurrentTab('email'))}>
                                <Row>
                                    <Col>
                                    {t('Email')}
                                    </Col>
                                    <Col className='text-end account-modal-text-secondary-color' >
                                        {userProfile.email}
                                    </Col>
                                </Row>
                            </Col>
                            <div className='w-100'></div>
                            { (userProfile.dateOfBirth != null) ?  
                            <Col className='account-modal-list account-modal-list-email' >
                                <Row>
                                    <Col>
                                    {t('DateOfBirth')}
                                    </Col>
                                    <Col className='text-end account-modal-text-secondary-color' >
                                       
                                        {/* {day} / {month} / {year} */}
                                        {dateLocaleFormat}
                                        
                                    </Col>
                                </Row>
                            </Col>
                                : ''
                            }
                            <div className='w-100'></div>
                            <Col className='account-modal-list account-modal-list-change-password cursor-pointer' onClick={handleChangePassword}>
                                {t('ChangePassword')}
                            </Col>
                            <div className='w-100'></div>
                            <Col className='account-modal-list account-modal-list-subscription cursor-pointer' onClick={() => clickButtonSubscription()}>
                                {t('Subscription')}
                            </Col>
                            <div className='w-100'></div>
                            <Col className='account-modal-list account-modal-list-subscription cursor-pointer' onClick={() => dispatch(accountModalSetCurrentTab('manageAccount'))}>
                                {t('TextManageAccount')}
                            </Col>
                            <div className='w-100'></div>
                            {/* <Col className='account-modal-list account-modal-list-flixer-plus cursor-pointer' onClick={() => dispatch(accountModalSetCurrentTab('rental'))}>
                                Rental
                            </Col> */}
                            {/* <div className='w-100'></div>
                            <Col className='account-modal-list account-modal-list-redeem cursor-pointer' onClick={() => { closeModal(); goToRedeemPage(history); }}>
                                {t('Redeem')}
                            </Col> */}
                            <div className='w-100'></div>

                            <Col className='account-modal-list account-modal-list-signout cursor-pointer' onClick={handleSignOut}>
                                {t('SignOut')}
                            </Col>
                        </Row>
                    </Container>
                }
                {
                    currentTab === 'rental' &&
                    <FlixerPlusAccount />
                }
                {
                    currentTab === 'manageAccount' &&
                    <ManageAccount />
                }
                {
                    currentTab === 'email' &&
                    <EmailProfile emailProfile={userProfile.email} />
                }
                {
                    currentTab === 'dateOfBirth' &&
                    <DateOfBirth userBirthDay={userProfile.dateOfBirth}/>
                }
            </Modal.Body>
        </Modal>
        </Suspense>
    )
}


const mapStateToProps = (state, ownProps) => ({
    show: state.accountModal.show,
    currentTab: state.accountModal.currentTab,
    userProfile: state.user.profile
})
  
const mapDispatchToProps = (dispatch, ownProps) => ({
    dispatch
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountModal)
