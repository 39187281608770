import React, { useEffect, useState } from 'react'
import {Row, Col} from 'react-bootstrap'
import {Suspense} from 'react';
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom';


const TermAndPrivacy = () => {
    const { t , i18n} = useTranslation('trans');
    const {language} = useParams();
    const [cookieCurrentLanguage , setCookieCurrentLanguage] = useState('');
    const [termOfUseLink , setTermOfUseLink] = useState('');
    const [privacyPolicyLink , setPrivacyPolicyLink] = useState('');


    useEffect(() => {

        let cookieLanguage = document.cookie.replace(/(?:(?:^|.*;\s*)currentLanguage\s*\=\s*([^;]*).*$)|^.*$/, "$1")
        setCookieCurrentLanguage(cookieLanguage)

        if(cookieLanguage !== '') {
          i18n.changeLanguage(cookieLanguage)
        } else {
          i18n.changeLanguage("en")
          setCookieCurrentLanguage('en')
        }
        setTermOfUseLink('/' + cookieLanguage + '/term-of-use')
        setPrivacyPolicyLink('/' + cookieLanguage + '/privacy-policy')

    }, [])

    return (
        <Suspense fallback="loading">
        <Row className='login-modal-term-privacy'>
            <Col>
                {t('BySigningUpYouAgreeToOur')} <br/> <a target='_blank' href={t('TermOfUseLink')}>{t('TermOfUse')}</a> {t('And')} <a target='_blank' href={t('PrivacyPolicyLink')}>{t('PrivacyPolicy')}</a>
            </Col>
        </Row>
        </Suspense>
    )
}

export default TermAndPrivacy
