import {StepBackwardOutlined} from '@ant-design/icons';

const BackButton = () => {

    const handleOnClick = () => {
        window.flixer.castPlayerController.queueBack( () => {} );
    }

    return (
        <div className='chromecast-control-bar-back-btn menu-button-icon'>
            <StepBackwardOutlined onClick={ () => handleOnClick() } />
        </div>
    );

}   

export default BackButton