import React from 'react'
import Spinner from 'react-bootstrap/Spinner'

const Loading = () => {
    return (
        <div id='loading-container' className='loading-container'>
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    )
}

export default Loading
