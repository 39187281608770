import i18n from 'i18next'
import XHR from 'i18next-xhr-backend'
import { initReactI18next, reactI18nextModule } from 'react-i18next'
// import LngDetector from "i18next-browser-languagedetector";


i18n
  .use(XHR)
//   .use(reactI18nextModule)
  // .use(LngDetector) //language detector
  // .use(Fetch)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    ns: ['trans'],
    defaultNS: 'trans',
    debug: true,
    interpolation: {
      escapeValue: false
    },
    react: {
      // wait: true,
      useSuspense: false,
      wait: false,
    }
  })

export default i18n