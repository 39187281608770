import React from 'react'
import { useDispatch } from 'react-redux'
import { loginModalSetCurrentTab, loginModalSetHidden } from '../../actions'
import { callApiRentalBuy } from '../../api'
import { alertErrorByCode, hideLoading, showLoading } from '../../helper/Helper'
import { isUserLoggedIn } from '../../helper/ReduxStoreHelper'
import { useTranslation } from 'react-i18next'

const RentButton = ({ productId, price, className='' }) => {

    const { t , i18n} = useTranslation('trans');
    const dispatch = useDispatch()

    const handleOnClick = () => {
       
        if( isUserLoggedIn() ){

            showLoading()
            callApiRentalBuy(productId, price).then( (response) => {
                if( response.result.response && response.result.response.webPaymentUrl ){
                    window.location.href = response.result.response.webPaymentUrl
                }else{
                    alertErrorByCode(500)
                }
                hideLoading()
            })

        }else{
            dispatch(loginModalSetCurrentTab(''))
            dispatch(loginModalSetHidden(false))
        }
    }

    return (
        <button className={'video-page-rent-button ' + className} onClick={handleOnClick}>{t('Rent')} {price} Baht</button>
    )
}

export default RentButton
