import React, { useEffect, useState } from 'react'
import {  getAgreementTextContent, getProfile, receiptValidator, updateUserBirthDate } from '../../api'
import AccountModalHeader from './AccountModalHeader'
import { useDispatch, useSelector } from 'react-redux'
import { accountModalSetCurrentTab, userSetProfile } from '../../actions'
import { useTranslation } from 'react-i18next'
import { alertErrorByCode, convertUnderscoreObjectKeyToCamelCaseObjectKey, getAge, hideLoading, showLoading } from '../../helper/Helper'
import { Container, Modal, Row } from 'react-bootstrap'
import { Checkbox } from '@material-ui/core'
import { withStyles } from "@material-ui/core/styles";
import ModalCloseButton from '../../components/global/ModalCloseButton'
import TermsOfUsePage from '../../components/termsofuse/TermsOfUsePage'
import PrivacyPolicyPage from '../../components/privacypolicy/PrivacyPolicyPage'

const DateOfBirth = (userBirthDay) => {

    const [showCheckboxAgreeModal, setShowCheckboxAgreeModal] = useState(false)
    const [showContinueButton , setShowContinueButton] = useState(false);
    const [ageChecked, setAgeChecked] = React.useState(false);
    const [checked, setChecked] = React.useState(true);

    const dispatch = useDispatch()

    const { t , i18n} = useTranslation('trans');

    const [dateOfBirth, setDateOfBirth] = useState("")
    const [monthOfBirth, setMonthOfBirth] = useState("")
    const [yearOfBirth, setYearOfBirth] = useState("")
    const [userAge, serUserAge] = useState("")
    const [textAgreementCheckbox1, setTextAgreementCheckbox1] = useState("");
    const [textAgreementCheckbox2, setTextAgreementCheckbox2] = useState("");
    const [textAgreementContinueButton, setTextAgreementContinueButton] = useState("");


    useEffect(() => {
        const dateSplit = userProfile.dateOfBirth.split('-');
        
        setYearOfBirth(dateSplit[0]);
        setMonthOfBirth(dateSplit[1]);
        setDateOfBirth(dateSplit[2]);
    }, [userBirthDay])



    const handleSignupButtonClick = () => {
       
        const monthInputValue = document.getElementById('month').value
        const dateInputValue = document.getElementById('date').value
        const yearInputValue = document.getElementById('year').value

        const resultMonth = monthInputValue.replace(/\D/g, '');
        const resultDate = dateInputValue.replace(/\D/g, '');
        const resultYear = yearInputValue.replace(/\D/g, '');

        const dateSplit = userProfile.dateOfBirth.split('-');

            if(resultYear === '' || resultMonth === '' || resultDate === '') {
                alert(t('DateOfBirthNotValid'))
            } else {
                var birthday = resultYear + '-' + resultMonth + '-' + resultDate; 
                const moment = require('moment')
                var isValidDate = moment(birthday, "YYYY-MM-DD").isValid();
                if(isValidDate === true) {
                    var age = getAge(birthday);
                    serUserAge(age);
                    if(parseInt(age) >= 18 && parseInt(age) <= 150) {
                        var birthday = yearOfBirth + '-' + monthOfBirth + '-' + dateOfBirth;
                        showLoading();
                        updateUserBirthDate(birthday); 
                        getProfile().then( (res) => {
                            handleGetProfileResponse(res)
                        })
                        dispatch(accountModalSetCurrentTab(''));
                        hideLoading();
                    }else if (parseInt(age) >= 13 && parseInt(age) < 18) {
                        showLoading();
                        getAgreementTextContent().then( (res) => {
                            setTextAgreementCheckbox1(res.result.content_agreement_check);
                            setTextAgreementCheckbox2(res.result.content_agreement_check_under_18);
                            setTextAgreementContinueButton(res.result.content_agreement_continue);
                        })
                        setShowCheckboxAgreeModal(true);
                        hideLoading();
                    } else if(parseInt(age) < 13 && parseInt(age) >= 0) {
                        alert(t('ServiceNotavailableUnder13'))
                    }else {
                        alert(t('DateOfBirthNotValid'))
                    }
                } else {
                    alert(t('DateOfBirthNotValid'))
                }
            }

        

    }

    const handleGetProfileResponse = ( response ) => {
        let profileResult = convertUnderscoreObjectKeyToCamelCaseObjectKey(response.result);
        dispatch(userSetProfile(profileResult));
    }



    const userIsPremium = useSelector(state => state.user.isPremium)

    const userProfile = useSelector(state => state.user.profile)

    useEffect( () => {
        document.getElementById("buttonSave").disabled=true;
    }, [])



    const handleDateOfBirthChange = (event) => {
        const result = event.target.value.replace(/\D/g, '');
        setDateOfBirth(result);
        document.getElementById("buttonSave").disabled=false;
    }

    const handleMonthOfBirthChange = (event) => {
        const result = event.target.value.replace(/\D/g, '');
        setMonthOfBirth(result);
        document.getElementById("buttonSave").disabled=false
    }

    const handleYearOfBirthChange = (event) => {
        const result = event.target.value.replace(/\D/g, '');
        setYearOfBirth(result);
        document.getElementById("buttonSave").disabled=false
    }

    const handleCheckboxAgeChange = (event) => {
        setAgeChecked(event.target.checked);
    };

    const closeModalCheckboxAgree = () => {
        setShowCheckboxAgreeModal(false)
    }

    const CustomColorCheckbox = withStyles({
        root: {
          color: "#e30f1e",
          "&$checked": {
            color: "#e30f1e"
          }
        },
        checked: {}
    })((props) => <Checkbox color="default" {...props} />);

    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) { 
            setShowContinueButton(true)
        } 
        // else {
        //     setShowCheckbox(false)
        // }
     } //end check box agree

     const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const clickButtonContinueModalCheckbox = () => {
        var birthday = yearOfBirth + '-' + monthOfBirth + '-' + dateOfBirth; 

        showLoading();
            updateUserBirthDate(birthday); 

            getProfile().then( (res) => {
                handleGetProfileResponse(res)
            })

            dispatch(accountModalSetCurrentTab(''));
            hideLoading();
    }

    return (
        <div>
            <AccountModalHeader title={t('DateOfBirth')} haveBackButton={true} backButtonFunction={() => dispatch(accountModalSetCurrentTab(''))}/>
            <div className='login-modal-form-body text-center'>
                <div className="form-group mb-2">
                    <div className='date-of-birth'>
                        {/* <Row style={{justifyContent: 'center'}}>
                        <label style={{fontSize: '14px', fontWeight: 'bold'}}>{t('YourBirthday')}</label>
                        </Row> */}
                        <Row style={{justifyContent: 'center'}}>
                            <label style={{width: '65px',padding: '0px', marginTop: '5px', marginLeft: '5px', marginRight: '5px',  textAlign: 'left', fontSize: '11px'}}>{t('Month')}*</label>
                            <label style={{width: '65px',padding: '0px', marginTop: '5px', marginLeft: '5px', marginRight: '5px',  textAlign: 'left', fontSize: '11px'}}>{t('Day')}*</label>
                            <label style={{width: '65px',padding: '0px', marginTop: '5px', marginLeft: '5px', marginRight: '5px',  textAlign: 'left', fontSize: '11px'}}>{t('Year')}*</label>
                        </Row>
                        <Row style={{justifyContent: 'center', marginBottom: '12px'}}>
                            <input className='form-control col-md-1' type='text' maxlength='2' value={monthOfBirth}  placeholder='MM' style={{width: '65px', margin: '5px', textAlign: 'center'}} id='month' onChange={handleMonthOfBirthChange} required/>
                            <input className='form-control col-md-1' type='text' maxlength='2' value={dateOfBirth}   placeholder='DD' style={{width: '65px', margin: '5px', textAlign: 'center'}} id='date' onChange={handleDateOfBirthChange} required/>
                            <input className='form-control col-md-1' type='text' maxlength='4' value={yearOfBirth}   placeholder='YYYY' style={{width: '65px', margin: '5px', textAlign: 'center' }} id='year' onChange={handleYearOfBirthChange} required/>
                        </Row>
                    </div>
                    <button className='btn login-modal-form-button' id='buttonSave' style={{maxWidth: '340px'}} onClick={handleSignupButtonClick}>{t('Save')}</button>
                </div>
            </div>

        <Modal
            show={showCheckboxAgreeModal}
            onHide={closeModalCheckboxAgree}
            dialogClassName='modal-dialog-centered account-modal-container'
            style={{ overflowY:"scroll", marginTop: "0px", marginBottom: "0px", paddingBottom: '20px'}}>
            <Modal.Body style={{height: 'auto', padding: '0px'}}>
            

                <div className='' onScroll={handleScroll} style={{backgroundColor: "RGB(25,25,25)", height:"850px",overflowY:"scroll", borderRadius: '12px' }}>
                    <ModalCloseButton onClick={closeModalCheckboxAgree}/>
                    <Container className='text-left' style={{maxWidth: "100%"}}>
                        
                        
                        <div style={{paddingBottom: '80px'}}>
                        

                            <div className='col-md-12'>
                                <TermsOfUsePage/>
                            </div>
                            <div className='col-md-12'>
                                <PrivacyPolicyPage/> 
                            </div>

                                         {/* { (showContinueButton === true)  ? */}
                                <div>
                                    { (parseInt(userAge) >= 13 && parseInt(userAge) < 18) ?
                                    <label style={{marginLeft: "30px"}}>
                                        <CustomColorCheckbox disableRipple checked={ageChecked} onChange={handleCheckboxAgeChange} style={{ marginLeft: "0px" , marginBottom: "3px"}}/>
                                        <span style={{ marginLeft: 6 }}>{textAgreementCheckbox2}</span>
                                    </label> : '' 
                                    }
                                    <label style={{marginLeft: "30px"}}>
                                        <CustomColorCheckbox disableRipple checked={checked} onChange={handleChange} style={{ marginLeft: "0px" , marginBottom: "3px"}}/>
                                        <span style={{ marginLeft: 6 }}>{textAgreementCheckbox1}</span>
                                    </label>
                                    <br/>
                                    <div className='col-md-12' style={{textAlign: "center", marginTop: "15px", marginBottom: "30px"}}>
                                    { 
                                    (parseInt(userAge) >= 18) ?
                                        (checked === true) ? 
                                        <button className='btn login-modal-form-button text-center' style={{width: "50%", backgroundColor: "#e30f1e", color: "black"}} onClick={clickButtonContinueModalCheckbox}> {textAgreementContinueButton} </button> 
                                        : 
                                        <button className='btn login-modal-form-button text-center' style={{width: "50%", backgroundColor: "RGB(34,34,37)", color: "RGB(82,82,86)"}} > {textAgreementContinueButton} </button> 
                                    : 
                                        (checked === true && ageChecked === true) ? 
                                        <button className='btn login-modal-form-button text-center' style={{width: "50%", backgroundColor: "#e30f1e", color: "black"}} onClick={clickButtonContinueModalCheckbox}> {textAgreementContinueButton} </button> 
                                        : 
                                        <button className='btn login-modal-form-button text-center' style={{width: "50%", backgroundColor: "RGB(34,34,37)", color: "RGB(82,82,86)"}} > {textAgreementContinueButton} </button> 
                                    }
                                    </div> 
                                </div>
                            {/* : ''
                            } */}
                            
                    
                        </div>

            
                    </Container>

       

                </div>

                

            </Modal.Body>

        </Modal>
            
            
        </div>
    )
}

export default DateOfBirth
