import { combineReducers } from 'redux'
import media from './media.reducer'
import chromecast from './chromecast.reducer'
import home from './home.reducer'
import user from './user.reducer'
import loginModal from './loginModal.reducer'
import accountModal from './accountModal.reducer'
import premiumModal from './premiumModal.reducer'
import site from './site.reducer'
import redeem from './redeem.reducer'

export default combineReducers({
    media,
    chromecast,
    home,
    user,
    loginModal,
    accountModal,
    premiumModal,
    site,
    redeem
})
