import React, { useEffect, useState } from 'react'
import { Redirect, useParams } from 'react-router-dom';
import { getPrivacyPolicyContentPath } from '../../api';

const PrivacyPolicyPage = () => {

    const [dataHtml, setDataHtml] = useState();
    const {language} = useParams();

    useEffect(()=>{
        if(language == null || language === 'en' || language === 'es') {
            document.body.style.paddingTop = '0px';
            getPrivacyPolicyContentPath().then( (res) => {
                handlePrivacyPolicyContent(res)
            })
        } else {
            window.location.replace("/pageNotFound");
        }
    }, [])

    const handlePrivacyPolicyContent = ( response ) => {
      setDataHtml(response.result.content_file_path);
    }

    return (
        <div className='privacy-content'>
            <div dangerouslySetInnerHTML={{__html: dataHtml}} />
            <br/>
        </div>
        
    )
}

export default PrivacyPolicyPage
