import React , { useState } from 'react'
import { useDispatch } from 'react-redux'
import { loginModalSetCurrentTab } from '../../actions'
import { forgetPassword } from '../../api'
import ForgetPasswordIcon from '../../images/login-modal/forget-password-icon.png'
import { hideLoading, showLoading, validateEmail } from '../../helper/Helper'
import {Suspense} from 'react';
import { useTranslation } from 'react-i18next'

const ForgetPasswordForm = ({show}) => {

    const { t , i18n} = useTranslation('trans');

    const dispatch = useDispatch()

    const [email, setEmail] = useState("")

    const handleEmailChange = (event) => {
        setEmail(event.target.value)
    }

    const handleOnClick = () => {
        if( !validateEmail(email) ) {
            alert(t('EmailAddressIsNotValid'))
        }else{
            showLoading()
            forgetPassword(email).then(handleForgetPasswordResponse)
        }
    }

    const handleForgetPasswordResponse = async (response) => {
        hideLoading()
        alert("Title: " + response.message.title + "\nMessage: " + response.message.message + "\n" )
    }

        
    return (
        <div className='login-modal-form forget-password-form' hidden={!show}>
            <div className='login-modal-form-header'>
                <span className='login-modal-form-back-button' onClick={() => dispatch(loginModalSetCurrentTab(''))}>{t('Back')}</span>
                <h3 className='text-center'>{t('ForgetPassword')}</h3>
            </div>
            <div className='login-modal-form-body text-center'>
                <div className='mb-3'>
                    <img className='forget-password-form-icon' src={ForgetPasswordIcon} alt='' />
                </div>

                <div>
                    <h3>Trouble with logging in?</h3>
                </div>
                <div className='forget-password-form-information login-modal-text-secondary-light-color'>
                    <p>{t('EnterYourEmailAddressSendLink')}</p>
                </div>

                <div className="form-group">
                    <input type="text" className="form-control" placeholder={t('Email')} onChange={handleEmailChange}/>
                    <button className='btn login-modal-form-button mb-2' onClick={handleOnClick}>{t('SendLink')}</button>    
                </div>
            </div>
        </div>
    )
}

export default ForgetPasswordForm
