import React, { useEffect, useState } from 'react'
import MediaLink from '../../containers/global/MediaLink'
import DummyImage from '../../images/slideshow/slideshow-mobile-sample.png'

const MediaContent = ({ mediaId, thumbnailUrl, name }) => {

    return (
        <MediaLink mediaId={mediaId} type={"mediaContent"}>
            <div className='media-content cursor-pointer'>
                <div>
                {/* 226px x 128px */}
                { (thumbnailUrl == null || thumbnailUrl == '') ?
                    <img src={DummyImage} alt='' loading="lazy"/> 
                :
                    <img src={thumbnailUrl} alt='' loading="lazy"/> 
                }
                </div>
                <div className='media-content-name'>
                    {name}
                </div>
            </div>
        </MediaLink>
    )
}

export default MediaContent;
  
