const initialState = {
    id: null, 
    episode: "01",
    startTime: 0,
    thumbnailUrl: "",
    episodeList: [],
    currentTime: 0,
    currentTextLanguage: null,
    currentAudioLanguage: null,
    details: {},
    canView: 0,
    isRentalType: 0,
    isRented: 0,
    isCurrentEpisodePremium: false,
}

const media = (state = initialState, action) => {
    switch (action.type) {
        case 'SELECT_MEDIA':
            return { ...state, id: action.id, episode: "01" }
        case 'SELECT_EPISODE':
            return { ...state, episode: action.episode }
        case 'MEDIA_SET_THUMBNAIL_URL':
            return { ...state, thumbnailUrl: action.thumbnailUrl }
        case 'MEDIA_SET_EPISODE_LIST':
            return { ...state, episodeList: action.episodeList }
        case 'MEDIA_SET_CURRENT_TIME':
            return { ...state, currentTime: action.currentTime }
        case 'MEDIA_SET_CURRENT_TEXT_LANGUAGE':
            return { ...state, currentTextLanguage: action.language }
        case 'MEDIA_SET_CURRENT_AUDIO_LANGUAGE':
            return { ...state, currentAudioLanguage: action.language }
        case 'MEDIA_SET_START_TIME':
            return { ...state, startTime: action.startTime }
        case 'MEDIA_SET_DETAILS':
            return { ...state, details: action.details }
        case 'MEDIA_SET_CAN_VIEW':
            return { ...state, canView: action.canView }
        case 'MEDIA_SET_IS_RENTAL_TYPE':
            return { ...state, isRentalType: action.isRentalType }
        case 'MEDIA_SET_IS_RENTED':
                return { ...state, isRented: action.isRented }
        case 'MEDIA_SET_IS_CURRENT_EPISODE_PREMIUM':
            return { ...state, isCurrentEpisodePremium: action.isCurrentEpisodePremium }
        case 'MEDIA_RESET':
            return initialState
        default:
            return state
    }
}
  
export default media
