import React from 'react'
import RentalContent from './RentalContent'

const RentalContentList = ({list}) => {

    return (
        <div className='rental-content-list'>
            {
                list && list.map( (details, index) => {
                    return <React.Fragment key={index}><RentalContent details={details}/><hr/></React.Fragment>
                })
            }
        </div>
    )
}

export default RentalContentList
