import { consoleLogHelper } from "./Helper";

export function CastPlayerController(){
    this.remotePlayer           = new window.cast.framework.RemotePlayer();
    this.remotePlayerController = new window.cast.framework.RemotePlayerController(this.remotePlayer);
}

CastPlayerController.prototype.isConnected = function() { return this.remotePlayer.isConnected; }
CastPlayerController.prototype.getPlayerState = function() { return this.remotePlayer.playerState; }
CastPlayerController.prototype.getMediaVolume = function() { return this.remotePlayer.volumeLevel; }
CastPlayerController.prototype.getMediaDuration = function() { return this.remotePlayer.duration; }
CastPlayerController.prototype.getCurrentMediaTime = function() { return this.remotePlayer.currentTime; }

CastPlayerController.prototype.play = function() { 
    if (this.remotePlayer.isPaused) {
        this.remotePlayerController.playOrPause();
    }
}

CastPlayerController.prototype.pause = function() { 
    if (!this.remotePlayer.isPaused) {
        this.remotePlayerController.playOrPause();
    }
}

CastPlayerController.prototype.isPaused = function() {
    return this.remotePlayer.isPaused;
}


CastPlayerController.prototype.seekTo = function(time) { 
    this.remotePlayer.currentTime = time;
    this.remotePlayerController.seek();
}


CastPlayerController.prototype.setVolume = function(volume) { 
    this.remotePlayer.volumeLevel = volume;
    this.remotePlayerController.setVolumeLevel();
}



CastPlayerController.prototype.mute = function() { 
    if (!this.remotePlayer.isMuted) {
        this.remotePlayerController.muteOrUnmute();
    }
}

CastPlayerController.prototype.unMute = function() { 
    if (this.remotePlayer.isMuted) {
        this.remotePlayerController.muteOrUnmute();
    }
}

CastPlayerController.prototype.isMuted = function() {
    return this.remotePlayer.isMuted;
}



CastPlayerController.prototype.seekRequest = function(currentTime) { 

    const castSession = window.cast.framework.CastContext.getInstance().getCurrentSession();
    const media = castSession.getMediaSession();

    var seekRequest = new window.chrome.cast.media.SeekRequest()
    seekRequest.currentTime = currentTime;

    media.seek(seekRequest, 
        ()=>{consoleLogHelper("Seek Success")},
        ()=>{consoleLogHelper("Seek Failed")},
    )

}


CastPlayerController.prototype.queueNext = function( resolve, reject ) { 

    const castSession = window.cast.framework.CastContext.getInstance().getCurrentSession();
    const media = castSession.getMediaSession();
    if( media && media.items ){
        media.queueNext(
            () => {
                resolve(media);
                consoleLogHelper('success changing queue next! current', media.currentItemId)
            }, 
            function errorCallback(){ 
                consoleLogHelper('Error CB!')}
            );
    }

}

CastPlayerController.prototype.getMediaCustomData = function() { 

    const castSession = window.cast.framework.CastContext.getInstance().getCurrentSession();
    const media = castSession.getMediaSession();
    if( media ){
        return media.media.customData
    }else{
        return null
    }

}



CastPlayerController.prototype.isLastQueueItemForNextButton = function(media) { 
consoleLogHelper('isLastQueueItemForNextButton',media);
consoleLogHelper('isLastQueueItemForNextButton itemid', media.items[media.items.length - 2].itemId);
consoleLogHelper('isLastQueueItemForNextButton currentItemId', media.currentItemId);
    if( media.items[media.items.length - 2].itemId === media.currentItemId ){
        return true;
    }else{
        return false;
    }

}



CastPlayerController.prototype.queueBack = function( resolve, reject ) { 

    const castSession = window.cast.framework.CastContext.getInstance().getCurrentSession();
    const media = castSession.getMediaSession();
    if( media && media.items ){
        media.queuePrev(
            () => {
                resolve(media);
            }, 
            function errorCallback(){ 
                consoleLogHelper('Error CB!')}
            );
    }

}



CastPlayerController.prototype.isFirstQueueItemForBackButton = function(media) { 
consoleLogHelper('isFirstQueueItemForBackButton',media);
consoleLogHelper('isFirstQueueItemForBackButton itemid', media.items[1].itemId);
consoleLogHelper('isFirstQueueItemForBackButton currentItemId', media.currentItemId);
    if( media.items[1].itemId === media.currentItemId ){
        return true;
    }else{
        return false;
    }

}


CastPlayerController.prototype.isFirstQueueItem = function() { 

    const castSession = window.cast.framework.CastContext.getInstance().getCurrentSession();
    const media = castSession.getMediaSession();

    if( media.items[0].itemId === media.currentItemId ){
        return true;
    }else{
        return false;
    }

}


CastPlayerController.prototype.isLastQueueItem = function() { 

    const castSession = window.cast.framework.CastContext.getInstance().getCurrentSession();
    const media = castSession.getMediaSession();

    if( media.items[media.items.length - 1].itemId === media.currentItemId ){
        return true;
    }else{
        return false;
    }

}


CastPlayerController.prototype.countQueueItems = function() { 

    const castSession = window.cast.framework.CastContext.getInstance().getCurrentSession();
    const media = castSession.getMediaSession();

    return media.items.length;

}



CastPlayerController.prototype.setAudioLanguageFromLanguageString = function(language){

    return new Promise( (resolve, reject) => {
       
        if( language ){

            const castSession = window.cast.framework.CastContext.getInstance().getCurrentSession();
            const media = castSession.getMediaSession();
      
            var tracks = media.media.tracks;
      
            var filteredTracks = tracks.filter( (track) => {
               return track.type === "AUDIO" && track.language === language;
            })
 
            consoleLogHelper('setAudioLanguage',filteredTracks);
 
            if( filteredTracks && filteredTracks.length !== 0 ){
      
               var activeTrackIds = [filteredTracks[0].trackId];
               var tracksInfoRequest = new window.chrome.cast.media.EditTracksInfoRequest(activeTrackIds);
      
               media.editTracksInfo(
                    tracksInfoRequest, 
                    () => {                 
                        consoleLogHelper('setAudioLanguage Success')
                        resolve(true);
                    }, 
                    () => {
                        resolve(false);
                    }
                );
      
            }else{
                resolve(false);
            }
   
        }else{
            resolve(false);
        }

    });


}


CastPlayerController.prototype.setTextLanguageFromLanguageString = function setTextLanguage(language){

    return new Promise( (resolve, reject) => {

        if( language ){

            const castSession = window.cast.framework.CastContext.getInstance().getCurrentSession();
            const media = castSession.getMediaSession();
    
            var tracks = media.media.tracks;
    
            var filteredTracks = tracks.filter( (track) => {
                return track.type === "TEXT" && track.language === language;
            })

            consoleLogHelper('setTextLanguage',filteredTracks);
    
            if( filteredTracks && filteredTracks.length !== 0 ){
    
                var activeTrackIds = [filteredTracks[0].trackId];
                var tracksInfoRequest = new window.chrome.cast.media.EditTracksInfoRequest(activeTrackIds);
    
                media.editTracksInfo(
                    tracksInfoRequest, 
                    () => {                 
                        consoleLogHelper('setTextLanguage Success')
                        resolve(true);
                    }, 
                    () => {
                        resolve(false);
                    }
                );
        
            }else{
                resolve(false);
            }
   
        }else{
            resolve(false);
        }

    });

}


// ตอน Set Audio จะต้อง Set Text ด้วย ไม่งั้น Text จะหายไป
CastPlayerController.prototype.setAudioLanguageFromTrack = function(track, currentActiveTextTrack, resolve, reject){
    
    consoleLogHelper('handleAudioChange', track);

    var activeTrackIds = [track.trackId];

    consoleLogHelper('handleAudioChange activetracktext',currentActiveTextTrack.length);

    if( Object.keys(currentActiveTextTrack).length !== 0 ){
        activeTrackIds.push( currentActiveTextTrack.trackId );
    }

    var tracksInfoRequest = new window.chrome.cast.media.EditTracksInfoRequest(activeTrackIds);

    const castSession = window.cast.framework.CastContext.getInstance().getCurrentSession();
    const media = castSession.getMediaSession();
    media.editTracksInfo(tracksInfoRequest, 
        () => {
            consoleLogHelper('success changing trackIDs!');
            resolve();
        },
        (err) => {
            reject(err)
        }
    );

}



CastPlayerController.prototype.setTextLanguageFromTrack = function(track, resolve, reject){
    
    consoleLogHelper('handleSubtitleChange', track);

    var activeTrackIds = [];

    if( track ){
        activeTrackIds = [track.trackId];
    }

    var tracksInfoRequest = new window.chrome.cast.media.EditTracksInfoRequest(activeTrackIds);

    const castSession = window.cast.framework.CastContext.getInstance().getCurrentSession();
    const media = castSession.getMediaSession();
    media.editTracksInfo(tracksInfoRequest, 
        () => {
            consoleLogHelper('success changing trackIDs!');
            resolve();
        },
        () => {
            consoleLogHelper('Error CB!');
        }
    );

}


CastPlayerController.prototype.getMediaStatus = function(resolve, reject){
    
    const castSession = window.cast.framework.CastContext.getInstance().getCurrentSession();
    const media = castSession.getMediaSession();
    if( media ){
        media.getStatus( undefined,
            (e)=> {
                resolve();
            }
        );
    }

}



CastPlayerController.prototype.getMediaTitle = function(){
    
    const castSession = window.cast.framework.CastContext.getInstance().getCurrentSession();
    const media = castSession.getMediaSession();

    if( media && media.media && media.media.metadata ){
        return media.media.metadata.title;
    }else{
        return '';
    }
}





CastPlayerController.prototype.getCastDeviceName = function(){
    
    const castSession = window.cast.framework.CastContext.getInstance().getCurrentSession();
    const castDevice = castSession.getCastDevice();

    if( castDevice.friendlyName ){
        return castDevice.friendlyName;
    }else{
        return '';
    }
}



CastPlayerController.prototype.getAllAvailableAudioAndTextTracks = function() {

    const castSession = window.cast.framework.CastContext.getInstance().getCurrentSession();
    const media = castSession.getMediaSession();

    var availableLanguageTracks = [];
    var availableAudioTracks    = [];

    if( media ){

        media.media.tracks.forEach(( track ) => {

            if( track.type === "TEXT" ){
                availableLanguageTracks.push(track); 
            }

            if( track.type === "AUDIO" ){
                availableAudioTracks.push(track); 
            }

        });

    }

    return { audio: availableAudioTracks, text: availableLanguageTracks };

}


CastPlayerController.prototype.getActiveAudioAndTextTrack = function() {

    const castSession = window.cast.framework.CastContext.getInstance().getCurrentSession();
    const media = castSession.getMediaSession();

    var activeTextTrack  = {};
    var activeAudioTrack = {};

    if( media ){
        
        media.media.tracks.forEach(( track ) => {

            if( track.type === "TEXT" ){
                if( media.activeTrackIds.indexOf(track.trackId) !== -1 ){
                    activeTextTrack = track;
                }
            }

            if( track.type === "AUDIO" ){
                if( media.activeTrackIds.indexOf(track.trackId) !== -1 ){
                    activeAudioTrack = track;
                }
            }

        });

    }

    return { audio: activeAudioTrack, text: activeTextTrack };

}




CastPlayerController.prototype.getActiveTrackIds = function() {

    const castSession = window.cast.framework.CastContext.getInstance().getCurrentSession();
    const media = castSession.getMediaSession();

    var activeTrackIds = [];

    if( media ){
        activeTrackIds = media.activeTrackIds;
    }

    return activeTrackIds;

}


CastPlayerController.prototype.isMediaSessionAvailable = function (){

    const castSession = window.cast.framework.CastContext.getInstance().getCurrentSession();
    const media = castSession.getMediaSession();

    if( media ){
        return true;
    }else{
        return false;
    }

}




CastPlayerController.prototype.loadQueueMedia = function (source, startIndex, resolve, reject ){

    var castSession   = window.cast.framework.CastContext.getInstance().getCurrentSession();

    var queueItems = [];
    

    source.forEach( ( src ) => {

        var mediaInfo = new window.chrome.cast.media.MediaInfo(
            src.src
            ,src.type
        );

        mediaInfo.customData = {
            mediaId: src.mediaId,
            episode: src.episode,
            licenseUrl : src.licenseUrl,
            titlePremium: src.titlePremium,
            episodePremium: src.episodePremium
        }
        
        mediaInfo.metadata = new window.chrome.cast.media.GenericMediaMetadata();
        mediaInfo.metadata.metadataType = window.chrome.cast.media.MetadataType.MOVIE;

        var coverImage = new window.chrome.cast.Image(src.coverImage);

        mediaInfo.metadata.images = [coverImage];
        mediaInfo.metadata.title = src.episodeName;
        if (src.name !== src.episodeName) {
            mediaInfo.metadata.title = src.episodeName;
            mediaInfo.metadata.subtitle = src.name;
        } else {
            mediaInfo.metadata.title = src.name;
            mediaInfo.metadata.subtitle = '';
        }
        
        
        queueItems.push(new window.chrome.cast.media.QueueItem(mediaInfo));
        
    });


    var queueLoadRequest = new window.chrome.cast.media.QueueLoadRequest(queueItems);

    queueLoadRequest.startIndex = startIndex;

    consoleLogHelper('queueLoadRequest',queueLoadRequest);

    let sessionObj = castSession.getSessionObj();

    sessionObj.queueLoad(queueLoadRequest, 
        (success)=>{ 
            consoleLogHelper("success", success); 
            resolve(); 
        }, 
        function(error){ 
            consoleLogHelper("Queue Added Error",error); 
        });


}
