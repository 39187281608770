import React from 'react'
import {BsChevronRight} from 'react-icons/bs'
import { useHistory } from 'react-router'
import { getDayLeftString, goToVideo } from '../../helper/Helper'
import { useDispatch } from 'react-redux'
import { accountModalSetShow } from '../../actions'

const RentalContent = ({ details }) => {

    const history = useHistory()
    const dispatch = useDispatch()

    const handleOnClick = () =>{ 
        goToVideo(history, details.id)
        dispatch(accountModalSetShow(false))
    }

    const processDayLeft = () => {

        let dayLeftInString = "-"

        if( details.watch_start !== "" && details.watch_end !== "" ){
            dayLeftInString = getDayLeftString(details.watch_end)
        }else if( details.expire_datetime !== "" ){
            dayLeftInString = getDayLeftString(details.expire_datetime)
        }

        return dayLeftInString
    }

    return (
        <div className='rental-content cursor-pointer' onClick={handleOnClick}>
            <div className='rental-content-image-container'>
                <img src={details.thumbnail_url} alt='' />
            </div>
            <div className='rental-content-information-container'>
                <div className='rental-content-information-title'>
                    {details.name}
                </div>
                <div className='rental-content-information-expire'>
                    {processDayLeft()}
                </div>
                <BsChevronRight className='rental-content-chevron-icon'/>
            </div>
        </div>
    )
}

export default RentalContent
