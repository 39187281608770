import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { loginModalSetCurrentTab, loginModalSetHidden } from '../../actions';
import LoginForm from './LoginForm';
import SignUpForm from './SignUpForm';
import {Modal, Container, Row, Col} from 'react-bootstrap'
import FlixerLogo from '../../images/premium-modal/flixer-logo.png'
import FacebookLoginButton from '../../components/login/FacebookLoginButton';
import AppleLoginButton from '../../components/login/AppleLoginButton';
import SplitLine from '../../components/login/SplitLine';
import Info1Icon from '../../images/login-modal/info1-icon.png'
import Info2Icon from '../../images/login-modal/info2-icon.png'
import Info3Icon from '../../images/login-modal/info3-icon.png'
import ForgetPasswordForm from './ForgetPasswordForm';
import TermAndPrivacy from '../../components/login/TermAndPrivacy';
import { firebaseLogEvent } from '../../helper/Helper';
import ModalCloseButton from '../../components/global/ModalCloseButton'
import { useTranslation } from 'react-i18next'
import GoogleLoginButton from '../../components/login/GoogleLoginButton';
import SignInNavbarButton from '../navbar/SignInNavbarButton';
import SignUpWithEmailButton from './SignUpWithEmailButton';
import SignInWithEmailButton from './SignInWithEmailButton';
import { GoogleOAuthProvider } from '@react-oauth/google';

const LoginModal = ({loginModal, dispatch}) => {

    const { t , i18n} = useTranslation('trans');

    const closeModal = () => {
        dispatch(loginModalSetHidden(true))
    }

    useEffect(() => {
        if( !loginModal.hidden && loginModal.currentTab === '' ){
            firebaseLogEvent('page_view',{
                page_title: 'LoginFirstPage Modal',
                page_location: window.location.origin + '/modal/login-first-page',
                page_path: '/modal/login-first-page'
            })
        }else if( loginModal.currentTab === 'signin' ){
            firebaseLogEvent('page_view',{
                page_title: 'Login Modal',
                page_location: window.location.origin + '/modal/login',
                page_path: '/modal/login'
            })
        }else if(loginModal.currentTab === 'signup' ){
            firebaseLogEvent('page_view',{
                page_title: 'Register Modal',
                page_location: window.location.origin + '/modal/register',
                page_path: '/modal/register'
            })
        }else if(loginModal.currentTab === 'forget-password' ){
            firebaseLogEvent('page_view',{
                page_title: 'ForgetPassword Modal',
                page_location: window.location.origin + '/modal/forget-password',
                page_path: '/modal/forget-password'
            })
        }
    }, [loginModal.currentTab, loginModal.hidden])

    return (
        <Modal
            show={!loginModal.hidden}
            onHide={closeModal}
            dialogClassName="login-modal-container modal-dialog-centered"
            data-current-tab={loginModal.currentTab}
        >
            <Modal.Body>
                <ModalCloseButton onClick={closeModal}/>
                <Container className='text-center login-modal-first-page' hidden={loginModal.currentTab !== ''}>
                    <Row className='login-modal-flixer-logo'>
                        <Col>
                            <img width='280px' src={FlixerLogo} alt=''/>
                        </Col>
                    </Row>

                    {/* <Row className='login-modal-notes'>
                        <Col>
                            {t('OnlySupportedDevices')}
                        </Col>
                        <div className='w-100'></div>
                        <Col className='mb-4'>
                            {t('OnlySupportedDevicesSecondLine')}
                        </Col>
                    </Row> */}


                    <Row style={{display: "block"}}>
                        <Col className='mb-2'>
                            <FacebookLoginButton />
                        </Col>
                        <div className='w-100'></div>
                        <Col className='mb-2'>
                            <AppleLoginButton />
                        </Col>
                        <div className='w-100'></div>
                        <Col className='mb-2'>
                            <GoogleOAuthProvider clientId={'813172702758-ahvehtsnshf7221kku47d5devtono9ad.apps.googleusercontent.com'}> 
                                <GoogleLoginButton />
                            </GoogleOAuthProvider>
                        </Col>
                        <div className='w-100'></div>
                        <Col className='mb-2' onClick={() => dispatch(loginModalSetCurrentTab('signin'))}>
                            <SignInWithEmailButton />
                        </Col>
                    </Row>
                    
                    <SplitLine />

                    <Row className='mb-3 font-size-90' style={{display: "block"}}>
                        <Col className='text-center' onClick={() => dispatch(loginModalSetCurrentTab('signup'))}>

                            <SignUpWithEmailButton />

                        </Col>
                    </Row>
                    <Row className='font-size-90'>
                        <Col className='mb-3'>
                            {/* <span className='me-2 login-modal-text-secondary-color'>{t('HaveAnAccount')}</span>
                            <span className='login-modal-link' onClick={() => dispatch(loginModalSetCurrentTab('signin'))}>{t('SignIn')}</span>
                            <span> | </span> */}
                            {/* <span className='login-modal-link' onClick={closeModal}>{t('NotNow')}</span> */}
                        </Col>
                    </Row>

                    <TermAndPrivacy />
                </Container>

                
                <LoginForm  show={loginModal.currentTab === 'signin'}/>
                <SignUpForm show={loginModal.currentTab === 'signup'}/>
                <ForgetPasswordForm show={loginModal.currentTab === 'forget-password'}/>
            </Modal.Body>
        </Modal>
    )
}


const mapStateToProps = (state, ownProps) => ({
    loginModal: state.loginModal
})
  
const mapDispatchToProps = (dispatch, ownProps) => ({
    dispatch
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginModal)
