import React, { useState, useEffect } from 'react'
import LikeIconOff from '../../images/video/like-off.png'
import LikeIconOn from '../../images/video/like-on.png'

const LikeButton = ({ likeCount, likeStatus, handleOnClick }) => {

    const [ likeStatusState, setLikeStatusState ] = useState()

    useEffect(() => {
        setLikeStatusState(likeStatus)
    }, [likeStatus])

    const handleLikeDisLikeOnClick = (status) => {
        setLikeStatusState(status)
        handleOnClick(status)
    }

    return (

                <div className=''>
                    {
                            likeStatusState === 1 || likeStatusState === '1'
                        ?
                            <div className='box-like-button'>
                                <img className='like-button-image'  src={LikeIconOn} alt='' onClick={() => handleLikeDisLikeOnClick(0)} /> 
                                <label className='text-like-button'>{(likeCount === 0 ? " - " : likeCount)}</label>
                            </div>
                        :
                            <div className='box-like-button'>
                                <img className='like-button-image' src={LikeIconOff}  alt='' onClick={() => handleLikeDisLikeOnClick(1)} />
                                <label className='text-like-button'>{(likeCount === 0 ? " - " : likeCount)}</label>
                            </div>
                    }
                </div>



        

        // <div className='row'>
        //         <div className='col text-center'>
        //             {likeCount}
        //         </div>
        //     </div>

    )
}

export default LikeButton