import React from 'react'

const PremiumButton = ({ firstLine, secondLine, onClick = () => {} }) => {
    return (
        <button className='premium-modal-premium-button' onClick={onClick}>
            <span className='first-line-text'>{firstLine}</span><br/>
            <span className='second-line-text'>{secondLine}</span>
        </button>
    )
}

export default PremiumButton
