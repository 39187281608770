const initialState = {
    profile: {
        email: "",
        fbid: "",
        name: "",
        emailVerify: "",
        last_login: "",
        profileImageUrl: "",
        premium: "",
        premiumExpire: "",
        premiumPlatform: "",
        userValid: false
    },
    userLevel: "",
    userType: "",
    userDpi: null,
    userToken: "",
    premiumScreens: 0,
    isPremium: false,
    tokenPrimary: 0,
    premiumExpire: "",
    premiumPlatform: "",
    userValid: false,
    action: "",
    userCountry: "",
    rental: true,
    canView: 0
}

const user = (state = initialState, action) => {
    switch (action.type) {
        case 'USER_SET_PROFILE':
            return { ...state, profile: action.profile }
        case 'USER_SET_LEVEL':
            return { ...state, userLevel: action.userLevel }
        case 'USER_SET_TYPE':
            return { ...state, userType: action.userType }
        case 'USER_SET_IS_PREMIUM':
            return { ...state, isPremium: action.isPremium }
        case 'USER_SET_PREMIUM_EXPIRE':
            return { ...state, premiumExpire: action.premiumExpire }
        case 'USER_SET_PREMIUM_PLATFORM':
            return { ...state, premiumPlatform: action.premiumPlatform }
        case 'USER_SET_VALID':
            return { ...state, userValid: action.userValid }
        case 'USER_SET_CAN_VIEW':
            return { ...state, canView: action.canView }
        case 'USER_CLEAR':
            return initialState;
        default:
            return state
    }
}
  
export default user
