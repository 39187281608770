import React, { useState, useEffect } from "react"
import { connect } from 'react-redux'
import image from '../../images/cast/audio_selection.png'
import {chromecastSetMediaActiveAudioTrack} from '../../actions'


const MediaAudio = ({ tracks, activeTrack, activeTextTrack, setActiveTrack }) => {

    const [hideMenu, setHideMenu]             = useState(true);
    const [selectedIndex, setSelectedIndex]   = useState(null);

    useEffect(() => {
        if( activeTrack.trackId ){
            setSelectedIndex(activeTrack.trackId);
        }
    },[activeTrack]);


    const handleOnClick = (track) => {
        if( track ){
            setActiveTrack(track)
        }else{
            setActiveTrack(null)
        }

        // ตอนที่ Set Audio จะต้องส่ง Active Text ไปด้วยไม่งั้น Text จะโดน reset
        window.flixer.castPlayerController.setAudioLanguageFromTrack(track, activeTextTrack, () => {} );
    }
    
    return (

        <div className='chromecast-bar-media-audio menu-button-container'>
            <div className='menu-container' hidden={hideMenu || tracks.length === 0} onMouseOver={ () => setHideMenu(false)} onMouseOut={ () => setHideMenu(true)}>
                <ul>
                    { 
                        tracks.map( (track, index) => {     
                            return <li className={track.trackId === selectedIndex ? "active" : ""} onClick={ () => handleOnClick(track)} key={index}>{track.name}</li>;
                        })
                    }
                </ul>
            </div>
            <div className='menu-button-icon' onMouseOver={ () => setHideMenu(false)} onMouseOut={ () => setHideMenu(true)}>
                <img width='100%' src={image} alt="" />
            </div>
        </div>
    );

}

const mapStateToProps = (state, ownProps) => ({
    tracks: state.chromecast.media.availableTracks.audio,
    activeTrack: state.chromecast.media.activeTracks.audio,
    activeTextTrack: state.chromecast.media.activeTracks.text
})
  
const mapDispatchToProps = (dispatch, ownProps) => ({
    setActiveTrack: (track) => dispatch(chromecastSetMediaActiveAudioTrack(track))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MediaAudio)
