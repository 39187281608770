import React, { useEffect, useState } from 'react'
import i18n from '../../components/i18n';
import {Suspense} from 'react';
import { useTranslation } from 'react-i18next'


const SignInNavbarButton = () => {

    const { t , i18n} = useTranslation('trans');

    return (
        <Suspense fallback={<div>Loading...</div>}>
                <div className='btn ' id="dropdown" style={{color: "white", background: "black" , borderRadius:"25px", borderColor: "white", paddingLeft: "10px", paddingRight: "10px", paddingTop: "2px", paddingBottom: "2px", fontSize: "15px", width: "max-content"}}>{t('SignInAndRegister')}</div>
        </Suspense>
    )
}

export default SignInNavbarButton
