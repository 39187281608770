const initialState = {
    show: false,
    currentTab: ''     
}

const accountModal = (state = initialState, action) => {
    switch (action.type) {
        case 'ACCOUNT_MODAL_SET_SHOW':
            return { ...state, show: action.show}
        case 'ACCOUNT_MODAL_SET_CURRENT_TAB':
            return { ...state, currentTab: action.currentTab}
        default:
            return state
    }
}
  
export default accountModal