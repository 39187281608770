import React from 'react'
import { Col } from 'react-bootstrap'

const AccountModalHeader = ({ title, haveBackButton = false, backButtonFunction = () => {}  }) => {

    return (
        <Col className='account-modal-header pt-3 pb-3'>
            {
                    haveBackButton 
                ?
                    <span className='account-modal-back-button' onClick={backButtonFunction}>Back</span>
                :
                    ""
            }
            <h4 className='fw-bold text-center mb-0'>{title}</h4>
        </Col>
    )
}

export default AccountModalHeader
