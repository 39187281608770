import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import FlixerLogo from '../../images/service-unavailable/flixer-logo.png'

const ServiceUnavailable = () => {

    const message = useSelector(state => state.site.isAvailable.message)

    const { t , i18n} = useTranslation('trans');
    const [cookieCurrentLanguage , setCookieCurrentLanguage] = useState('');
    const [termOfUseLink , setTermOfUseLink] = useState('');
    const [privacyPolicyLink , setPrivacyPolicyLink] = useState('');
    const [messageShow , setMessageShow] = useState('');

    useEffect(() => {

        let cookieLanguage = document.cookie.replace(/(?:(?:^|.*;\s*)currentLanguage\s*\=\s*([^;]*).*$)|^.*$/, "$1")
        setCookieCurrentLanguage(cookieLanguage)

        if(cookieLanguage !== '') {
          i18n.changeLanguage(cookieLanguage)
        } else {
          i18n.changeLanguage("en")
          setCookieCurrentLanguage('en')
        }
        setTermOfUseLink('/' + cookieLanguage + '/term-of-use')
        setPrivacyPolicyLink('/' + cookieLanguage + '/privacy-policy')

        if (message !== t('ServerMaintenance') || message == null ||  message === '') {
            // message = t('UnavailableRegion');
            setMessageShow( t('UnavailableRegion'))
        } else {
            setMessageShow( t('ServerMaintenance'))
        }

    }, [])

    return (
        <div className="service-unavailable-page">
            <div className="service-unavailable-content">
                <img className='service-unavailable-logo' src={FlixerLogo} alt='' />
                <div className='service-unavailable-title'>
                    {t('ServiceUnavailable')}
                    
                </div>
                {/* <div style={{margin: '10px'}}><p>{t('UnavailableRegion')}</p></div> */}
                <div className='service-unavailable-information'>
                    {messageShow}
                </div>
                {/* <div className='service-unavailable-terms'>
                    <a className='service-unavailable-terms-terms-of-use' target='_blank' href={t('TermOfUseLink')}>{t('TermOfUse')}</a>
                    <a target='_blank' href={t('PrivacyPolicyLink')}>{t('PrivacyPolicy')}</a>
                </div> */}
            </div>
        </div>
    )
}

export default ServiceUnavailable