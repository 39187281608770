import React, { useEffect, useState } from 'react'
import { callApiRentalList, removeAccount } from '../../api'
import AccountModalHeader from './AccountModalHeader'
import RentalContentList from './RentalContentList'
import { useDispatch, useSelector } from 'react-redux'
import { accountModalSetCurrentTab } from '../../actions'
import NoRentalList from './NoRentalList'
import { useTranslation } from 'react-i18next'

const ManageAccount = () => {

    const dispatch = useDispatch()

    const { t , i18n} = useTranslation('trans');

    const [loaded, setLoaded] = useState(false)

    const userIsPremium = useSelector(state => state.user.isPremium)

    // const [rentalList, setRentalList] = useState([])

    useEffect( () => {
        // callApiRentalList().then(handleRentalListResponse)
    }, [])

    const handleRemoveAccountResponse = (response) => {
        window.location.reload()
        alert(t('DeleteAccountSuccess'));
    }

    const handleOnClickDeleteAccount = () => {
        if (userIsPremium) {
            alert(t('AlertMessageUnableDeleteAccount'));
        } else {
            let confirm = window.confirm(t('AlertMessage'));
            if (confirm) {
                removeAccount().then(handleRemoveAccountResponse)
            } else {
                
            }
        }
   
    }

    return (
        <div>
            <AccountModalHeader title={t('TextManageAccount')} haveBackButton={true} backButtonFunction={() => dispatch(accountModalSetCurrentTab(''))}/>
            <div className='account-modal-list' style={{color : "rgb(255,50,50)", marginTop: "30px",cursor: "pointer" }} onClick={() => handleOnClickDeleteAccount()}>{t('TextDeleteAccount')}</div>
            
        </div>
    )
}

export default ManageAccount
