import React, {useState, useEffect} from 'react'
import { connect } from "react-redux";
import AllMediaContentList from '../../components/global/AllMediaContentList'
import {hideLoading, makeListSupportAllMediaContentListFormat, showLoading} from '../../helper/Helper'
import { useDispatch } from 'react-redux'
import { homeSetSearchList } from '../../actions';
import {Suspense} from 'react';
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom';

const Search = ({searchList}) => {

    const history = useHistory();

    // List ที่ Format ไว้เพื่อใช้กับ AllMediaContentList component
    const [formattedSearchList, setFormattedSearchList] = useState([])
    const [wordSearch, setWordSearch] = useState('')

    const dispatch = useDispatch()

    const { t , i18n} = useTranslation('trans');

    useEffect( () => {
        // Scroll to top เสมอกรณีที่พึ่งเข้ามาหน้า search
        window.scrollTo(0, 0)
        
        return () => {
            dispatch(homeSetSearchList([]))
        }
    }, [])

    useEffect( () => {
        let list = makeListSupportAllMediaContentListFormat(searchList)
        setFormattedSearchList(list)
    }, [searchList])

    useEffect( () => {
        getWordSearchFromUrl()
    }, [formattedSearchList])

    const getWordSearchFromUrl = () => {
        const urlArray = window.location.href.split('q=')

        setWordSearch(urlArray[1])
    }

    return (
        (formattedSearchList.length > 0) ? 
        <div>
            <AllMediaContentList title={t('Search')} list={formattedSearchList} description=''/>
        </div>
        : 
        <div className='col-md-12' style={{textAlign: 'center'}}>
            <label style={{textSize: '50px', textAlign: 'center', marginTop: '30px', alignItems : "center"}}>{t('SearchNoResult')} "{decodeURI(wordSearch)}"</label>
        </div>
        
    )
}

const mapStateToProps = (state, ownProps) => ({
    searchList: state.home.searchList
})
  
const mapDispatchToProps = (dispatch, ownProps) => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Search)
