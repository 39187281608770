import React, {useState, useEffect} from 'react'
import {getSearchList} from '../../api'
import { useHistory,useLocation } from 'react-router-dom';
import {goToHomePage, search, isSearchPage} from '../../helper/Helper'
import { connect } from "react-redux";
import {homeSetSearchString, homeSetSearchList} from '../../actions'

import {Suspense} from 'react';
import { useTranslation } from 'react-i18next'

// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}
const SearchBar = ({homeSetSearchString, homeSetSearchList}) => {

    let query = useQuery()

    const history = useHistory()
    const location = useLocation()

    const { t , i18n} = useTranslation('trans');

    const [typingTimeoutId, setTypingTimeoutId] = useState(null)

    useEffect( () => {

        let searchQueryString = getSearchQueryString()
        if( isSearchPage(location.pathname) && !searchQueryString.trim() ){
            goToHomePage(history)
        }else if(!searchQueryString.trim()){
            
        }else{
            getSearchList(searchQueryString).then( (response) => handleGetSearchListResponse(response) )
        }
    },[])


    const handleSearchOnChange = ( value ) => {
        
        if( !value.trim() ){
            homeSetSearchString(value)
            if( history.location.state && history.location.state.flixerWeb ){
                history.goBack()
            }else{
                goToHomePage(history)
            }
        }else{
            homeSetSearchString(value)
            search(history, value)
    
            if( typingTimeoutId ){
                clearTimeout(typingTimeoutId);
            }
    
            let timeoutId = setTimeout(function () {
                getSearchList(value).then( (response) => handleGetSearchListResponse(response) );
            }, 1000);
    
            setTypingTimeoutId(timeoutId);
        }
    };

    const handleGetSearchListResponse = (response) => {
        let searchList = response.result[0].data
        if( searchList && searchList.length > 0){
            homeSetSearchList(searchList)
        }else{
            homeSetSearchList([]);
        }
        // Scroll To Top เสมอกรณีที่ Search สำเร็จ
        window.scrollTo(0, 0)
    };

    const getSearchQueryString = () => {
        if( query.get("q") ){
            return query.get("q");
        }else{
            return '';
        }
    }

    return (
        <React.Fragment>
            <input className='d-inline search-input form-control' placeholder={t('navbar.search')} type='text' value={getSearchQueryString()} onChange={ e => handleSearchOnChange(e.target.value) }/>
            <div className="search-icon "><i></i></div>
        </React.Fragment>
    )
}


const mapStateToProps = (state, ownProps) => ({
})
  
const mapDispatchToProps = (dispatch, ownProps) => ({
    homeSetSearchString: (searchString) => dispatch(homeSetSearchString(searchString)), 
    homeSetSearchList: (searchList) => dispatch(homeSetSearchList(searchList))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchBar)