import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getTermOfUseContentPath } from '../../api';

const TermsOfUsePage = () => {

    const [dataHtml, setDataHtml] = useState();
    const {language} = useParams();

    useEffect(()=>{
        if(language == null || language === 'en' || language === 'es') {
            document.body.style.paddingTop = '0px';

            getTermOfUseContentPath().then( (res) => {
                handleTermOfUseContent(res)
            })
        } else {
            window.location.replace("/pageNotFound");
        }
    }, [])

    const handleTermOfUseContent = ( response ) => {
        setDataHtml(response.result.content_file_path);
      }    

    return (
        
        <div className='term-of-use-content'>
            <div dangerouslySetInnerHTML={{__html: dataHtml}} />
            <br/>
        </div>

    )
}

export default TermsOfUsePage
