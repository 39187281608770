import React from 'react'
import { useTranslation } from 'react-i18next'

const AboutFlixerPlus = () => {

    const { t , i18n} = useTranslation('trans');

    return (
        <div className='about-flixer-plus-container'>
            <div className='about-flixer-plus-title'>{t('About')} Flixer+</div>
            <div className='about-flixer-plus-information'>
                {/* {window.flixer.aboutFlixerPlusDescription} */}
                {t('YouHave30DaysToStartWatching')} 
            </div>
        </div>
    )
}

export default AboutFlixerPlus