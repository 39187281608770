import { store } from '../index';

export const isUserLoggedIn = () => {
    let state = store.getState()
    // ไม่เป็นค่าว่างและไม่เป็น guest
    return state.user.userType && state.user.userType.trim().length > 0 && state.user.userType !== 'guest'
}

export const isUserPremium = () => {
    let state = store.getState()
    return state.user.isPremium
}
