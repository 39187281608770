const initialState = {
    show: false,
    refresh: false,
}

const premiumModal = (state = initialState, action) => {
    switch (action.type) {
        case 'PREMIUM_MODAL_SET_SHOW':
            return { ...state, show: action.show}
        case 'PREMIUM_MODAL_SET_REFRESH':
            return { ...state, refresh: action.refresh}
        case 'PREMIUM_MODAL_SET_CURRENT_TAB':
            return { ...state, currentTab: action.currentTab}
        default:
            return state
    }
}
  
export default premiumModal