const initialState = {
    hidden: true,
    currentTab: ''
}

const loginModal = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN_MODAL_SET_HIDDEN':
            return { ...state, hidden: action.hidden}
        case 'LOGIN_MODAL_SET_CURRENT_TAB':
            return { ...state, currentTab: action.currentTab }
        default:
            return state
    }
}
  
export default loginModal
