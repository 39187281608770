import React from 'react'
import { useHistory } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux'
import {chromecastSetMediaId, siteSetisClickFromSlideShow} from '../../actions'
import {getAge, goToVideo} from '../../helper/Helper'
import { useTranslation } from 'react-i18next';
import { getTitle } from '../../api'

const MediaLink = ({ mediaId, chromeCastSessionState, chromecastSetMediaId, children, type }) => {

    const dispatch = useDispatch()

    let history = useHistory();

    const userProfile = useSelector(state => state.user.profile);
    const { t , i18n} = useTranslation('trans');
    
    const handleOnClick = (event, mediaId ) => {

        if(type === "watchNowButtonBannerSlide") {
            dispatch(siteSetisClickFromSlideShow(true))
        } else {
            dispatch(siteSetisClickFromSlideShow(false))
        }
        
        // กรณีที่เปิด ChromeCast อยู่ จะทำการเล่นหนังผ่าน ChromeCast
        if( chromeCastSessionState === window.flixer.chromecast.sessionState.started || 
            chromeCastSessionState === window.flixer.chromecast.sessionState.resumed
        ){
            // chromecastSetMediaId(mediaId);

            getTitle(mediaId).then( (titleResponse) => {
                var rating = titleResponse.result.title.rating;
                // var rating = details.rating;
                var age = getAge(userProfile.dateOfBirth) ;
                var ratingList = [];
        
                if(rating != null && rating != '') {
                    if (parseInt(age) >= 18) {
                        ratingList = ["R-18","R-15","PG-12","G"];
                    } else if(parseInt(age) >= 15 && parseInt(age) < 18) {
                        ratingList = ["R-15","PG-12","G"];
                    } else if(parseInt(age) > 12 && parseInt(age) < 15) {
                        ratingList = ["PG-12","G"];
                    }else {
                        ratingList = ["PG-12","G"];
                    }
        
                    if(ratingList.includes(rating)) {
                        chromecastSetMediaId(mediaId);
                    } else {
                        alert(t('TextAlertCannotViewMovie'));
                    }
        
                } else {
                    chromecastSetMediaId(mediaId);
                }
            });



        }else{
            goToVideo(history, mediaId);
        }

        //ให้มันไม่ได้ทำ event ของ parent ที่เกิดขึ้น
        event.stopPropagation()

    }

    return (
        <div onClick={ (e) => handleOnClick(e, mediaId) } className='media-link'>
            {children}
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    mediaId: ownProps.mediaId,
    episode: ownProps.episode,
    chromeCastSessionState: state.chromecast.sessionState
})
  
const mapDispatchToProps = (dispatch, ownProps) => ({
    chromecastSetMediaId: (mediaId) => dispatch(chromecastSetMediaId(mediaId))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MediaLink)
