import React from 'react'

const ButtonTopupTranparent = ({ firstLine, secondLine, onClick }) => {
    return (
        <button className='premium-topup-button' onClick={onClick}>
            <span className='premium-topup-button-first-line-text'>{firstLine}</span><br/>
            <span className='premium-topup-button-second-line-text'>{secondLine}</span>
        </button>
    )
}

export default ButtonTopupTranparent
