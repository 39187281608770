import React, { useState, useEffect } from 'react'
import LikeIconOff from '../../images/video/like-off.png'
import LikeIconOn from '../../images/video/like-on.png'
import DisLikeIconOff from '../../images/video/dislike-off.png'
import DisLikeIconOn from '../../images/video/dislike-on.png'
import { isUserLoggedIn } from '../../helper/ReduxStoreHelper';
import { useDispatch, useSelector } from 'react-redux'
import { loginModalSetHidden } from '../../actions'

const LikeAndDisLikeButton = ({ likeCount, dislikeCount, likeStatus, handleOnClick }) => {

    const [ likeStatusState, setLikeStatusState ] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        setLikeStatusState(likeStatus)
    }, [likeStatus])

    const handleLikeDisLikeOnClick = (status) => {
        // if (isUserLoggedIn()) {
        //     setLikeStatusState(status)
        //     handleOnClick(status)
        // } else {
        //     dispatch(loginModalSetHidden(false))
        // }
        
    }

    return (
        <div className='video-page-like-dislike-content mb-3'>
            <div className='row mb-1'>
                <div className='col text-left'>
                    {
                            likeStatusState === 1 || likeStatusState === '1'
                        ?
                            <img className='video-page-like-button' src={LikeIconOn} alt='' onClick={() => handleLikeDisLikeOnClick(0)} />
                        :
                            <img className='video-page-like-button' src={LikeIconOff} alt='' onClick={() => handleLikeDisLikeOnClick(1)} />
                    }
                </div>
                {/* <div className='col text-center'>
                    {
                            likeStatusState === -1 || likeStatusState === '-1'
                        ?
                            <img className='video-page-like-button' src={DisLikeIconOn} alt='' onClick={() => handleLikeDisLikeOnClick(0)} />
                        :
                            <img className='video-page-like-button' src={DisLikeIconOff} alt='' onClick={() => handleLikeDisLikeOnClick(-1)}  />
                    }
                </div> */}
            </div>
            <div className='row'>
                <div className='col text-left'>
                    {likeCount}
                </div>
                {/* <div className='col text-center'>
                    {dislikeCount}
                </div> */}
            </div>
            
        </div>
    )
}

export default LikeAndDisLikeButton
