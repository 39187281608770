import React from 'react'
import MediaContent from './MediaContent'
import SeeAllButton from './SeeAllButton'
import { Swiper, SwiperSlide } from 'swiper/react';
import { checkIsMobileWidth } from '../../helper/Helper';
import useClientWidth from '../../hooks/useScreenWidth';
import DragScrollContainer from '../helper/DragScrollContainer'

const MediaListContainer = ({title, lists, shelfId}) => {

    const clientWidth = useClientWidth()

    return (
        <div className='media-list-container'>
            <div className='mb-1'>
                <div className='media-list-container-title d-inline'>{title}</div>
               <SeeAllButton shelfId={shelfId}/>
            </div>
            {
                    checkIsMobileWidth(clientWidth)
                ?
                    <DragScrollContainer className='media-list full-width-container-with-start-spacing'>
                        {
                            lists.map( (media) => {
                                return  <MediaContent key={media.id + media.episode} mediaId={media.id} thumbnailUrl={media.thumbnail_url} name={media.name}/>
                            })
                        }
                    </DragScrollContainer>
                :
                    <Swiper 
                        slidesPerView={'auto'} 
                        slidesPerGroup={4}
                        freeMode={true} 
                        navigation={true}
                        allowTouchMove={false}
                        speed={500}
                        className='horizontal-list media-list full-width-container-with-start-spacing'
                    >
                        {
                            lists.map( (media) => {
                                
                                return  <SwiperSlide key={media.id + media.episode}><MediaContent  mediaId={media.id} thumbnailUrl={media.thumbnail_url} name={media.name}/></SwiperSlide>
                            })
                        }
                    </Swiper>
            }
        </div>
    )

}

export default MediaListContainer
