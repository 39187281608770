import {useState, useEffect} from 'react'

const useClientWidth = () => {
    const [clientWith, setClientWith] = useState(document.documentElement.clientWidth)

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            const clientWith = document.documentElement.clientWidth
            setClientWith(clientWith)
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize)
    }, [])

    return clientWith;
}

export default useClientWidth