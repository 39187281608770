import React, { useState } from 'react'
import { connect } from 'react-redux'
import { loginModalSetCurrentTab, loginModalSetHidden } from '../../actions'
import { emailLogin, sendVerifyEmail } from '../../api'
import { Row, Col} from 'react-bootstrap'
import SplitLine from '../../components/login/SplitLine'
import FacebookLoginButton from '../../components/login/FacebookLoginButton';
import AppleLoginButton from '../../components/login/AppleLoginButton';
import TermAndPrivacy from '../../components/login/TermAndPrivacy'
import { firebaseLogEvent, hideLoading, showLoading } from '../../helper/Helper'
import { useTranslation } from 'react-i18next'
import GoogleLoginButton from '../../components/login/GoogleLoginButton'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Modal } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import ModalCloseButton from '../../components/global/ModalCloseButton'

const LoginForm = ({dispatch, show}) => {

    const { t , i18n} = useTranslation('trans');

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const [showModal, setShowModal] = useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const [modalTextDetailSendEmail, setModalTextDetailSendEmail] = useState("")
    const [modalTextTitleSendEmail, setModalTextTitleSendEmail] = useState("")
    const [modalButton1SendEmail, setModalButton1SendEmail] = useState("")
    const [modalButton2SendEmail, setModalButton2SendEmail] = useState("")
    const [verifyEmailToken, setVerifyEmailToken] = useState("")

    const handleOnCloseButtonClick = () => {
        handleClose()
        // setShowCheckboxAgreeModal(false)
        // dispatch(loginModalSetCurrentTab(''))
        // dispatch(loginModalSetHidden(false))
    }

    const handleResendEmailClick = () => {
        // verifyEmailToken
        showLoading();
        handleClose();
        sendVerifyEmail(verifyEmailToken).then(handleVerifyResponse)

    }

    

    const handleVerifyResponse = async (response) => {
        
        hideLoading();
        // handleClose()
        alert(response.message.message)
        // setShowCheckboxAgreeModal(false)
        // dispatch(loginModalSetCurrentTab(''))
        // dispatch(loginModalSetHidden(false))

    }


    const handleUsernameChange = (event) => {
        setUsername(event.target.value)
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value)
    }


    const handleEmailLoginResponse = async (response) => {

        hideLoading()

        if( response.error === false ){
            firebaseLogEvent('login', { method: "Email"})
            dispatch(loginModalSetHidden(true))
            window.location.reload()
        }else{
            if(response.error_code === 122) {
                setModalTextTitleSendEmail(response.message.title)
                setModalTextDetailSendEmail(response.message.message)
                setModalButton1SendEmail('OK')
                setModalButton2SendEmail(response.message.button)
                setVerifyEmailToken(response.result.verifyEmailToken)
                setShowModal(true)
            } else {
            
            alert("Title: " + response.message.title + "\nMessage: " + response.message.message + "\n" )
            }
        }

    }

    const handleOnSubmit = (e) => {
        e.preventDefault();
        showLoading()
        emailLogin(username, password).then(handleEmailLoginResponse)
    }

    return (
        <>
        <form className='login-modal-form' hidden={!show} onSubmit={handleOnSubmit}>
            <div className='login-modal-form-header'>
                <span className='login-modal-form-back-button' onClick={() => dispatch(loginModalSetCurrentTab(''))}>{t('Back')}</span>
                <h3 className='text-center'>{t('SignIn')}</h3>
            </div>
            <div className='login-modal-form-body text-center' style={{marginBottom: "180px"}}>
                <div className="form-group">
                    <input type="text" className="form-control" placeholder={t('Email')} onChange={handleUsernameChange} required/>
                    <input type="password" className="form-control" placeholder={t('Password')} onChange={handlePasswordChange} required/>
                    <button className='btn login-modal-form-button mb-2'>{t('SignIn')}</button>    
                    <div className='font-size-90 text-center text-orange'>
                        <span className='cursor-pointer' onClick={() => dispatch(loginModalSetCurrentTab('forget-password'))}>{t('ForgetPassword')}</span>
                    </div>
                    {/* <SplitLine />
                    <Row className='mb-2' style={{display: "block"}}>
                        <Col className='mb-3'>
                            <FacebookLoginButton />
                        </Col>
                        <div className='w-100'></div>
                        <Col className='mb-3'>
                            <AppleLoginButton />
                        </Col>
                        <div className='w-100'></div>
                        <Col>
                            <GoogleOAuthProvider clientId={'813172702758-ahvehtsnshf7221kku47d5devtono9ad.apps.googleusercontent.com'}> 
                                <GoogleLoginButton />
                            </GoogleOAuthProvider>
                        </Col>
                    </Row>
                    <TermAndPrivacy /> */}
                    
                </div>
            </div>
        </form>


{/* <Button variant="primary" onClick={handleShow}>
  Launch demo modal
</Button> */}

<Modal className='' show={showModal} onHide={handleClose}>
  <div className='modal-content' style={{backgroundColor: 'RGB(63,63,63)', border: '0 none'}}>
  <Modal.Header  style={{color: 'white', borderColor: 'rgba(255,255,255, 0.3)'}}>
  <ModalCloseButton onClick={handleClose}/>
    <Modal.Title>{modalTextTitleSendEmail}</Modal.Title>
  </Modal.Header>
  <Modal.Body style={{color: 'white'}}>

      {modalTextDetailSendEmail}
      
  </Modal.Body>
  <Modal.Footer style={{ borderColor: 'rgba(255,255,255, 0.3)'}}>
  {/* <button type="button" class="btn btn-outline-danger">Danger</button> */}
    <Button className='btn btn-outline' style={{color: '#e30f1e', backgroundColor: 'RGB(63,63,63)', borderColor: '#e30f1e', letterSpacing: '0.05em', borderWidth: '1.5px'}} variant="secondary" onClick={handleResendEmailClick}>
    {modalButton2SendEmail}
    </Button>
    <Button variant="primary" style={{backgroundColor: '#e30f1e', borderColor: '#e30f1e', color: 'black', borderWidth: '1.5px'}} onClick={handleOnCloseButtonClick}>
    {modalButton1SendEmail}
    </Button>
  </Modal.Footer>
  </div>
</Modal>
</>
    )
}


const mapStateToProps = (state, ownProps) => ({
    show: ownProps.show
})
  
const mapDispatchToProps = (dispatch, ownProps) => ({
    dispatch
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginForm)

