import React from 'react'
import {Suspense} from 'react';
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom';

const SplitLine = () => {

    const { t , i18n} = useTranslation('trans');
    // const {language} = useParams();
    let cookieCurrentLanguage = document.cookie.replace(/(?:(?:^|.*;\s*)currentLanguage\s*\=\s*([^;]*).*$)|^.*$/, "$1");

    const styleWithDafult = { width: "50px", marginLeft: "-25px" , color: "RGB(177,177,177)"};
    const styleWithSpain = { width: "100px", marginLeft: "-45px" , color: "RGB(177,177,177)"};

    return (
        <Suspense fallback="loading">
        {/* <div className="split-line">
            <label className="label-or" style={ (cookieCurrentLanguage === 'es') ? styleWithSpain : styleWithDafult}>{t('OR')}</label>
        </div> */}
        {/* <div className='content-text-or'>
            <div className='split-line-left' style={{width : "45%", float: "left"}}><hr/></div>
            <div className='text-or' style={{width : "10%", float: "center"}}>OR</div>
            <div className='split-line-right' style={{width : "45%", float: "right"}}><hr/></div>
        </div> */}
        <div className='content-text-or'>
        { (cookieCurrentLanguage === 'es') ? 
            <div className='split-line-left' >
                <hr style={{width : "35%", float: "left"}}/>
                <font style={{width : "30%", float: "center"}}>{t('OR')}</font>
                <hr style={{width : "35%", float: "right"}}/>
            </div>
            :

            <div className='split-line-left' >
                <hr style={{width : "45%", float: "left"}}/>
                <font style={{width : "10%", float: "center"}}>{t('OR')}</font>
                <hr style={{width : "45%", float: "right"}}/>
            </div>

        }
        </div>
        </Suspense>
    )
}

export default SplitLine
