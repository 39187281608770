import React from 'react'

const BackTopupButton = ({ firstLine, onClick }) => {
    return (
        <button className='premium-back-button'  onClick={onClick}>
            <span  >{firstLine}</span>
        </button>
    )
}

export default BackTopupButton