import React from 'react'
import { connect } from 'react-redux'
import {chromecastSetIsPlay} from '../../actions'
import PlayImage from '../../images/cast/play.png'

const PlayButton = ({ isShow, setIsPlay }) => {

    const handleOnClick = () => {
        window.flixer.castPlayerController.play();
        setIsPlay(true);
    }
    
    return (
        <div className='cursor-pointer' hidden={!isShow} onClick={handleOnClick}>
            <img width='50px' src={PlayImage} alt="" />
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    isShow: !state.chromecast.isPlay
})
  
const mapDispatchToProps = (dispatch, ownProps) => ({
    setIsPlay: (isPlay) => dispatch(chromecastSetIsPlay(isPlay))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PlayButton)
