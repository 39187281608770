import React, { useState, useEffect } from "react"
import { connect } from 'react-redux'
import image from '../../images/cast/subtitle_selection.png'
import {chromecastSetMediaActiveTextTrack} from '../../actions'
import { useCookies } from 'react-cookie'


const MediaSubtitle = ({ tracks, activeTrack, setActiveTrack }) => {

    const [cookies, setCookie] = useCookies(['chromecastDetails']);

    const [hideMenu, setHideMenu]             = useState(true);
    const [selectedIndex, setSelectedIndex]   = useState(null);

    useEffect(() => {
        if( activeTrack.trackId ){
            setSelectedIndex(activeTrack.trackId);
        }else{
            setSelectedIndex(null);
        }
    },[activeTrack]);


    const handleOnClick = (track) => {
        if( track ){
            setActiveTrack(track)
            setUserSettingsCookie('language', 'subtitleLanguage', track.language)
        }else{
            setActiveTrack({})
            setUserSettingsCookie('language', 'subtitleLanguage', null)
        }

        window.flixer.castPlayerController.setTextLanguageFromTrack(track, () => {} );
    }

    const setUserSettingsCookie = ( type, name, value ) => {

        let cookieConsentGiven = document.cookie.replace(/(?:(?:^|.*;\s*)rcl_consent_given\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        let cookieConsentPreferences = document.cookie.replace(/(?:(?:^|.*;\s*)rcl_preferences_consent\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        let cookieConsentMarketing = document.cookie.replace(/(?:(?:^|.*;\s*)rcl_marketing_consent\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        let cookieConsentStatistics = document.cookie.replace(/(?:(?:^|.*;\s*)rcl_statistics_consent\s*\=\s*([^;]*).*$)|^.*$/, "$1");

        if(cookieConsentPreferences !== '') {
            let userSettings = cookies.userSettings
            if( typeof userSettings === 'undefined' ){
                userSettings = window.flixer.userSettingsDefault
            }

            userSettings[type][name] = value

            setCookie('userSettings', JSON.stringify(userSettings), { path: '/', maxAge: 31622400, domain: window.flixer.cookieDomainDefault })  // maxAge 1 year
        } else {
            cookies = window.flixer.userSettingsDefault
        }
    }

    
    return (

        <div className='chromecast-bar-media-subtitle menu-button-container'>
            <div className='menu-container' hidden={hideMenu || tracks.length === 0} onMouseOver={ () => setHideMenu(false)} onMouseOut={ () => setHideMenu(true)}>
                <ul >

                    {
                        tracks.length >= 1 
                        ? 
                            <li className={null === selectedIndex ? "active" : ""} onClick={ () => handleOnClick(null)} >No Captions</li>
                        :
                            ""
                    }

                    { 
                        tracks.map( (track, index) => {     
                            return <li className={track.trackId === selectedIndex ? "active" : ""} onClick={ () => handleOnClick(track)} key={index}>{track.language}</li>;
                        })
                    }
                </ul>
            </div>
            <div className='menu-button-icon' onMouseOver={ () => setHideMenu(false)} onMouseOut={ () => setHideMenu(true)}>
                <img width='100%' src={image} alt="" />
            </div>
        </div>
    );

}

const mapStateToProps = (state, ownProps) => ({
    tracks: state.chromecast.media.availableTracks.text,
    activeTrack: state.chromecast.media.activeTracks.text
})
  
const mapDispatchToProps = (dispatch, ownProps) => ({
    setActiveTrack: (track) => dispatch(chromecastSetMediaActiveTextTrack(track))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MediaSubtitle)
