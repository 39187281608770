import React from 'react'
import MediaBannerContent from './MediaBannerContent'
import { Swiper, SwiperSlide } from 'swiper/react';
import DragScrollContainer from '../helper/DragScrollContainer'
import useClientWidth from '../../hooks/useScreenWidth';
import { checkIsMobileWidth } from '../../helper/Helper';

const MediaBannerContentList = ({list}) => {

    const clientWidth = useClientWidth()

    return (
            checkIsMobileWidth(clientWidth)
        ?
            <DragScrollContainer className='media-banner-content-list full-width-container-with-start-spacing'>
                {
                    list.map( (media, index) => {
                        return <MediaBannerContent key={index} details={media}/>
                    })
                }
            </DragScrollContainer>
        :
            <Swiper 
                slidesPerView={'auto'} 
                slidesPerGroup={1}
                freeMode={true} 
                navigation={true}
                allowTouchMove={false}
                speed={500}
                className='horizontal-list media-banner-content-list full-width-container-with-start-spacing'
            >
                {
                    list.map( (media, index) => {
                        
                        return  <SwiperSlide key={index}><MediaBannerContent details={media}/></SwiperSlide>
                    })
                }
            </Swiper>
    )
}

export default MediaBannerContentList