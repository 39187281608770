import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ProfileDummyIcon from '../../images/profile-dummy-icon.png'
import {Col} from 'react-bootstrap'
import { profileUpload } from '../../api'
import { userSetProfile } from '../../actions'
import CameraIcon from '../../images/account-modal/camera-icon.png'
import ImageRemoveIcon from '../../images/account-modal/image-remove-icon.png'
import { hideLoading, showLoading } from '../../helper/Helper'

const ImageProfile = () => {


    const dispatch    = useDispatch()
    const userProfile = useSelector(state => state.user.profile)

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
    })

    const toJpeg = base64Image => new Promise((resolve, reject) => {
        
        const canvas = document.getElementById('profile-image-converter-canvas')
        const ctx = canvas.getContext('2d')

        const img = document.getElementById( "profile-image-converter" )
        img.setAttribute( "src", base64Image)

        img.onload = function() {
            canvas.width = this.naturalWidth
            canvas.height = this.naturalHeight
            ctx.drawImage(this, 0, 0)

            resolve(canvas.toDataURL( "image/jpeg" ))
        }
    })
    

    const handleOnChange = async (e) => {

        const files = Array.from(e.target.files)

        if( files.length > 0 ){

            let base64Image = await toBase64(files[0])

            let convertedBase64Image = await toJpeg(base64Image)

            showLoading()
            profileUpload(convertedBase64Image).then(handleProfileUploadResponse)
         
            e.target.value = ''
        }
    
    }

    const handleProfileUploadResponse = (response) => {
        hideLoading()
        if( response.result && !response.error ){
            let profile = {...userProfile, profileImageUrl: response.result.profile_image_url }
            dispatch( userSetProfile( profile ) )
        }
    }

    const imageProfileRemoveOnClick = (e) => {
        // ไม่ให้เข้า function select file
        e.preventDefault()
        let isConfirm = window.confirm("Remove profile image?")
        if( isConfirm ){
            showLoading()
            profileUpload('').then(handleProfileUploadResponse)
        }
    }

 
    return (
        <Col>
            <label className='account-modal-image-profile-container' htmlFor="file-input">
                <div className='account-modal-image-profile' style={{'backgroundImage': 'url("'+ (userProfile.profileImageUrl ? userProfile.profileImageUrl : ProfileDummyIcon) +'")'}}>
                </div>
                {
                        // จะแสดงผลเฉพาะตอนที่มี profile image
                        userProfile.profileImageUrl
                    ?
                        <div className='account-modal-image-remove-icon' onClick={imageProfileRemoveOnClick}>
                            <img width="100%" src={ImageRemoveIcon} alt='' />
                        </div>
                    :
                        ""
                }
                <div className='account-modal-camera-icon'>
                    <img width="100%" src={CameraIcon} alt='' />
                </div>
            </label>
            <input id='file-input' type='file' accept="image/jpeg,image/png" onChange={handleOnChange} hidden/> 
            {/* ใช้สำหรับแปลงไฟล์ png เป็น jpeg ก่อน upload ขึ้น server */}
            <canvas id='profile-image-converter-canvas' hidden/>
            <img id='profile-image-converter' src='' alt='' hidden/>
        </Col>
    )

}

export default ImageProfile
