export const loginModalSetHidden = hidden => ({
    type: 'LOGIN_MODAL_SET_HIDDEN',
    hidden
})

/**
 * 
 * @param {string} currentTab  '', 'signin', 'signup'
 * @returns 
 */
export const loginModalSetCurrentTab = currentTab => ({
    type: 'LOGIN_MODAL_SET_CURRENT_TAB',
    currentTab
})