import React, { useEffect, useState } from 'react'
import Icon from '../../images/login-modal/icon/icon_google.png'
import { firebaseLogEvent, getAge, hideLoading, showLoading } from '../../helper/Helper';
import { getAgreementTextContent, googleLoginOAuthWeb } from '../../api';
import { useTranslation } from 'react-i18next'
import { useGoogleLogin } from '@react-oauth/google';
import axios from "axios"
import TermsOfUsePage from '../../components/termsofuse/TermsOfUsePage'
import PrivacyPolicyPage from '../../components/privacypolicy/PrivacyPolicyPage'
import { Container, Modal, Row } from 'react-bootstrap'
import ModalCloseButton from '../../components/global/ModalCloseButton'
import { Checkbox } from '@material-ui/core'
import { withStyles } from "@material-ui/core/styles";

const GoogleLoginButton = () => {

    // const clientId = '813172702758-ahvehtsnshf7221kku47d5devtono9ad.apps.googleusercontent.com';

    const { t } = useTranslation('trans');
    const [userInfoData, setUserInfoData] = useState(null)

    const [showDateOfBirthModal, setShowDateOfBirthModal] = useState(false)
    const [dateOfBirth, setDateOfBirth] = useState("")
    const [monthOfBirth, setMonthOfBirth] = useState("")
    const [yearOfBirth, setYearOfBirth] = useState("")
    const [userAge, serUserAge] = useState("")
    const [ageChecked, setAgeChecked] = React.useState(false);
    const [textAgreementCheckbox1, setTextAgreementCheckbox1] = useState("");
    const [textAgreementCheckbox2, setTextAgreementCheckbox2] = useState("");
    const [textAgreementContinueButton, setTextAgreementContinueButton] = useState("");

    const handleDateOfBirthChange = (event) => {
        const result = event.target.value.replace(/\D/g, '');
        setDateOfBirth(result);
    }

    const handleMonthOfBirthChange = (event) => {
        const result = event.target.value.replace(/\D/g, '');
        setMonthOfBirth(result);
    }

    const handleYearOfBirthChange = (event) => {
        const result = event.target.value.replace(/\D/g, '');
        setYearOfBirth(result);
    }
    const closeModalDateofBirth = () => {
        setShowDateOfBirthModal(false);
    }

    const handleCheckboxAgeChange = (event) => {
        setAgeChecked(event.target.checked);
    };
    
    const handleSignupButtonClick = () => {
        if(yearOfBirth === '' || monthOfBirth === '' || dateOfBirth === '') {
            alert(t('DateOfBirthNotValid'))
        } else {
            var birthday = yearOfBirth + '-' + monthOfBirth + '-' + dateOfBirth; 
            const moment = require('moment')
            var isValidDate = moment(birthday, "YYYY-MM-DD").isValid();
            if(isValidDate === true) {
                var age = getAge(birthday);
                serUserAge(age);
                if (parseInt(age) >= 13 && parseInt(age) <= 150) {
                    showLoading();
                    getAgreementTextContent().then( (res) => {
                        setTextAgreementCheckbox1(res.result.content_agreement_check);
                        setTextAgreementCheckbox2(res.result.content_agreement_check_under_18);
                        setTextAgreementContinueButton(res.result.content_agreement_continue);
                    })
                    setShowCheckboxAgreeModal(true);
                    hideLoading();
                } else if(parseInt(age) < 13 && parseInt(age) >= 0) {
                    alert(t('ServiceNotavailableUnder13'));
                }else {
                    alert(t('DateOfBirthNotValid'));
                }
            } else {
                alert(t('DateOfBirthNotValid'));
            }
        }

    }

        //check box agree
        const [checked, setChecked] = React.useState(false);
        const [showCheckboxAgreeModal, setShowCheckboxAgreeModal] = useState(false)
        const [showContinueButton , setShowContinueButton] = useState(false);
        const closeModalCheckboxAgree = () => {
            setShowCheckboxAgreeModal(false)
        }
        const handleChange = (event) => {
            setChecked(event.target.checked);
        };
        const clickButtonContinueModalCheckbox = () => {
            var birthday = yearOfBirth + '-' + monthOfBirth + '-' + dateOfBirth; 
            googleLoginOAuthWeb(userInfoData, true, birthday).then(handleGoogleLoginResponse)
        }
        const CustomColorCheckbox = withStyles({
            root: {
              color: "#e30f1e",
              "&$checked": {
                color: "#e30f1e"
              }
            },
            checked: {}
        })((props) => <Checkbox color="default" {...props} />);
    
        const handleScroll = (e) => {
            const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
            if (bottom) { 
                setShowContinueButton(true)
            } 
            // else {
            //     setShowCheckbox(false)
            // }
         } //end check box agree




    const handleGoogleLoginResponse = async (response) => {

        hideLoading()

        if( response.error === false ){
            if(response.result.shouldShowAgreement === false) {
                firebaseLogEvent('login', { method: "Google"})
                window.location.reload()
            } else {
                setShowDateOfBirthModal(true);
                // setShowCheckboxAgreeModal(true)  
            }
            
        }else{
            alert("Title: " + response.message.title + "\nMessage: " + response.message.message + "\n" )
        }
    }

    const googleLoginOAuth = useGoogleLogin({
        onSuccess: async (codeResponse) => {

            showLoading();
            // fetching userinfo can be done on the client or the server      
            const userInfo = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
                headers: { Authorization: `Bearer ${codeResponse.access_token}` },
            }).then(res => res.data);
            setUserInfoData(userInfo)
            googleLoginOAuthWeb(userInfo, false).then(handleGoogleLoginResponse)
        },
        onError: errorResponse => console.log(errorResponse),

        flow: 'implicit',
        scope: 'email profile',
    });

  return (
    <div className="">
          <div className="">
                <div style={{width: "100%", borderRadius: "4px" , color: "black",paddingTop: "8px", paddingBottom: "8px", marginBottom: "15px", background: "white"}} onClick={() => googleLoginOAuth()}>
                     <img className='' src={Icon} alt='' style={{width: "20px" , marginRight: "5px", marginBottom: "2px"}}/> 
                     <label>{t('SignInWithGoogle')}</label>
                </div>
          </div>


          <Modal
            show={showCheckboxAgreeModal}
            onHide={closeModalCheckboxAgree}
            dialogClassName='modal-dialog-centered account-modal-container'
            style={{ overflowY:"scroll", marginTop: "0px", marginBottom: "0px", paddingBottom: '20px'}}>
            <Modal.Body style={{height: 'auto', padding: '0px'}}>
            

                <div className='' onScroll={handleScroll} style={{backgroundColor: "RGB(25,25,25)", height:"850px",overflowY:"scroll", borderRadius: '12px' }}>
                    <ModalCloseButton onClick={closeModalCheckboxAgree}/>
                    <Container className='text-left' style={{maxWidth: "100%"}}>
                        
                        
                        <div style={{paddingBottom: '80px'}}>
                        

                            <div className='col-md-12'>
                                <TermsOfUsePage/>
                            </div>
                            <div className='col-md-12'>
                                <PrivacyPolicyPage/> 
                            </div>

                                         {/* { (showContinueButton === true)  ? */}
                                <div>
                                    { (parseInt(userAge) >= 13 && parseInt(userAge) < 18) ?
                                    <label style={{marginLeft: "30px"}}>
                                        <CustomColorCheckbox disableRipple checked={ageChecked} onChange={handleCheckboxAgeChange} style={{ marginLeft: "0px" , marginBottom: "3px"}}/>
                                        <span style={{ marginLeft: 6 }}>{textAgreementCheckbox2}</span>
                                    </label> : '' 
                                    }
                                    <label style={{marginLeft: "30px"}}>
                                        <CustomColorCheckbox disableRipple checked={checked} onChange={handleChange} style={{ marginLeft: "0px" , marginBottom: "3px"}}/>
                                        <span style={{ marginLeft: 6 }}>{textAgreementCheckbox1}</span>
                                    </label>
                                    <br/>
                                    <div className='col-md-12' style={{textAlign: "center", marginTop: "15px", marginBottom: "30px"}}>
                                    { 
                                    (parseInt(userAge) >= 18) ?
                                        (checked === true) ? 
                                        <button className='btn login-modal-form-button text-center' style={{width: "50%", backgroundColor: "#e30f1e", color: "black"}} onClick={clickButtonContinueModalCheckbox}> {textAgreementContinueButton} </button> 
                                        : 
                                        <button className='btn login-modal-form-button text-center' style={{width: "50%", backgroundColor: "RGB(34,34,37)", color: "RGB(82,82,86)"}} > {textAgreementContinueButton} </button> 
                                    : 
                                        (checked === true && ageChecked === true) ? 
                                        <button className='btn login-modal-form-button text-center' style={{width: "50%", backgroundColor: "#e30f1e", color: "black"}} onClick={clickButtonContinueModalCheckbox}> {textAgreementContinueButton} </button> 
                                        : 
                                        <button className='btn login-modal-form-button text-center' style={{width: "50%", backgroundColor: "RGB(34,34,37)", color: "RGB(82,82,86)"}} > {textAgreementContinueButton} </button> 
                                    }
                                    </div> 
                                </div>
                            {/* : ''
                            } */}
                            
                    
                        </div>

            
                    </Container>

       

                </div>

                

            </Modal.Body>

        </Modal>

        <Modal
            show={showDateOfBirthModal}
            onHide={closeModalDateofBirth}
            dialogClassName='modal-dialog-centered '
            style={{ overflowY:"scroll", marginTop: "0px", marginBottom: "0px", paddingBottom: '20px', minHeight: '0px', backgroundColor: 'rgba(0,0,0,0.6)'}}>
            <Modal.Body style={{height: 'auto', padding: '0px'}}>
                <div className='' onScroll={handleScroll} style={{backgroundColor: "RGB(25,25,25)",overflowY:"scroll", borderRadius: '10px' }}>
                    <ModalCloseButton onClick={closeModalDateofBirth}/>
                    <Container className='text-left' style={{maxWidth: "100%"}}>
                        
            <div className='login-modal-form-body text-center'>
                <div className="form-group mb-2">
                    <div className='date-of-birth'>
                        <Row style={{justifyContent: 'center'}}>
                        <label style={{fontSize: '14px', fontWeight: 'bold'}}>{t('YourBirthday')}</label>
                        </Row>
                        <Row style={{justifyContent: 'center'}}>
                            <label style={{width: '65px',padding: '0px', marginTop: '5px', marginLeft: '5px', marginRight: '5px',  textAlign: 'left', fontSize: '11px'}}>{t('Month')}*</label>
                            <label style={{width: '65px',padding: '0px', marginTop: '5px', marginLeft: '5px', marginRight: '5px',  textAlign: 'left', fontSize: '11px'}}>{t('Day')}*</label>
                            <label style={{width: '65px',padding: '0px', marginTop: '5px', marginLeft: '5px', marginRight: '5px',  textAlign: 'left', fontSize: '11px'}}>{t('Year')}*</label>
                        </Row>
                        <Row style={{justifyContent: 'center', marginBottom: '12px'}}>
                            <input className='form-control col-md-1' type='text' maxlength='2' value={monthOfBirth} placeholder='MM' style={{width: '65px', margin: '5px', textAlign: 'center'}} onChange={handleMonthOfBirthChange}/>
                            <input className='form-control col-md-1' type='text' maxlength='2' value={dateOfBirth} placeholder='DD' style={{width: '65px', margin: '5px', textAlign: 'center'}} onChange={handleDateOfBirthChange}/>
                            <input className='form-control col-md-1' type='text' maxlength='4' value={yearOfBirth} placeholder='YYYY' style={{width: '65px', margin: '5px', textAlign: 'center' }} onChange={handleYearOfBirthChange}/>
                        </Row>
                    </div>

                    <button className='btn login-modal-form-button' onClick={handleSignupButtonClick}>{t('SignUp')}</button>   
                </div>
 
            </div>
                    </Container>
                </div>
            </Modal.Body>
        </Modal>


    </div>
  )
}

export default GoogleLoginButton
