import {StepForwardOutlined} from '@ant-design/icons';

const NextButton = () => {

    const handleOnClick = () => {
        window.flixer.castPlayerController.queueNext( () => {} );
    }

    return (
        <div className='chromecast-control-bar-back-btn menu-button-icon'>
            <StepForwardOutlined onClick={ () => handleOnClick() } />
        </div>
    );

}   

export default NextButton