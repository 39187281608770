const initialState = { 
    mediaId: null,
    episode: null,
    sessionState: null,
    isPlay: false,
    isMuted:false,
    volume:0.5,
    deviceName:"",
    media: {
        title: "",
        // currentQueueItem: {},
        queueItems: [],
        currentQueueItemIndex: 0,
        duration:0,
        currentTime:0,
        remainingTime: "00:00",
        availableTracks: {
            text: [],
            audio: [],
        },
        activeTracks: {
            text: {},
            audio: {},
        }
    },
    source: []
};


const chromecast = (state = initialState, action) => {
    
    switch (action.type) {
        case 'SET_MEDIA_ID':
            return(
                {
                    ...state,
                    mediaId: action.id
                }
            )
        case 'SET_EPISODE':
            return(
                {
                    ...state,
                    episode: action.episode
                }
            )
        case 'SET_SESSION_STATE':
            return(
                {
                    ...state,
                    sessionState: action.state
                }
            )
        case 'SET_IS_PLAY':
            return{...state, isPlay: action.isPlay}
        case 'SET_IS_MUTE':
            return{...state, isMuted: action.isMuted}
        case 'SET_VOLUME':
            return{...state, volume: action.volume }
        case 'SET_MEDIA_DURATION':
            return{...state, media: {...state.media, duration: action.duration }}
        case 'SET_MEDIA_CURRENT_TIME':
            return{...state, media: {...state.media, currentTime: action.currentTime }}
        case 'SET_MEDIA_REMAINING_TIME':
            return{...state, media: {...state.media, remainingTime: action.remainingTime }}
        case 'SET_MEDIA_AVAILABLE_TEXT_TRACKS':
            return{...state, media: {...state.media, availableTracks: {...state.media.availableTracks  , text: action.availableTextTracks } }}
        case 'SET_MEDIA_ACTIVE_TEXT_TRACK':
            return{...state, media: {...state.media, activeTracks: {...state.media.activeTracks  , text: action.activeTextTrack } }}
        case 'SET_MEDIA_AVAILABLE_AUDIO_TRACKS':
            return{...state, media: {...state.media, availableTracks: {...state.media.availableTracks  , audio: action.availableAudioTracks } }}
        case 'SET_MEDIA_ACTIVE_AUDIO_TRACK':
            return{...state, media: {...state.media, activeTracks: {...state.media.activeTracks  , audio: action.activeAudioTrack } }}
        case 'SET_MEDIA_TITLE':
            return{...state, media: {...state.media, title: action.title  }}
        case 'SET_DEVICE_NAME':
            return{...state, deviceName: action.deviceName }
        default:
            return state
    }
}
  
export default chromecast
