import 'antd/dist/antd.css';
import "@silvermine/videojs-chromecast/dist/silvermine-videojs-chromecast.css";
import '../App.scss';
import '../App.css';
import 'video.js/dist/video-js.css'
import { useEffect, useState } from 'react';
import Home from '../containers/home/Home';
import Search from '../containers/search/Search';
import VideoPage from '../containers/video/VideoPage';
import ChromeCastControlBar from '../containers/chromecast/ChromeCastControlBar';
import {Switch, Route, BrowserRouter as Router, Redirect} from 'react-router-dom'
import React from 'react'
import CategoryPage from './category/CategoryPage';
import SeeAllPage from './seeall/SeeAllPage'
import Footer from './footer/Footer';
import NavBar from '../containers/navbar/NavBar';
import CharacterPage from './character/CharacterPage';
import {connect, useSelector} from 'react-redux'
import {guestLogin, getProfile, checkWebCredentialExist, receiptValidator, checkMaintenance, consentlog} from '../api'
import LoginModal from '../containers/login/LoginModal';
import { siteSetAvailableMessage, siteSetIsAvailable, userSetProfile } from '../actions';
import { alertErrorByCode, convertUnderscoreObjectKeyToCamelCaseObjectKey } from '../helper/Helper';
import AccountModal from '../containers/account/AccountModal';
import FlixerPlusPage from '../containers/flixerplus/FlixerPlusPage';
import HandePageChange from '../containers/helper/HandePageChange';
import PremiumModal from '../containers/premium/PremiumModal';
import RedeemPage from '../containers/redeem/RedeemPage';
import Loading from './global/Loading';
import ServiceUnavailable from './serviceunavailable/ServiceUnavailable';
import { useCookies } from 'react-cookie'
import { Helmet } from 'react-helmet';

import { CookieBanner } from "@palmabit/react-cookie-law";
import { useTranslation } from 'react-i18next';
import PageNotFound from './serviceunavailable/PageNotFound';
import TermsOfUsePage from './termsofuse/TermsOfUsePage';
import PrivacyPolicyPage from './privacypolicy/PrivacyPolicyPage';
import HelpCenterPage from './helpCenter/HelpCenterPage';
import TermOfUseAndPrivacyPolicy from '../containers/termPrivacy/TermOfUseAndPrivacyPolicy';

function App( { dispatch } ) {

    const [cookies, setCookie] = useCookies(['userSettings']);

    const [isProfileLoaded, setIsProfileLoaded] = useState(false)

    const isSiteAvailable = useSelector(state => state.site.isAvailable.status)

    const [privacyPolicyLink , setPrivacyPolicyLink] = useState('');

    const { t , i18n} = useTranslation('trans');

    useEffect( () => {
        
        // ทุกครั้งที่เปิดหรือ refresh หน้าเว็บ จะทำการเรียก profile ก่อนเสมอเพื่อเช็ค user ก่อนโหลดหน้าเว็บ
        async function process() {

            // await checkMaintenanceProcess()
            checkUserSettingsCookiesAndInitialIfNotExsists()
            updateUserSettingsCookiesExpireDate()

            try {
                // let checkWebCredentialExistResponse = await checkWebCredentialExist()

                // // กรณีที่ยังไม่มี Credentail
                // if( checkWebCredentialExistResponse.result === false ){
                    // await guestLogin();
                // }

                // let getProfileResponse = await getProfile()

                // if( getProfileResponse.error === false ){

                //     if( getProfileResponse.user_type && getProfileResponse.user_type !== 'guest'){
                        // await receiptValidator()
                //     }

                    // let profileResult = convertUnderscoreObjectKeyToCamelCaseObjectKey(getProfileResponse.result)

                    // dispatch(userSetProfile(profileResult))
                    setIsProfileLoaded(true)
                // }else{
                //     alertErrorByCode("500")
                // }

            } catch(e) {
                // สำหรับ Handle Promise Reject 
            }
            
        }
        process()

        //manage link privacypolicy
        let cookieLanguage = document.cookie.replace(/(?:(?:^|.*;\s*)currentLanguage\s*\=\s*([^;]*).*$)|^.*$/, "$1")
        if(cookieLanguage == null || cookieLanguage == '') {
            cookieLanguage = 'en'
        }
        setPrivacyPolicyLink('/' + cookieLanguage + '/privacy-policy')

    }, [])

     const checkMaintenanceProcess = async () => {
        let checkMaintenanceResponse =  await checkMaintenance()
        let statusMaintenance = checkMaintenanceResponse.result.isMaintenance;
        let isErrorStatus = checkMaintenanceResponse.error;

        if(isErrorStatus) {
            alert("Title: " + checkMaintenanceResponse.message.title + "\nMessage: " + checkMaintenanceResponse.message.message + "\n" )
        }

        if(statusMaintenance) {
            // dispatch(siteSetIsAvailable(t('ServerMaintenance')))
            dispatch(siteSetAvailableMessage(t('ServerMaintenance')))
            dispatch(siteSetIsAvailable(false))
        }
    }


    // สำหรับดูว่ามี Cookies UserSettings อยู่หรือไม่ ถ้ายังไม่มีจะให้ทำการ Initial ค่า Default 
    const checkUserSettingsCookiesAndInitialIfNotExsists = () => {
        let userSettings = cookies.userSettings
        if( typeof userSettings === 'undefined' ){
            userSettings = window.flixer.userSettingsDefault
            setCookie('userSettings', JSON.stringify(userSettings), { path: '/', maxAge: window.flixer.userSettingsCookiesMaxAge, domain: window.flixer.cookieDomainDefault })
        }
    }

    // สำหรับอัพเดท ExpireDate ของ Cookies UserSettings เนื่องจากบาง browser จะไม่รองรับ maxAge ยาวถึง 1 ปี เช่น Safari จะ limit ไว้แค่ 7 วัน
    // จึงต้องทำการอัพเดท ExpireDate ทุกครั้งที่มีการเข้าเว็บ
    const updateUserSettingsCookiesExpireDate = () => {
        let userSettings = cookies.userSettings
        // กรณีที่พึ่ง Initial จาก function checkUserSettingsCookiesAndInitialIfNotExsists() ค่า userSettings ตรงนี้จะยังเป็น undefined อยู่
        if( typeof userSettings !== 'undefined' ){
            // ในส่วนนี้จะเป็นส่วนของการ UpdateVersion Cookies เช่นว่ามีบางอย่างเปลี่ยนแปลงและต้องทำการอัพเดทค่านั้นๆ ก็จะทำการตรวจสอบและอัพเดทตรงนี้
            // กรณีที่ Cookie version มีค่าไม่เท่ากับ version ปัจจุบัน จะต้องทำการอัพเดทตามที่มีการเปลี่ยนแปลง
            if( userSettings.version !== window.flixer.userSettingsDefault.version ){
                // version 2 มีแก้ไขเรื่อง set backgroundOpacity ให้เป็น 0
                userSettings.style.backgroundOpacity = '0'
                userSettings.version = window.flixer.userSettingsDefault.version
                setCookie('userSettings', JSON.stringify(userSettings), { path: '/', maxAge: window.flixer.userSettingsCookiesMaxAge, domain: window.flixer.cookieDomainDefault })
            }else{
                setCookie('userSettings', JSON.stringify(userSettings), { path: '/', maxAge: window.flixer.userSettingsCookiesMaxAge, domain: window.flixer.cookieDomainDefault })
            }
        }
    }

    let cookieCurrentLanguage = document.cookie.replace(/(?:(?:^|.*;\s*)currentLanguage\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    let currentLanguage = cookieCurrentLanguage
    
    if (currentLanguage !== '') {
        currentLanguage = "/" + currentLanguage + "/"
    }

    const pageNotFound = () => {

    }

    const cookieConsentSaveToLog = () => {
        let cookieConsentGiven = document.cookie.replace(/(?:(?:^|.*;\s*)rcl_consent_given\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        let cookieConsentMarketing = document.cookie.replace(/(?:(?:^|.*;\s*)rcl_marketing_consent\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        let cookieConsentStatistics = document.cookie.replace(/(?:(?:^|.*;\s*)rcl_statistics_consent\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        let cookieConsentPreferences = document.cookie.replace(/(?:(?:^|.*;\s*)rcl_preferences_consent\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        let cookieConsentAcceptValue = document.cookie.replace(/(?:(?:^|.*;\s*)cookieConsentAccept\s*\=\s*([^;]*).*$)|^.*$/, "$1");

        const d = new Date();
        d.setTime(d.getTime() + (365*24*60*60*1000)); //365 คือ 1 ปี 
        let expires = "expires="+ d.toUTCString();

        document.cookie = 'cookieConsentAccept' + "=" + 'true' + ";" + expires + ";path=/" + ";domain=" + window.flixer.cookieDomainDefault;

        //ที่ต้องเก็บ cookie consent ซ้ำอีกครั้ง เพราะติดปัญหาเรื่อง path url ที่ /ภาษา 
        // ทำให้กดเปลี่ยนไปภาษาอื่นแล้ว dialog cookie กลับมาขึ้น เนื่องจากไปอ่าน cookie ผิด path
        // เลยเก็บคุกกี้ไว้ที่ path  /  แทน
        if(cookieConsentGiven === 'true'){
            //ที่ใช้ code รูปแบบนี้เพราะในฟังก์ชั้นนี้ set cookie แบบ react แล้วมีปัญหา เลยใช้แบบ js ทั่วไป
            document.cookie = 'rcl_consent_given' + "=" + cookieConsentGiven + ";" + expires + ";path=/"+ ";domain=" + window.flixer.cookieDomainDefault;
        }
        if(cookieConsentMarketing === 'true'){
            document.cookie = 'rcl_marketing_consent' + "=" + cookieConsentMarketing + ";" + expires + ";path=/" + ";domain=" + window.flixer.cookieDomainDefault;
        }
        if(cookieConsentStatistics === 'true'){
            document.cookie = 'rcl_statistics_consent' + "=" + cookieConsentStatistics + ";" + expires + ";path=/" + ";domain=" + window.flixer.cookieDomainDefault;
        }
        if(cookieConsentPreferences === 'true'){
            document.cookie = 'rcl_preferences_consent' + "=" + cookieConsentPreferences + ";" + expires + ";path=/" + ";domain=" + window.flixer.cookieDomainDefault;    
        }   

            if(cookieConsentAcceptValue !== 'true' ){

                if(cookieConsentGiven === ''){
                cookieConsentGiven = false;
                }
                if(cookieConsentMarketing === ''){
                cookieConsentMarketing = false;
                }
                if(cookieConsentStatistics === ''){
                cookieConsentStatistics = false;
                }
                if(cookieConsentPreferences === ''){
                cookieConsentPreferences = false;
                }
            
                const moment = require('moment')
                var dateInEpochTS = 1595314414299
                var currentDate = moment(dateInEpochTS).format('MMM DD YYYY hh:mm:ss');

                const jsonData = {
                "date": currentDate,
                "cookieConsentGiven": cookieConsentGiven, 
                "cookieConsentStatistics": cookieConsentStatistics,
                "cookieConsentMarketing": cookieConsentMarketing,
                "cookieConsentPreferences": cookieConsentPreferences
                };
                const jsonString = JSON.stringify(jsonData);  
                consentlog(jsonString);
            }

        }

        const NotFound = () => 
                <div style={{width : "100%",  
                     textAlign: "center",
                     fontSize: "60px",
                     padding: "100px"
                 }}>
                     
                    Error 404 <br/>  Page Not Found <br/> This Page Isn't Available. 
                 
                 </div>

    return (
            // ตรวจสอบว่าเป็น IP จากประเทศไทยใช่หรือไม่ ถ้าไม่ใช่จะไปหน้า service unavailable
            isSiteAvailable
            ?
                isProfileLoaded  
                ?
                    <div>
                    {/* <CookieBanner
                        message="This site or third-party tools used by this make use of cookies necessary for the operation and useful for the purposes outlined in the cookie policy. To learn more or opt out, see the cookie policy. By accepting, you consent to the use of cookies."
                        styles={{
                            dialog: { 
                            // marginTop: "200px" ,
                            position: "fixed",
                            bottom: "0px",
                            left: "0px",
                            width: "100%",
                            background: "black",
                            paddingBottom: "10px",
                            zIndex: "9999"
                            },
                            button : {
                            border: "0px",
                            borderRadius: "20px",
                            margin: "5px",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            background: "var(--color-orange)",
                            color: "black",
                            },
                            optionLabel : {
                            // background: "black",
                            // backgroundColor: "black",
                            color: "var(--color-orange)",
                            marginLeft: "20px"
                            },
                            message : {
                            color: "white",
                            padding : "10px"
                            }
                        }}
                        wholeDomain={true}
                        onAccept={() => { cookieConsentSaveToLog() }}
                        onAcceptPreferences={() => {}}
                        onAcceptStatistics={() => {}}
                        onAcceptMarketing={() => {}}
                        marketingDefaultChecked={false} 
                        preferencesDefaultChecked={false}
                        statisticsDefaultChecked={false}
                        dismissOnScroll={false}
                        showDeclineButton={true}
                        declineButtonText='Cancel'
                        managePreferencesButtonText='Manage my cookies'
                        policyLink={privacyPolicyLink} />  */}
                        <Helmet>
                            <title>SAMURAI VS NINJA</title>
                            <meta name="description" content="Unlimited viewing of SAMURAI & NINJA content!!" />
                        </Helmet>
                        <div className='body-content'>
                        <Router>
                            <HandePageChange />
                            <NavBar />
                            <Switch>

                                {/* Dont have language in url */}
                                {/* <Route exact path={window.flixer.homeUrl + "/"}>
                                <PageNotFound/>
                                </Route> */}
                                {/* <Route path={[window.flixer.homeUrl + "/video/:id/:episode?"]}>
                                    <VideoPage />
                                </Route> */}
                                <Route path={[window.flixer.homeUrl + "/video/:id/:episode/:apiKey?"]}>
                                    <VideoPage />
                                </Route>
                                

                                {/* Have language in url */}
                                {/* <Route exact path={window.flixer.homeUrl + "/:language/"}>
                                <PageNotFound/>
                                </Route> */}
                                {/* <Route path={[window.flixer.homeUrl + "/:language/video/:id/:episode?"]}>
                                    <VideoPage />
                                </Route> */}
                                <Route path={[window.flixer.homeUrl + "/:language/video/:id/:episode/:apiKey?"]}>
                                    <VideoPage />
                                </Route>
                                
                                <Route>
                                    <PageNotFound/>
                                </Route>
                            </Switch>
                            <ChromeCastControlBar /> 
                            <Loading />
                            {/* <LoginModal />
                            <AccountModal /> */}
                            {/* <PremiumModal /> */}
                        </Router>
                        </div>
                        
                        <Footer />

                     

                       
                    </div>
                :
                    <div className='container'>
                    </div>
            :
            <div className='body-content-service-unavailable'>
                <ServiceUnavailable />
            </div>

    );
}


const mapStateToProps = (state, ownProps) => ({
})
  
const mapDispatchToProps = (dispatch, ownProps) => ({
    dispatch
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App)