import React from 'react'
import { connect } from 'react-redux'
import {chromecastSetIsPlay} from '../../actions'
import PauseImage from '../../images/cast/pause.png'

const PauseButton = ({ isShow, setIsPlay }) => {

    const handleOnClick = () => {
        window.flixer.castPlayerController.pause();
        setIsPlay(false);
    }
    
    return (
        <div className='cursor-pointer' hidden={!isShow} onClick={handleOnClick}>
            <img width='50px' src={PauseImage} alt="" />
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    isShow: state.chromecast.isPlay
})
  
const mapDispatchToProps = (dispatch, ownProps) => ({
    setIsPlay: (isPlay) => dispatch(chromecastSetIsPlay(isPlay))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PauseButton)