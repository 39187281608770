import React from 'react'

const Title = ({ text }) => {

    return (
        <div className='video-page-title'>
            <h2 className='mb-0'>{text}</h2>
        </div>
    )
}

export default Title
