import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const CastInformation = ({ castDeviceName, mediaTitle }) => {

    const { t , i18n} = useTranslation('trans');

    return (
        <div className='chromecast-control-bar-cast-information'>
            {
                castDeviceName && mediaTitle ? t('PlayingOn') + castDeviceName + ': ' + mediaTitle : ''
            }
        </div>
    );

}


const mapStateToProps = (state, ownProps) => ({
    castDeviceName: state.chromecast.deviceName,
    mediaTitle: state.chromecast.media.title,
})
  
const mapDispatchToProps = (dispatch, ownProps) => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CastInformation)