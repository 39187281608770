import React from 'react'
import NoRentalListImage from '../../images/account-modal/no-rental-list.png'
import {Row, Col} from 'react-bootstrap'

const NoRentalList = () => {
    return (
        <Row className='no-rental-list-content text-center'>
            <Col>
                <img className='no-rental-list-content-image' src={NoRentalListImage} alt='' />
            </Col>
            <div className='w-100' />
            <Col className='text-secondary-light-color no-rental-list-content-description'>
                You do not have any purchased movies
            </Col>
        </Row>
    )
}

export default NoRentalList
