import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import { loginModalSetCurrentTab, loginModalSetHidden } from '../../actions'
import { emailSignUp, getAgreementTextContent, sendVerifyEmail } from '../../api'
import TermAndPrivacy from '../../components/login/TermAndPrivacy'
import { firebaseLogEvent, getAge, hideLoading, showLoading, validateEmail, validatePassword } from '../../helper/Helper'
import {Suspense} from 'react';
import { useTranslation } from 'react-i18next'
import TermsOfUsePage from '../../components/termsofuse/TermsOfUsePage'
import PrivacyPolicyPage from '../../components/privacypolicy/PrivacyPolicyPage'
import { Container, Modal, Row } from 'react-bootstrap'
import ModalCloseButton from '../../components/global/ModalCloseButton'
import { Checkbox } from '@material-ui/core'
import { withStyles } from "@material-ui/core/styles";
import Button from 'react-bootstrap/Button';
import { exists } from 'i18next'

const SignUpForm = ({show, dispatch}) => {

    const [showModal, setShowModal] = useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const { t , i18n} = useTranslation('trans');

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [dateOfBirth, setDateOfBirth] = useState("")
    const [monthOfBirth, setMonthOfBirth] = useState("")
    const [yearOfBirth, setYearOfBirth] = useState("")
    const [userAge, serUserAge] = useState("")

    const [modalTextDetailSendEmail, setModalTextDetailSendEmail] = useState("")
    const [modalTextTitleSendEmail, setModalTextTitleSendEmail] = useState("")
    const [modalButton1SendEmail, setModalButton1SendEmail] = useState("")
    const [modalButton2SendEmail, setModalButton2SendEmail] = useState("")
    const [verifyEmailToken, setVerifyEmailToken] = useState("")


    //check box agree
    const [checked, setChecked] = React.useState(false);
    const [ageChecked, setAgeChecked] = React.useState(false);
    const [showCheckboxAgreeModal, setShowCheckboxAgreeModal] = useState(false)
    const [showContinueButton , setShowContinueButton] = useState(false);
    const [textAgreementCheckbox1, setTextAgreementCheckbox1] = useState("");
    const [textAgreementCheckbox2, setTextAgreementCheckbox2] = useState("");
    const [textAgreementContinueButton, setTextAgreementContinueButton] = useState("");

    const closeModalCheckboxAgree = () => {
        setShowCheckboxAgreeModal(false)
    }
    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
    const handleCheckboxAgeChange = (event) => {
        setAgeChecked(event.target.checked);
    };

    const clickButtonContinueModalCheckbox = () => {
        // console.log("buttonNext click")
        showLoading();
        setShowCheckboxAgreeModal(false)
        dispatch(loginModalSetCurrentTab(''))
        dispatch(loginModalSetHidden(false))
        var birthday = yearOfBirth + '-' + monthOfBirth + '-' + dateOfBirth;
        emailSignUp(username, password, true, birthday).then(handleEmailSignUpResponse)
    }
    const CustomColorCheckbox = withStyles({
        root: {
          color: "#e30f1e",
          "&$checked": {
            color: "#e30f1e"
          }
        },
        checked: {}
    })((props) => <Checkbox color="default" {...props} />);

    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) { 
            setShowContinueButton(true)
        } 
        // else {
        //     setShowCheckbox(false)
        // }
     } //end check box agree
     

    const handleUsernameChange = (event) => {
        setUsername(event.target.value)
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value)
    }

    const handleDateOfBirthChange = (event) => {
        const result = event.target.value.replace(/\D/g, '');
        setDateOfBirth(result);
    }

    const handleMonthOfBirthChange = (event) => {
        const result = event.target.value.replace(/\D/g, '');
        setMonthOfBirth(result);
    }

    const handleYearOfBirthChange = (event) => {
        const result = event.target.value.replace(/\D/g, '');
        setYearOfBirth(result);
    }

    const handleEmailSignUpResponse = async (response) => {

        hideLoading()


        // alert("Title: " + response.message.title + "\nMessage: " + response.message.message + "\n" )

        if( response.error === false ){
            // firebaseLogEvent('sign_up', { method: "Email"})
            // dispatch(loginModalSetHidden(true))
            // window.location.reload()

            
            setModalTextTitleSendEmail(response.message.title)
            setModalTextDetailSendEmail(response.message.message)
            setModalButton1SendEmail('OK')
            setModalButton2SendEmail(response.message.button)
            setVerifyEmailToken(response.result.verifyEmailToken)
            setShowModal(true)

// modalTextDetailSendEmail
// modalTextTitleSendEmail
// modalButton1SendEmail
// modalButton2SendEmail

            // alert(response.message.message)
            // setShowCheckboxAgreeModal(false)
            // // dispatch(loginModalSetHidden(false))
            // dispatch(loginModalSetCurrentTab(''))
            // dispatch(loginModalSetHidden(false))

        }

    }

    const handleOnCloseButtonClick = () => {
        handleClose()
        // setShowCheckboxAgreeModal(false)
        // dispatch(loginModalSetCurrentTab(''))
        // dispatch(loginModalSetHidden(false))
    }

    const handleResendEmailClick = () => {
        // verifyEmailToken
        showLoading();
        handleClose()
        sendVerifyEmail(verifyEmailToken).then(handleVerifyResponse)

    }

    const handleVerifyResponse = async (response) => {
        
        hideLoading();
        // handleClose()
        alert(response.message.message)
        // setShowCheckboxAgreeModal(false)
        // dispatch(loginModalSetCurrentTab(''))
        // dispatch(loginModalSetHidden(false))

    }


    const handleEmailSignUpCheckRegister = async (response) => {

        hideLoading()

        // alert("Title: " + response.message.title + "\nMessage: " + response.message.message + "\n" )

        if( response.result.shouldShowAgreement === true ){
            showLoading();
            getAgreementTextContent().then( (res) => {
                setTextAgreementCheckbox1(res.result.content_agreement_check);
                setTextAgreementCheckbox2(res.result.content_agreement_check_under_18);
                setTextAgreementContinueButton(res.result.content_agreement_continue);
            })
            setShowCheckboxAgreeModal(true);
            hideLoading();
        }

    }

    const handleOnSubmit = (e) => {
        e.preventDefault();
        if(yearOfBirth === '' || monthOfBirth === '' || dateOfBirth === '') {
            alert(t('DateOfBirthNotValid'))
        } else {
            var birthday = yearOfBirth + '-' + monthOfBirth + '-' + dateOfBirth; 
            const moment = require('moment')
            var isValidDate = moment(birthday, "YYYY-MM-DD").isValid();

            if(isValidDate === true) {
                var age = getAge(birthday);
                serUserAge(age);
                // //default checkbox
                setChecked(false);
                setAgeChecked(false)

                if (parseInt(age) >= 13 && parseInt(age) <= 150) {
                    if( !validateEmail(username) ) {
                        alert(t('EmailAddressIsNotValid'))
                    }else if( !validatePassword(password) ) {
                        alert(t('PasswordAlert'))
                    }else{
                        showLoading();
                        emailSignUp(username, password, false, birthday).then(handleEmailSignUpCheckRegister)
                    }
                } else if(parseInt(age) < 13 && parseInt(age) >= 0) {
                    alert(t('ServiceNotavailableUnder13'))
                }else {
                    alert(t('DateOfBirthNotValid'))
                }
            } else {
                alert(t('DateOfBirthNotValid'))
            }
        }
        
    }

    

    return (
        <Suspense fallback="loading">
        <form className='login-modal-form modal-signup' hidden={!show} onSubmit={handleOnSubmit}>
            <div className='login-modal-form-header'>
                <span className='login-modal-form-back-button' onClick={() => dispatch(loginModalSetCurrentTab(''))}>{t('Back')}</span>
                <h3 className='text-center'>{t('SignUp')}</h3>
            </div>
            <div className='login-modal-form-body text-center'>
                <div className="form-group mb-2">
                    <input type="text" className="form-control" style={{maxWidth: '340px', marginLeft: 'auto', marginRight: 'auto'}} placeholder={t('Email')} autocomplete="off" onChange={handleUsernameChange} required/>
                    <input type="password" className="form-control" style={{maxWidth: '340px', marginLeft: 'auto', marginRight: 'auto'}} placeholder={t('Password')} autocomplete="off" onChange={handlePasswordChange} autoComplete='new-password' required/>

                    <div className='date-of-birth'>
                        <Row style={{justifyContent: 'center'}}>
                        <label style={{fontSize: '14px', fontWeight: 'bold'}}>{t('YourBirthday')}</label>
                        </Row>
                        <Row style={{justifyContent: 'center'}}>
                            <label style={{width: '65px',padding: '0px', marginTop: '5px', marginLeft: '5px', marginRight: '5px',  textAlign: 'left', fontSize: '11px'}}>{t('Month')}*</label>
                            <label style={{width: '65px',padding: '0px', marginTop: '5px', marginLeft: '5px', marginRight: '5px',  textAlign: 'left', fontSize: '11px'}}>{t('Day')}*</label>
                            <label style={{width: '65px',padding: '0px', marginTop: '5px', marginLeft: '5px', marginRight: '5px',  textAlign: 'left', fontSize: '11px'}}>{t('Year')}*</label>
                        </Row>
                        <Row style={{justifyContent: 'center', marginBottom: '12px'}}>
                            <input className='form-control col-md-1' type='text' maxlength='2' value={monthOfBirth} placeholder='MM' style={{width: '65px', margin: '5px', textAlign: 'center'}} onChange={handleMonthOfBirthChange}/>
                            <input className='form-control col-md-1' type='text' maxlength='2' value={dateOfBirth} placeholder='DD' style={{width: '65px', margin: '5px', textAlign: 'center'}} onChange={handleDateOfBirthChange}/>
                            <input className='form-control col-md-1' type='text' maxlength='4' value={yearOfBirth} placeholder='YYYY' style={{width: '65px', margin: '5px', textAlign: 'center' }} onChange={handleYearOfBirthChange}/>
                        </Row>
                    </div>

                    <button className='btn login-modal-form-button' style={{maxWidth: '340px'}}>{t('SignUp')}</button>   
                </div>
                <TermAndPrivacy />
            </div>
        </form>


        <Modal
            show={showCheckboxAgreeModal}
            onHide={closeModalCheckboxAgree}
            dialogClassName='modal-dialog-centered account-modal-container'
            style={{ overflowY:"scroll", marginTop: "0px", marginBottom: "0px", paddingBottom: '20px'}}>
            <Modal.Body style={{height: 'auto', padding: '0px'}}>
            

                <div className='' onScroll={handleScroll} style={{backgroundColor: "RGB(25,25,25)", height:"850px",overflowY:"scroll", borderRadius: '12px' }}>
                    <ModalCloseButton onClick={closeModalCheckboxAgree}/>
                    <Container className='text-left' style={{maxWidth: "100%"}}>
                        
                        
                        <div style={{paddingBottom: '80px'}}>
                        

                            <div className='col-md-12'>
                                <TermsOfUsePage/>
                            </div>
                            <div className='col-md-12'>
                                <PrivacyPolicyPage/> 
                            </div>

                                         {/* { (showContinueButton === true)  ? */}
                                <div>
                                    { (parseInt(userAge) >= 13 && parseInt(userAge) < 18) ?
                                    <label style={{marginLeft: "30px"}}>
                                        <CustomColorCheckbox disableRipple checked={ageChecked} onChange={handleCheckboxAgeChange} style={{ marginLeft: "0px" , marginBottom: "3px"}}/>
                                        <span style={{ marginLeft: 6 }}>{textAgreementCheckbox2}</span>
                                    </label> : '' 
                                    }
                                    <label style={{marginLeft: "30px"}}>
                                        <CustomColorCheckbox disableRipple checked={checked} onChange={handleChange} style={{ marginLeft: "0px" , marginBottom: "3px"}}/>
                                        <span style={{ marginLeft: 6 }}>{textAgreementCheckbox1}</span>
                                    </label>
                                    <br/>
                                    <div className='col-md-12' style={{textAlign: "center", marginTop: "15px", marginBottom: "30px"}}>
                                    { 
                                    (parseInt(userAge) >= 18) ?
                                        (checked === true) ? 
                                        <button className='btn login-modal-form-button text-center' style={{width: "50%", backgroundColor: "#e30f1e", color: "black"}} onClick={clickButtonContinueModalCheckbox}> {textAgreementContinueButton} </button> 
                                        : 
                                        <button className='btn login-modal-form-button text-center' style={{width: "50%", backgroundColor: "RGB(34,34,37)", color: "RGB(82,82,86)"}} > {textAgreementContinueButton} </button> 
                                    : 
                                        (checked === true && ageChecked === true) ? 
                                        <button className='btn login-modal-form-button text-center' style={{width: "50%", backgroundColor: "#e30f1e", color: "black"}} onClick={clickButtonContinueModalCheckbox}> {textAgreementContinueButton} </button> 
                                        : 
                                        <button className='btn login-modal-form-button text-center' style={{width: "50%", backgroundColor: "RGB(34,34,37)", color: "RGB(82,82,86)"}} > {textAgreementContinueButton} </button> 
                                    }
                                    </div> 
                                </div>
                            {/* : ''
                            } */}
                            
                    
                        </div>
     
                    </Container>

       

                </div>

                

            </Modal.Body>

        </Modal>


        <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

      <Modal className='' show={showModal} onHide={handleClose}>
        <div className='modal-content' style={{backgroundColor: 'RGB(63,63,63)', border: '0 none'}}>
        <Modal.Header closeButton style={{color: 'white',borderColor: 'rgba(255,255,255, 0.9)'}}>
          <Modal.Title>{modalTextTitleSendEmail}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{color: 'white'}}>

            {modalTextDetailSendEmail}
            
        </Modal.Body>
        <Modal.Footer >
        {/* <button type="button" class="btn btn-outline-danger">Danger</button> */}
          <Button className='btn btn-outline' style={{color: '#e30f1e', backgroundColor: 'RGB(63,63,63)', borderColor: '#e30f1e', letterSpacing: '0.05em', borderWidth: '1.5px'}} variant="secondary" onClick={handleResendEmailClick}>
          {modalButton2SendEmail}
          </Button>
          <Button variant="primary" style={{backgroundColor: '#e30f1e', borderColor: '#e30f1e', color: 'black', borderWidth: '1.5px'}} onClick={handleOnCloseButtonClick}>
          {modalButton1SendEmail}
          </Button>
        </Modal.Footer>
        </div>
      </Modal>
    </>


        </Suspense>
    )
}

const mapStateToProps = (state, ownProps) => ({
    show: ownProps.show
})
  
const mapDispatchToProps = (dispatch, ownProps) => ({
    dispatch
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SignUpForm)

