const initialState = {
    isFinish: false,
    finishMessage: "",
    finishMessageProductPeriodInDay: ""
}

const redeem = (state = initialState, action) => {
    switch (action.type) {
        case 'REDEEM_SET_FINISH':
            return { ...state, isFinish: action.isFinish}
        case 'REDEEM_SET_FINISH_MESSAGE':
            return { ...state, finishMessage: action.message}
        case 'REDEEM_SET_FINISH_MESSAGE_PRODUCT_PERIOD_IN_DAY':
            return { ...state, finishMessageProductPeriodInDay: action.periodInDay}
        default:
            return state
    }
}
  
export default redeem
