import React from 'react'
import Icon from '../../images/modal/close-btn.png'

const CloseButton = ({onClick}) => {
    return (
        <div className='modal-box-close-button' onClick={onClick}>
            <img src={Icon} alt=''/>
        </div>
    )
}

export default CloseButton
