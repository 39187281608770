import React, { useState, useEffect } from "react"
import MuteImage from '../../images/cast/audio_off.png'
import UnmuteImage from '../../images/cast/audio_on.png'
import Slider from '@material-ui/core/Slider';
import { connect } from 'react-redux'
import {chromecastSetIsMuted,chromecastSetVolume} from '../../actions'

const VolumeSlider = ({ chromeCastVolume, isMuted, setVolume, setIsMuted }) => {

    const [hideSlider, setHideSlider]   = useState(true);
    const [focus, setFocus]             = useState(false);

    useEffect(() => {
        const handleDocumentMouseUp = event => {
            if (focus === true) {
                setFocus(false)
            }
        };
      
        document.addEventListener('mouseup', handleDocumentMouseUp);
        return () => document.removeEventListener('mouseup', handleDocumentMouseUp);
    }, [focus]);

    
    const handleVolumeOnChange = (value) => {
        const volume = value
        setVolume(volume);
        window.flixer.castPlayerController.setVolume(volume)
    }

    const setMuted = () => {
        setIsMuted(!isMuted)
        
        if( isMuted ){
            window.flixer.castPlayerController.unMute()
        }else{
            window.flixer.castPlayerController.mute()
        }

    }

    return (
        <div className='chromecast-volume-control cursor-pointer' >
            <div className='volume-input-range' 
                onMouseOver={ () => setHideSlider(false)} 
                onMouseLeave={ () => setHideSlider(true)} 
                onMouseDown={ () => setFocus(true)}
                hidden={ !focus && ( isMuted || hideSlider )}
                >
                <div>
                    <Slider 
                        orientation="vertical"
                        value={chromeCastVolume} 
                        onChange={(event,value) => handleVolumeOnChange(value) } 
                        max={1}
                        step={0.01}
                    />
                </div>
            </div>

            <div onClick={setMuted} onMouseOver={ () => setHideSlider(false)} onMouseOut={ () => setHideSlider(true)}>
                {isMuted 
                ? 
                    <img src={MuteImage} alt='' />
                : 
                    <img src={UnmuteImage} alt='' />
                }
            </div>

        </div>
    )
}



const mapStateToProps = (state, ownProps) => ({
    chromeCastVolume: state.chromecast.volume,
    isMuted: state.chromecast.isMuted,
})
  
const mapDispatchToProps = (dispatch, ownProps) => ({
    setVolume: (volume) => dispatch(chromecastSetVolume(volume)),
    setIsMuted: (isMuted) => dispatch(chromecastSetIsMuted(isMuted))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VolumeSlider)
