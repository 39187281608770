import React from 'react'

const RentalalExpire = ({ expireInDayString, className='' }) => {
    return (
        <div className={ 'video-page-rental-expire ' + className }>
            {expireInDayString}
        </div>
    )
}

export default RentalalExpire
