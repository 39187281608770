export const homeSetShelf = shelf => ({
    type: 'HOME_SET_SHELF',
    shelf
})

export const homeSetSlideshow = slideshow => ({
    type: 'HOME_SET_SLIDESHOW',
    slideshow
})

export const homeSetSearchString = searchString => ({
    type: 'HOME_SET_SEARCH_STRING',
    searchString
})

export const homeSetSearchList = searchList => ({
    type: 'HOME_SET_SEARCH_LIST',
    searchList
})

export const homeSetContinueWatchingList = continueWatchingList => ({
    type: 'HOME_SET_CONTINUE_WATCHING_LIST',
    continueWatchingList
})