export const chromecastSetMediaId = id => ({
    type: 'SET_MEDIA_ID',
    id
})

export const chromecastSetEpisode = episode => ({
    type: 'SET_EPISODE',
    episode
})

export const chromecastSetSessionState = state => ({
    type: 'SET_SESSION_STATE',
    state
})

export const chromecastSetIsMuted = isMuted => ({
    type: 'SET_IS_MUTE',
    isMuted
})

export const chromecastSetIsPlay = isPlay => ({
    type: 'SET_IS_PLAY',
    isPlay
})

export const chromecastSetVolume = volume => ({
    type: 'SET_VOLUME',
    volume
})

export const chromecastSetMediaDuration = duration => ({
    type: 'SET_MEDIA_DURATION',
    duration
})


export const chromecastSetMediaCurrentTime = currentTime => ({
    type: 'SET_MEDIA_CURRENT_TIME',
    currentTime
})

export const chromecastSetMediaRemainingTime = remainingTime => ({
    type: 'SET_MEDIA_REMAINING_TIME',
    remainingTime
})


export const chromecastSetMediaAvailableTextTracks = availableTextTracks => ({
    type: 'SET_MEDIA_AVAILABLE_TEXT_TRACKS',
    availableTextTracks
})


export const chromecastSetMediaActiveTextTrack = activeTextTrack => ({
    type: 'SET_MEDIA_ACTIVE_TEXT_TRACK',
    activeTextTrack
})


export const chromecastSetMediaAvailableAudioTracks = availableAudioTracks => ({
    type: 'SET_MEDIA_AVAILABLE_AUDIO_TRACKS',
    availableAudioTracks
})


export const chromecastSetMediaActiveAudioTrack = activeAudioTrack => ({
    type: 'SET_MEDIA_ACTIVE_AUDIO_TRACK',
    activeAudioTrack
})


export const chromecastSetMediaTitle = title => ({
    type: 'SET_MEDIA_TITLE',
    title
})

export const chromecastSetDeviceName = deviceName => ({
    type: 'SET_DEVICE_NAME',
    deviceName
})

