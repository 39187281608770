import React,{useRef, useState} from 'react'
import { useHistory } from 'react-router-dom'
import { loginModalSetHidden, mediaSetCanView, mediaSetIsCurrentEpisodePremium, premiumModalSetShow, selectEpisode, selectMedia } from '../../actions'
import {alertErrorByCode, goToEpisode, hideLoading, showLoading} from '../../helper/Helper'
import { isUserLoggedIn, isUserPremium } from '../../helper/ReduxStoreHelper'
import { useDispatch, useSelector } from 'react-redux'
import { callApiRentalBuy, getEpisode } from '../../api'
// import DesktopSampleImage from '../../images/slideshow/slideshow-desktop-sample.png'
import DummyImage from '../../images/slideshow/slideshow-mobile-sample.png'
import { Container, Modal } from 'react-bootstrap'
import ModalCloseButton from '../../components/global/ModalCloseButton'
import { useTranslation } from 'react-i18next'


const EpisodeContent = ({ episode }) => {

    let history = useHistory();

    const dispatch = useDispatch();
    const { t , i18n} = useTranslation('trans');

    const currentMedia = useSelector(state => state.media)
    const currentMediaRef = useRef();
    currentMediaRef.current = currentMedia;

    const [showPopup, setShowPopup] = useState(false);

    const togglePopup = () => {
      setShowPopup(true);
    };

    const closePopup = () => {
        setShowPopup(false);
      };

    const handleOnClick = () => {

        if(window.flixer.episodeClickTimeoutID !== ''){
            clearTimeout(window.flixer.episodeClickTimeoutID)
        }

        // ใส่ timeout ไว้ 300 ms ก่อนที่จะเปลี่ยน Episode ป้องกันกรณีกดเปลี่ยนรัวๆจนพัง
        window.flixer.episodeClickTimeoutID = setTimeout( () => {  

            getEpisode(episode.title, episode.episode).then( (res) => handleEpisodeResponse(res) )

            const handleEpisodeResponse = ( response ) => {
                // This is src for player
            dispatch(mediaSetCanView(response.can_view));
            dispatch(mediaSetIsCurrentEpisodePremium(episode.premium));
            // dispatch(selectMedia(episode.id));
            // dispatch(selectEpisode(episode.episode));

            // if( (currentMediaRef.current.canView !== 1 && currentMediaRef.current.canView !== '1') ){
                
            //     if( !isUserLoggedIn() ){
            //         dispatch(loginModalSetHidden(false))
            //     }else{

            //         // Handle Case Episode ที่เป็น FreeTrial คือ ถ้า CanView เป็น 0 แต่ว่า episode นี้ ไม่ได้เป็น premium จะถือว่าเป็น Trial Episode ให้กดไปที่ Episode นั้นได้
            //         if( !episode.premium ){
            //             goToEpisode(history, currentMediaRef.current.id, episode.episode, false)
            //         }else if( currentMediaRef.current.isRentalType && !currentMediaRef.current.isRented ){
            //             handleRental()
            //         }else if( currentMediaRef.current.isCurrentEpisodePremium && isUserPremium() ){
            //             // กรณีที่หนังเป็น Premium และ User ก็เป็น Premium แสดงว่า User ดูหนังอยู่ในอีกจอนึงอยู่
            //             alertErrorByCode("0005")
            //         }else if( currentMediaRef.current.isCurrentEpisodePremium && !isUserPremium() ){
            //             // กรณีที่หนังเป็น Premium แต่ว่า User ไม่ได้เป็น Premium จะ Show หน้า Sub
            //             dispatch(premiumModalSetShow(true))
            //         }
            //     }
            // }else{
            
                goToEpisode(history, currentMediaRef.current.id, episode.episode, false)
            // }

        }

        }, 300)

    }

    const handleRental = () => {
        showLoading()
        callApiRentalBuy(currentMedia.details.product_id, currentMedia.details.price).then( (response) => {
            if( response.result.response && response.result.response.webPaymentUrl ){
                window.location.href = response.result.response.webPaymentUrl
            }else{
                alertErrorByCode(500)
            }
            hideLoading()
        })
    }

    return (
        // <div className={currentMedia.episode === episode.episode ? 'episode-content active' : 'episode-content'} onClick={ () => handleOnClick(episode) }>
        <div className={currentMedia.episode === episode.episode ? 'episode-content active' : 'episode-content'} >
            { (episode.thumbnail_url == null || episode.thumbnail_url == '') ?
                <div className='episode-content-image' onClick={ handleOnClick }>
                    <img src={DummyImage} alt=''/>
                </div>
                : 
                <div className='episode-content-image' onClick={ handleOnClick }>
                    <img src={episode.thumbnail_url} alt=''/>
                </div>
            }
            <div className='episode-content-name p-0' title={episode.name} onClick={ handleOnClick }>
                <b>{episode.name}</b>
            </div>
            { (episode.description != null && episode.description !== '') ?
                <div className='episode-content-sub-name p-0' style={{textAlign: 'left'}} title={episode.name} onClick={ handleOnClick }>
                    {episode.description}
                </div>
                : ''
            }
            { (episode.description != null && episode.description !== '') ?
                <div className='' style={{textAlign: 'right', fontSize: '12px', color: 'gray'}}>
                    <u onClick={togglePopup}>{ t('MoreTextSmall') }</u>
                </div>
                : ''
            }

        <Modal
            show={showPopup}
            onHide={closePopup}
            dialogClassName='modal-dialog-centered 0px'
            style={{ overflowY:"scroll", marginTop: "0px", marginBottom: "0px", paddingBottom: '20px'}}>
            <Modal.Body style={{height: 'auto', padding: '0px'}}>
            

                <div className='' style={{backgroundColor: "RGB(25,25,25)", borderRadius: '9px' }}>
                    <ModalCloseButton onClick={closePopup}/>
                    <Container className='text-left' style={{maxWidth: "100%"}}>
                        
                        
                        <div style={{paddingBottom: '80px', paddingTop: '30px', paddingLeft: '30px', paddingRight: '30px', color: 'white'}}>
                        
                            <div className='episode-content-name-popup p-0' style={{marginBottom: '20px', fontSize: '18px'}}>
                                <b>{episode.name}</b>
                            </div>
                            <div className='' style={{color: 'rgba(255, 255, 255, 0.5)'}}>
                                {episode.description}
                            </div>
                            
                        </div>

            
                    </Container>

       

                </div>

                

            </Modal.Body>

        </Modal>

        </div>
    )
}

export default EpisodeContent