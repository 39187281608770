import React, {useCallback, useEffect, useState} from 'react'
import { connect, useSelector } from 'react-redux'
import { Col } from 'react-bootstrap';
import { useResizeDetector} from 'react-resize-detector/build/withPolyfill';
import { loginModalSetHidden, premiumModalSetShow, siteSetBeforeTitleVideo, siteSetisClickFromSlideShow, siteSetisPlayMediaNextEpisode } from '../../actions';
import { isUserPremium } from '../../helper/ReduxStoreHelper';
import { alertErrorByCode, consoleLogHelper, getAge, getRentalPriceFromMediaDetail, getRentalProductIdFromMediaDetail } from '../../helper/Helper';
import RentButton from '../../components/video/RentButton';
import VideoPlayer from './VideoPlayer';
import { getTitle } from '../../api';
import { useTranslation } from 'react-i18next';

const DummyPageCheckRating = ({ thumbnailUrl, isGuest, dispatch , isPremium, isVideoPageLoaded, videoSource, playerAdsList, sourceChangeCount, media, onStartWatching, rating, videoLicenseUrl, videoCertificateUrl}) => {

    const root = document.documentElement;

    // const [confirmRating, setConfirmRating] = useState(false);
    const [canViewThisMedia, setCanViewThisMedia] = useState(false);
    const beforeTitleVideo = useSelector(state => state.site.beforeTitleVideo);
    const userProfile = useSelector(state => state.user.profile);
    const { t , i18n} = useTranslation('trans');

    const onResize = useCallback(( width, height ) => {
        root.style.setProperty('--video-js-height', height + "px");
    }, []);
      
    const { ref } = useResizeDetector({ onResize });

    const isClickFromSlideShow = useSelector(state => state.site.isClickFromSlideShow)
    const isPlayMediaNextEpisode = useSelector(state => state.site.isPlayMediaNextEpisode)

    useEffect(()=>{

    if(isVideoPageLoaded) {
        if(isClickFromSlideShow) {
            consoleLogHelper("ClickFromSlideShow" , isClickFromSlideShow)
            dispatch(siteSetisClickFromSlideShow(false))
            // if(!showRentButton) {
                handleOnClickCheckRating()   
            // }
            
        }
    }

    },[isVideoPageLoaded])

    // const handleEpisodeResponse = ( response ) => {
    //     // This is src for player
    //     // let playlist = response.result.playlist;
    //     // let adsList  = response.result.ads.dfp

    //     videoCertificateUrl = response.result.playlist_license_cert_url
    //     videoLicenseUrl = response.result.playlist_license_url

    //     setConfirmRating(true);

        
    // }

    const handleOnClickCheckRating = () => {
        // rating = media.details.rating;
        // var age = getAge(userProfile.dateOfBirth) ;
        // var ratingList = [];

        // if(rating != null && rating != '') {
        //     if (parseInt(age) >= 18) {
        //         ratingList = ["R-18","R-15","PG-12","G"];
        //     } else if(parseInt(age) >= 15 && parseInt(age) < 18) {
        //         ratingList = ["R-15","PG-12","G"];
        //     } else if(parseInt(age) > 12 && parseInt(age) < 15) {
        //         ratingList = ["PG-12","G"];
        //     }else {
        //         ratingList = ["PG-12","G"];
        //     }

        //     if(ratingList.includes(rating)) {
                setCanViewThisMedia(true);
        //     } else {
        //         setCanViewThisMedia(false);
        //         alert(t('TextAlertCannotViewMovie'));
        //     }

        // } else {
        //     setCanViewThisMedia(true);
        // }

        // if (rating != null && rating.includes('R-')) {
        //     ratingAge = rating.substring(2);

        //     if(beforeTitleVideo === '') {
        //         let confirm = window.confirm(t('MessageDialogCheckRating'));
        //         if (confirm) {
        //             setConfirmRating(true);
        //             dispatch(siteSetBeforeTitleVideo(String(media.id)));
                    
        //         } else {
        //             setConfirmRating(false);
        //         }
        //     } else if(beforeTitleVideo !== '' && beforeTitleVideo !== media.id) {
        //         let confirm = window.confirm(t('MessageDialogCheckRating'));
        //         if (confirm) {
        //             setConfirmRating(true);
        //             dispatch(siteSetBeforeTitleVideo(String(media.id)));
                    
        //         } else {
        //             setConfirmRating(false);
        //         }
        //     } else {
        //         setConfirmRating(true);
        //     }

        // } else {
        //     setConfirmRating(true);
        //     dispatch(siteSetBeforeTitleVideo(String(media.id)));
        // }

    }

    useEffect(()=>{

        setCanViewThisMedia(false)

        if(parseInt(media.startTime) > 0) {
            handleOnClickCheckRating();
        } 

        // if(String(media.id) === beforeTitleVideo){
        //     setConfirmRating(true)
        //     dispatch(siteSetBeforeTitleVideo(String(media.id)));
        // } else {
        //     setConfirmRating(false)
        // }

        //กรณีคลิกมาจาก slide show
        if(isClickFromSlideShow) {
            dispatch(siteSetisClickFromSlideShow(false))
            // if(!showRentButton) {
                handleOnClickCheckRating()   
            // }
            
        }

        if(isPlayMediaNextEpisode) {
            handleOnClickCheckRating();
            dispatch(siteSetisPlayMediaNextEpisode(false));
        }
        
    
        },[])

       

    return (
        <React.Fragment>
            <Col md={1}></Col>
            <Col xs={12} md={12}>
                        
                {
                        (canViewThisMedia === false) ?
                    <div >
                        <div className='dummy-page-video-player-container' style={{"position": "relative"}}>
                            <img className='can-not-view-image' src={thumbnailUrl} alt=''/>
                            <div className='video-js video-js-dummy' style={{'width': '100%', 'height': 'auto'}} onClick={handleOnClickCheckRating} >
                                <button className="vjs-big-play-button" type="button" title="Play Video" aria-disabled="false"><span aria-hidden="true" className="vjs-icon-placeholder"></span><span className="vjs-control-text" aria-live="polite">Play Video</span></button>
                            </div>
                        </div>
                        {/* <VideoPlayer source={''} adsList={playerAdsList} autoPlay={false} onStartWatching={handlePlayerOnStartWatching}/>   */}
                    </div>
                    :
                    <div>
                        <VideoPlayer source={videoSource} adsList={playerAdsList} autoPlay={true} onStartWatching={onStartWatching} titlePremium={isPremium} videoLicenseCertificateUrl={videoCertificateUrl} videoLicenseUrl={videoLicenseUrl} />  
                    </div>
                }               
               
            </Col>
            <Col md={1}></Col>
            <Col xs={12} md={3}>
                 {/* <Ads 
                     adUnitId={adsDetails.ads_unit_id}
                     sampleImage={adsDetails.banner_url}
                     width={parseInt(adsDetails.width)}
                     height={parseInt(adsDetails.height)}
                 /> */}
          </Col>

        </React.Fragment> 
    )
}

const mapStateToProps = (state, ownProps) => ({
})
  
const mapDispatchToProps = (dispatch, ownProps) => ({
    dispatch
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DummyPageCheckRating)
