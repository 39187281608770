const initialState = {
    shelf: [], 
    slideshow: [],
    searchString: '',
    searchList: [],
    continueWatchingList: []
}

const media = (state = initialState, action) => {
    switch (action.type) {
        case 'HOME_SET_SHELF':
            return { ...state, shelf: action.shelf }
        case 'HOME_SET_SLIDESHOW':
            return { ...state, slideshow: action.slideshow }
        case 'HOME_SET_SEARCH_STRING':
            return { ...state, searchString: action.searchString }
        case 'HOME_SET_SEARCH_LIST':
            return { ...state, searchList: action.searchList }
        case 'HOME_SET_CONTINUE_WATCHING_LIST':
            return { ...state, continueWatchingList: action.continueWatchingList }
        default:
            return state
    }
}
  
export default media
