import React from 'react'

const Rating = ({text}) => {
    return (
        <div className={'video-page-rating mb-3' + (text ? '' : ' video-page-rating-no-text')}>
            {text ? text : '-'}
        </div>
    )
}

export default Rating
