import React from "react"
import { connect } from 'react-redux'

const MediaRemainingTime = ({mediaRemainingTime}) => {
    
    return (
        <div className="chromecast-control-bar-media-remaining-time">
            -{ mediaRemainingTime }
        </div>
    );
}

const mapStateToProps = (state, ownProps) => ({
    mediaRemainingTime: state.chromecast.media.remainingTime
})
  
const mapDispatchToProps = (dispatch, ownProps) => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MediaRemainingTime)
