
export const selectMedia = id => ({
    type: 'SELECT_MEDIA',
    id
})

export const selectEpisode = episode => ({
    type: 'SELECT_EPISODE',
    episode
})

export const mediaSetEpisodeList  = episodeList => ({
    type: 'MEDIA_SET_EPISODE_LIST',
    episodeList
})

export const mediaSetThumbnailUrl = thumbnailUrl => ({
    type: 'MEDIA_SET_THUMBNAIL_URL',
    thumbnailUrl
})

export const mediaSetCurrentTime  = currentTime => ({
    type: 'MEDIA_SET_CURRENT_TIME',
    currentTime
})

export const mediaSetCurrentTextLanguage = language => ({
    type: 'MEDIA_SET_CURRENT_TEXT_LANGUAGE',
    language
})

export const mediaSetCurrentAudioLanguage = language => ({
    type: 'MEDIA_SET_CURRENT_AUDIO_LANGUAGE',
    language
})

export const mediaSetStartTime = startTime => ({
    type: 'MEDIA_SET_START_TIME',
    startTime
})

export const mediaSetDetails = details => ({
    type: 'MEDIA_SET_DETAILS',
    details
})

export const mediaSetCanView = canView => ({
    type: 'MEDIA_SET_CAN_VIEW',
    canView
})

export const mediaSetIsRentalType = isRentalType => ({
    type: 'MEDIA_SET_IS_RENTAL_TYPE',
    isRentalType
})


export const mediaSetIsRented = isRented => ({
    type: 'MEDIA_SET_IS_RENTED',
    isRented
})

export const mediaSetIsCurrentEpisodePremium = isCurrentEpisodePremium => ({
    type: 'MEDIA_SET_IS_CURRENT_EPISODE_PREMIUM',
    isCurrentEpisodePremium
})



export const mediaReset = ()  => ({
    type: 'MEDIA_RESET',
})