import React,{useRef, useState} from 'react'

const DragScrollContainer = ({children, className}) => {

    const [mouseDown, setmouseDown] = useState(false)
    const [isDragging, setisDragging] = useState(false)
    const [startX, setstartX] = useState()
    const [scrollLeft, setscrollLeft] = useState()

    const slider = useRef(null);

    let startDragging = function (e) {
        setisDragging(false)
        setmouseDown(true);
        setstartX(e.pageX - slider.current.offsetLeft)
        setscrollLeft(slider.current.scrollLeft)
    };
    let stopDragging = function (event) {
        setmouseDown(false);
    };

    let dragging = function (e) {
        e.preventDefault();
        if(!mouseDown) { return; }
        const x = e.pageX - slider.current.offsetLeft;
        const scroll = x - startX;
        slider.current.scrollLeft = scrollLeft - scroll;
    };

    const handleOnClick = (e) => {
        if(isDragging){
            e.preventDefault();
            e.stopPropagation();
        }else{
        }
    }

    const handleOnScroll = () => {
        setisDragging(true)
    }

    return (
        <div
            className={'drag-scroll-container ' + className}
            onMouseDown={startDragging} 
            onMouseUp={stopDragging} 
            onMouseLeave={stopDragging} 
            onMouseMove={dragging} 
            onScroll={handleOnScroll}
            onClickCapture={handleOnClick} 
            ref={slider}>
            {children}
        </div>
    )
}

export default DragScrollContainer
