import React from 'react'
import SlideshowContent from './SlideshowContent'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {Autoplay, Navigation,Pagination} from 'swiper/core';
import useClientWidth from '../../hooks/useScreenWidth';
import { checkIsMobileWidth } from '../../helper/Helper';

SwiperCore.use([Autoplay, Navigation, Pagination]);

const SlideshowContentList = ({list}) => {

    const clientWidth = useClientWidth()

    return (

        list && list.length > 0
        ? 
            <React.Fragment>
                {
                        checkIsMobileWidth(clientWidth)
                    ?
                        <Swiper
                            className='slide-show-content-list full-width-container mobile-mode'
                            loop={true}
                            slidesPerView={1.0} 
                            spaceBetween={0}
                            centeredSlides={true} 
                            navigation={false} 
                            pagination={true} 
                            autoplay={{
                                    "delay": 4000,
                                    "disableOnInteraction": false
                                }}
                            >
                            {
                                list.map( (slideshow, index) => {
                                    return <SwiperSlide key={index}><SlideshowContent details={slideshow} mode='mobile' /></SwiperSlide>
                                    
                                })
                            }
                        </Swiper>
                    :
                        <Swiper
                            className='slide-show-content-list full-width-container desktop-mode'
                            loop={true}
                            slidesPerView={1.00} 
                            spaceBetween={0}
                            centeredSlides={true} 
                            navigation={false} 
                            pagination={true} 
                            autoplay={{
                                    "delay": 4000,
                                    "disableOnInteraction": false
                                }}
                            >
                                {
                                    list.map( (slideshow, index) => {
                                        return <SwiperSlide key={index}><SlideshowContent details={slideshow} mode='desktop' /></SwiperSlide>
                                    })
                                }
                        </Swiper>
                }
            </React.Fragment>
        :
            <div></div>
    )
}

export default SlideshowContentList
