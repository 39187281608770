export const premiumModalSetShow = show => ({
    type: 'PREMIUM_MODAL_SET_SHOW',
    show
})

export const premiumModalRefresh = refresh => ({
    type: 'PREMIUM_MODAL_SET_REFRESH',
    refresh
})

export const premiumModalSetCurrentTab = currentTab => ({
    type: 'PREMIUM_MODAL_SET_CURRENT_TAB',
    currentTab
})
