import React from "react"
import { connect } from 'react-redux'
import Slider from '@material-ui/core/Slider';

const MediaSeeker = ({mediaCurrentTime, mediaDuration}) => {
    
    const setCurrentTime = (value) => {
        const time = value;
        window.flixer.castPlayerController.seekTo(time);
    }

    return (
        <div className='chromecast-control-bar-media-duration-control'>
            <Slider 
                value={mediaCurrentTime} 
                onChange={(event,value) => setCurrentTime(value) } 
                max={mediaDuration === 0 ? 1 : mediaDuration} // กรณีที่ video จบ duration กับ current จะเท่ากับ 0 จะทำให้ slider มาอยู่ตรงกลางดังนั้นจึงต้องใส่ค่าเข้าไปเป็น 1
                aria-labelledby="continuous-slider" />
        </div>
    );
}

const mapStateToProps = (state, ownProps) => ({
    mediaCurrentTime: state.chromecast.media.currentTime,
    mediaDuration: state.chromecast.media.duration,
})
  
const mapDispatchToProps = (dispatch, ownProps) => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MediaSeeker)
