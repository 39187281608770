import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import rootReducer from './reducers'
import {Switch, Route, BrowserRouter as Router} from 'react-router-dom'
import PrivacyPolicyPage from './components/privacypolicy/PrivacyPolicyPage';
import TermsOfUsePage from './components/termsofuse/TermsOfUsePage';
import i18n from './components/i18n';
import PageNotFound from './components/serviceunavailable/PageNotFound';
import HelpCenterPage from './components/helpCenter/HelpCenterPage';
import TermOfUseAndPrivacyPolicy from './containers/termPrivacy/TermOfUseAndPrivacyPolicy';

export const store = createStore(rootReducer)

window.flixer = {};
window.flixer.chromecast = {};
window.flixer.chromecast.sessionState = {
  started: "STARTED",
  resumed: "RESUMED",
  ended  : "ENDED",
}

window.flixer.chromecast.PLAYER_STATE = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  PLAYING: 'PLAYING',
  PAUSED: 'PAUSED',
  STOPPED: 'STOPPED',
  ERROR: 'ERROR'
}

let cookieCurrentLanguage = document.cookie.replace(/(?:(?:^|.*;\s*)currentLanguage\s*\=\s*([^;]*).*$)|^.*$/, "$1");

window.flixer.homeUrl = '';

if(cookieCurrentLanguage !== '') {
  i18n.changeLanguage(cookieCurrentLanguage)
} else {
  i18n.changeLanguage("en")
  cookieCurrentLanguage = 'en'
}

window.flixer.episodeClickTimeoutID = '';

window.flixer.aboutFlixerPlusDescription = 'คุณมีเวลา 30 วันที่จะเริ่มรับชมเนื้อหานี้ และหลังจากเริ่มรับชมคุณจะมีเวลา 48 ชม. ในการรับชมให้เสร็จสิ้น';

window.flixer.cookieDomainDefault = '.samuraivsninja.com'

window.flixer.userSettingsDefault = {
  style: {
      backgroundColor: "#000",
      backgroundOpacity: '0',
      color: "#FFF",
      edgeStyle: "none",
      fontFamily: "proportionalSansSerif",
      fontPercent: 1.5,
      textOpacity: '1',
      windowColor: "#000",
      windowOpacity: '0',
      volume: '1.0',
      isMute: '0',
  },
  language: {
      subtitleLanguage: 'en',
      audioLanguage: null
  },
  version: 2
}
window.flixer.userSettingsCookiesMaxAge = 31536000; // 1 year


require('./helper/ErrorHelper')

window.addEventListener("popstate", e => {
  // ็Handle หน้าที่ redirect มาจากหน้า 2c2p payment จะมี State "fromPaymentPage" 
  if( e.state && e.state.state && e.state.state.fromPaymentPage === true ){
    // จะส่งไปยังหน้าก่อนที่กด Back มา ทำให้ไม่สามารถกด back กลับไปหน้า 2c2p Payment ได้
    window.history.go(1);
  }
});


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path={[window.flixer.homeUrl + "/pageNotFound"]}>
            <PageNotFound/>
          </Route>
          <Route path="*">
            <App />
          </Route>
        </Switch>
      </Router>

     
    </Provider>
    
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
