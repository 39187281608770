// { code: description, }

window.flixer.ERROR = {
    '500': 'Error',
    '501': 'Error', // เกิดข้อผิดพลาดจาก API request error
    '0001': 'Email address required.',
    '0002': 'Error: %s',
    '0003': 'เกิดข้อผิดพลาดในขั้นตอนการชำระเงิน: หากชำระเงินผ่านช่องทาง บัตรเดบิต/บัตรเครดิต สำเร็จแล้ว โปรดติดต่อฝ่ายประสานงานลูกค้า',
    '0004': 'Something worng, Please try again',
    '0005': 'Title: Usage Limit\nMessage: our account has exceeded the maximum number of viewer at this time.'
}