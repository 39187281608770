import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { loginModalSetCurrentTab, loginModalSetHidden, premiumModalSetCurrentTab, premiumModalSetShow } from '../../actions'
import { callApiTopupBuy, callApiTopupList } from '../../api'
import { getDayLeft, hideLoading, showLoading } from '../../helper/Helper'
import { isUserLoggedIn, isUserPremium } from '../../helper/ReduxStoreHelper'
import Icon from '../../images/modal/close-btn.png'
import BackTopupButton from './BackTopupButton'
import ButtonTopupTranparent from './ButtonTopupTranparent'
import { Col, Row } from 'react-bootstrap'
import FlixerLogo from '../../images/premium-modal/flixer-logo.png'
import FlixerBannerTopup from '../../images/modal/bannertopuppremium.png'
import InformationIcon1 from '../../images/premium-modal/information-icon1.png'
import InformationIcon2 from '../../images/premium-modal/information-icon2.png'
import InformationIcon3 from '../../images/premium-modal/information-icon3.png'
import InformationIcon4 from '../../images/premium-modal/information-icon4.png'

import {Suspense} from 'react';
import { useTranslation } from 'react-i18next'

const TopUpForm = () => {

    const [data, setData] = useState([]);
    const [ dateExpireCount , setDateExpireCount ] = useState('')
    const dispatch = useDispatch()
    const premiumExpire = useSelector(state => state.user.premiumExpire)
    const [ dayLeftExpire , setDayLeftExpire ] = useState(0)
    const platformPremium = useSelector(state => state.user.premiumPlatform)
    const [ statusLoadData , setStatusLoadData ] = useState(false)

    const { t , i18n} = useTranslation('trans');


    useEffect(() => {
        showLoading();
        setStatusLoadData(false)
        callApiTopupList().then(handleTopupListResponse)
            
    }, [])

    useEffect(() => {

        
        hideLoading();
            
    }, [statusLoadData])

    useEffect(() => {

    }, [dayLeftExpire])


    const handleTopupListResponse = (response) => {
        
        setData(response.result.topup)
        let dayLeft = getDayLeft(premiumExpire)
        setDayLeftExpire(dayLeft);

        setStatusLoadData(true)
        

    }


    const clickButton = (productId, price, topupDays) => {

        if(isUserLoggedIn()) {
            const countDateExpire = parseInt(topupDays) + parseInt(dayLeftExpire);
            // console.log('countDate' , countDateExpire)
            // console.log('topupDays' , topupDays)

            //730 is two years(365 x 2)
            if(countDateExpire <= 730) {
                showLoading();
                callApiTopupBuy(productId, price).then(handleTopupBuyResponse);
            }else if(countDateExpire > 730) {
                alert("Title: " + "Warning" + "\nMessage: " + "ขออภัย สามารถเติมวันสะสมไว้ได้ไม่เกินสองปี" + "\n" )
            }else {
                alert("Title: " + "Warning" + "\nMessage: " + "มีบางอย่างผิดพลาด โปรดติดต่อผู้พัฒนา" + "\n" )
            }
        } else {
            dispatch(loginModalSetCurrentTab(''))
            dispatch(loginModalSetHidden(false))
        }
    }

    const handleTopupBuyResponse = (response) => {
        hideLoading();
        window.location.href = response.result.response.webPaymentUrl
    }

    const closeModal = () => {
        dispatch(premiumModalSetShow(false))
    }

    return (


        <div className='login-modal-form' hidden={false} >
            {/* <ModalClose onClick={closeModal}/> */}
            
            <div className='login-modal-form-header' >
                {
                    (platformPremium !== 'flixer-topup') ?
                    <BackTopupButton firstLine={t('Back')} onClick={() => dispatch(premiumModalSetCurrentTab(''))}/>
                :  
                    <BackTopupButton firstLine={t('Back')} onClick={() => dispatch(premiumModalSetCurrentTab('topupExpire'))}/>
                }
                {/* <div className='text-center' >หมดอายุวันที่ {premiumExpire} </div> */}

                
                

                <div className='modal-box-close-button' onClick={closeModal}>
                    <img src={Icon} alt=''/>
                </div>
            </div>

            {
                statusLoadData == true ?
            
            
            <div className='login-modal-form-body text-center'   style={{paddingTop:"0px"}}>

            <Row className='premium-modal-flixer-logo text-center'>
                    <Col>
                        <img src={FlixerLogo} alt=''/>
                    </Col>
                </Row>
            
            {/* <Row>
                <Col>
                    <img style={{width: "100%" , marginBottom: "20px"}} src={FlixerBannerTopup} alt=''/>
                </Col>
            </Row> */}

                    <div className='d-flex justify-content-center mb-4'>
                        <Row className='premium-modal-information'>
                            <Col>
                                <img className='premium-modal-information-icon' src={InformationIcon1} alt='' />รับชมเนื้อหาใหม่ก่อนใคร
                            </Col>
                            <div className='w-100' />
                            <Col>
                                <img className='premium-modal-information-icon' src={InformationIcon2} alt='' />รับชมความละเอียดได้สูงสุดแบบ Full HD
                            </Col>
                            <div className='w-100' />
                            <Col>
                                <img className='premium-modal-information-icon' src={InformationIcon3} alt='' />ไม่มีโฆษณาคั่นระหว่างรับชม
                            </Col>
                            <div className='w-100' />
                            <Col>
                                <img className='premium-modal-information-icon' src={InformationIcon4} alt='' />สามารถเลือกเสียงและซับไตเติ้ลได้
                            </Col>
                        </Row>
                    </div>

            <Row>
                <Col>
                    <label className='text-center' style={{color: "rgb(255,231,175)" , fontSize: "30px", fontWeight: "bold", marginBottom: "20px"}}>Packages</label>
                </Col>
            </Row>

            <Row>
                <Col>
                <div style={{color: "white" , width: "100%"}}>
                 {
                     data.map((item, index) => (
                         <p><ButtonTopupTranparent firstLine={item.description.first_line} secondLine={item.description.second_line} onClick={() => clickButton(item.product_id, item.price, item.topup_days)} /></p>
                     ))
                 }
                </div>
                </Col>
            </Row>

            <Row>
                <Col>
                    <div className='premium-modal-bottom-text'>
                        การซื้อแพคเกจมีผลหลังจากชำระเงินเสร็จ
                        ใช้ได้ตามจำนวนของแพคเกจ และยกเลิกอัตโนมัติเมื่อครบกำหนด<br/>
                        ไม่สามารถใช้งานร่วมกับการเป็นสมาชิกแบบชำระเงินรายเดือนได้
                    </div>
                </Col>
            </Row>

            </div>

            :

            '' 
            }

            
        </div>
        
    )
}

const mapStateToProps = (state, ownProps) => ({
    show: ownProps.show
})
  
const mapDispatchToProps = (dispatch, ownProps) => ({
    dispatch
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TopUpForm)

